import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../../Components/Header/Header";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import awsLinkImg from "../../utils/images/link (1).png";
import cIDImg from "../../utils/images/letter-a.png";
import FileImg from "../../utils/images/file_1.png";
import FiletypeImg from "../../utils/images/abc (1).png";
import DescImg from "../../utils/images/desc_4.png";
import HashImg from "../../utils/images/hash.png";
import keywordImg from "../../utils/images/kw_10.png";
import OrgImg from "../../utils/images/organization (3).png";
import DOBImg from "../../utils/images/calendar (1).png";
import UserImg from "../../utils/images/user (5).png";
import AgeImg from "../../utils/images/age.png";
import GenderImg from "../../utils/images/gender.png";
import EmailImg from "../../utils/images/email.png";
import DatePicker from "react-datepicker";
import Cookie from "js-cookie";
import { GlobalConstants } from "../../utils/globalconstant";
import { GLOBAL_URL_DOMAIN_LIVE } from "../../utils/Files/url";
import axios from "axios";
import { errorToast, successToast } from "../../utils/Files/toasterMsgs";
import { ToastContainer } from "react-toastify";
import { Tooltip } from "@mui/material";
import PhoneInput from "react-phone-input-2";
import ReactFlagsSelect from "react-flags-select";
import "./EditFile.css";
import Loader3 from "../../utils/Files/data";
import { useDispatch, useSelector } from "react-redux";
import { fetchEditFileData } from "../../redux/EditFileDataSlice";
// import { useDispatch } from "react-redux";
import { foderwiseAwsFiless } from "../../redux/AwsRootFolderSlice";
import { getIPFSFileData } from "../../redux/IPFSRootFolderSlice";
import { getBothFileData } from "../../redux/BothFilesSlice";
const xpath = require("xpath");
const dom = require("xmldom").DOMParser;

function EditFile() {
  const navigate = useNavigate();
  const location = useLocation();
  // ---- language starts -----//
  const [text_amazons3link1, setText_amazons3link1] =
    useState("Amazon S3 Link");
  const [text_atomfileid, setText_atomfileid] = useState("ATOM FILE ID");
  const [text_filename1, setText_filename1] = useState("File Name");
  const [text_filetype1, setText_filetype1] = useState("File Type");
  const [text_filesize1, setText_filesize1] = useState("File Size");
  const [text_description1, setText_description1] = useState("Description");
  const [text_filetag, setText_filetag] = useState("File Tag(s)");
  const [text_keywords, setText_keywords] = useState("Keyword(s)");
  const [text_org1, setText_org1] = useState("Organization");
  const [text_personalhealthdata, setText_personalhealthdata] = useState(
    "Personal Health Data"
  );
  const [text_patient, setText_patient] = useState("Patient");
  const [text_firstname1, setText_firstname1] = useState("First Name");
  const [text_lastname1, setText_lastname1] = useState("Last Name");
  const [text_country, setText_country] = useState("Country");
  const [text_id, setText_id] = useState("ID");
  const [text_enterid, setText_enterid] = useState("Enter Id");
  const [text_dob1, setText_dob1] = useState("Date Of Birth");
  const [text_age1, setText_age1] = useState("Age");
  const [text_gender1, setTextgender1] = useState("Gender");
  const [text_email1, setText_email1] = useState("Email");
  const [text_phoneno1, setText_phoneno1] = useState("Phone Number");
  const [text_enterphoneno, setText_enterphoneno] =
    useState("Enter Phone Number");
  const [text_enterage, setText_enterage] = useState("Enter Age");
  const [text_enterfirstname, setText_enterfirstname] =
    useState("Enter First Name");
  const [text_enterlastname, setText_enterlastname] =
    useState("Enter Last Name");
  const [text_cancel, setText_cancel] = useState("Cancel");
  const [text_save, setText_save] = useState("Save");
  const [text_editfiledata, setText_editfiledata] = useState("Edit file Data");
  const [text_selectgender, setText_selectgender] = useState("Select Gender");
  const [text_other, setText_other] = useState("Other");
  const [text_male, setText_male] = useState("Male");
  const [text_female, setText_female] = useState("Female");
  const [text_pntd, setText_pntd] = useState("Prefer not to disclose");
  const [text_atomstore, setText_atomstore] = useState(
    "Atom Storage Network CID"
  );
  const [text_amazonlocation, setText_amazonLocation] =
    useState("Amazon S3 Location");
  const [text_copied, setText_copied] = useState("Copied");

  // ---- language end -----//

  //variable
  const [selecttab, setSelectedtab] = useState(location?.state?.selectedTab);
  const [FileID, setFileID] = useState(location?.state?.EditFileData?._id);

  const [editData, setEditData] = useState([]);
  const [keyWord, setKeyword] = useState("");
  const [tag, setTag] = useState("");
  const [desc, setDesc] = useState("");
  const [filesize, setFilesize] = useState("");
  const [filetype, setFiletype] = useState("");
  const [fileName, setFileName] = useState("");
  const [cid, setCid] = useState("");
  const [amazonLink, setAmazonLink] = useState("");
  const [org, setOrg] = useState("");
  const [fName, setFname] = useState("");
  const [mName, setMname] = useState("");
  const [lName, setLname] = useState("");
  const [dateOfBirth, setDateofbirth] = useState(new Date());
  const [age, setAge] = useState("");
  const [gender, setGender] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [openTooltipamazon, setOpenTooltipAmazon] = useState(false);
  const [openTooltipCid, setOpenTooltipCid] = useState(false);
  const [country, setCountry] = useState("");
  const [selectIDCard1, setSelectIDCard1] = useState([]);
  const [selectIDCard2, setSelectIDCard2] = useState([]);
  const [selectIDCard3, setSelectIDCard3] = useState([]);
  const [selectedID1, setSelectedCardID1] = useState(selectIDCard1._id);
  const [selectedID2, setSelectedCardID2] = useState(selectIDCard2._id);
  const [selectedID3, setSelectedCardID3] = useState(selectIDCard3._id);
  const [enterId1, setenterId1] = useState("");
  const [enterId2, setenterId2] = useState("");
  const [enterId3, setenterId3] = useState("");
  const [FileSizeInBytes, setFileSizeInBite] = useState("");
  const [privateOrPublic, setPrivateOrPublic] = useState("");
  const [amazonKey, setAmazonKey] = useState("");
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  const { data, loading, error } = useSelector((state) => state.EditFileData);
  //***close tooltip*****/
  console.log(typeof selecttab);
  const onMouseOutamazon = () => {
    if (openTooltipamazon) {
      setTimeout(() => {
        setOpenTooltipAmazon(false);
      }, 1000);
    }
  };

  const onMouseOutCid = () => {
    if (cid === "NA") {
      console.log("NO DATA");
    } else {
      if (openTooltipCid) {
        setTimeout(() => {
          setOpenTooltipCid(false);
        }, 1000);
      }
    }
  };
  //**** Language start *****/
  useEffect(() => {
    try {
      SetLanguageText();
    } catch (err) {
      console.log("" + err);
    }
  }, []);
  const SetLanguageText = () => {
    try {
      var xml = localStorage.getItem(GlobalConstants.session_lang_xml);
      var doc = new dom().parseFromString(xml);
      setText_amazons3link1(
        xpath.select1("/resources/string[@name='text_amazons3link1']", doc)
          .firstChild.data
      );
      setText_atomfileid(
        xpath.select1("/resources/string[@name='text_atomfileid']", doc)
          .firstChild.data
      );
      setText_filename1(
        xpath.select1("/resources/string[@name='text_filename1']", doc)
          .firstChild.data
      );
      setText_filetype1(
        xpath.select1("/resources/string[@name='text_filetype1']", doc)
          .firstChild.data
      );
      setText_filesize1(
        xpath.select1("/resources/string[@name='text_filesize1']", doc)
          .firstChild.data
      );
      setText_description1(
        xpath.select1("/resources/string[@name='text_description1']", doc)
          .firstChild.data
      );
      setText_filetag(
        xpath.select1("/resources/string[@name='text_filetag']", doc).firstChild
          .data
      );
      setText_keywords(
        xpath.select1("/resources/string[@name='text_keywords']", doc)
          .firstChild.data
      );
      setText_org1(
        xpath.select1("/resources/string[@name='text_org1']", doc).firstChild
          .data
      );
      setText_personalhealthdata(
        xpath.select1("/resources/string[@name='text_personalhealthdata']", doc)
          .firstChild.data
      );
      setText_patient(
        xpath.select1("/resources/string[@name='text_patient']", doc).firstChild
          .data
      );
      setText_firstname1(
        xpath.select1("/resources/string[@name='text_firstname1']", doc)
          .firstChild.data
      );
      setText_lastname1(
        xpath.select1("/resources/string[@name='text_lastname1']", doc)
          .firstChild.data
      );
      setText_dob1(
        xpath.select1("/resources/string[@name='text_dob1']", doc).firstChild
          .data
      );
      setText_age1(
        xpath.select1("/resources/string[@name='text_age1']", doc).firstChild
          .data
      );
      setTextgender1(
        xpath.select1("/resources/string[@name='text_gender1']", doc).firstChild
          .data
      );
      setText_email1(
        xpath.select1("/resources/string[@name='text_email1']", doc).firstChild
          .data
      );
      setText_phoneno1(
        xpath.select1("/resources/string[@name='text_phoneno1']", doc)
          .firstChild.data
      );
      setText_cancel(
        xpath.select1("/resources/string[@name='text_cancel']", doc).firstChild
          .data
      );
      setText_save(
        xpath.select1("/resources/string[@name='text_save']", doc).firstChild
          .data
      );
      setText_editfiledata(
        xpath.select1("/resources/string[@name='text_editfiledata']", doc)
          .firstChild.data
      );
      setText_selectgender(
        xpath.select1("/resources/string[@name='text_selectgender']", doc)
          .firstChild.data
      );
      setText_male(
        xpath.select1("/resources/string[@name='text_male']", doc).firstChild
          .data
      );
      setText_female(
        xpath.select1("/resources/string[@name='text_female']", doc).firstChild
          .data
      );
      setText_other(
        xpath.select1("/resources/string[@name='text_other']", doc).firstChild
          .data
      );
      setText_pntd(
        xpath.select1("/resources/string[@name='text_pntd']", doc).firstChild
          .data
      );
      setText_country(
        xpath.select1("/resources/string[@name='text_country']", doc).firstChild
          .data
      );
      setText_id(
        xpath.select1("/resources/string[@name='text_id']", doc).firstChild.data
      );
      setText_enterid(
        xpath.select1("/resources/string[@name='text_enterid']", doc).firstChild
          .data
      );
      setText_atomstore(
        xpath.select1("/resources/string[@name='text_atomstore']", doc)
          .firstChild.data
      );

      setText_amazonLocation(
        xpath.select1("/resources/string[@name='text_amazonlocation']", doc)
          .firstChild.data
      );
      setText_enterphoneno(
        xpath.select1("/resources/string[@name='text_enterphoneno']", doc)
          .firstChild.data
      );
      setText_enterage(
        xpath.select1("/resources/string[@name='text_enterage']", doc)
          .firstChild.data
      );
      setText_enterlastname(
        xpath.select1("/resources/string[@name='text_enterlastname']", doc)
          .firstChild.data
      );
      setText_enterfirstname(
        xpath.select1("/resources/string[@name='text_enterfirstname']", doc)
          .firstChild.data
      );
      setText_copied(
        xpath.select1("/resources/string[@name='text_copied']", doc).firstChild
          .data
      );
    } catch (ert) {}
  };
  //**** Language end *****/
  //*****Get All Data *******/
  const prevFileID = sessionStorage.getItem("prevFileID");
  console.log("data", typeof data);
  useEffect(() => {
    if ((FileID && FileID !== prevFileID) || Object.keys(data).length === 0) {
      console.log(FileID);
      sessionStorage.setItem("prevFileID", FileID);
      dispatch(fetchEditFileData(FileID));
    }
  }, [FileID]);
  useEffect(() => {
    if (data) {
      // Update your local state with the fetched data
      setEditData(data);
      setOrg(data.orgName || "NA");
      setAmazonLink(data.amazonUrl || "NA");
      setFileName(data.fileName || "NA");
      setFilesize(data.fileSize || "NA");
      setFiletype(data.fileType || "NA");
      setDesc(data.description || "NA");
      setKeyword(data.keywords || "NA");
      setTag(data.fileTags || "NA");
      setCid(data.fileCId || "NA");
      setFname(data.pFName || "NA");
      setLname(data.pLName || "NA");
      setMname(data.pMName || "NA");
      setDateofbirth(data.pDOB ? new Date(data.pDOB) : new Date());
      setAge(data.pAge || 0);
      setGender(data.pGender || "NA");
      setEmail(data.pEmail || "NA");
      setPhone(data.pPhone || "NA");
      setSelectedCardID1(data.h_id_1?.name || "NA");
      setSelectedCardID2(data.h_id_2?.name || "NA");
      setSelectedCardID3(data.h_id_3?.name || "NA");
      setenterId1(data.h_id_1?.value || "NA");
      setenterId2(data.h_id_2?.value || "NA");
      setenterId3(data.h_id_3?.value || "NA");
      setCountry(data.country);
      setFileSizeInBite(data.sizeInBytes || 0);
      setPrivateOrPublic(data.bucketACLOption);
      setAmazonKey(data.amazonKey || "NA");
    }
  }, [data]);
  // useEffect(() => {
  //   setLoader(true);
  //   let headerConfig = {
  //     headers: {
  //       authorization: "Bearer " + sessionStorage.getItem("userToken"),
  //     },
  //   };

  //   var API_URL =
  //     GLOBAL_URL_DOMAIN_LIVE + `/file/filedata-by-id?fileId=${FileID}`;

  //   axios
  //     .get(API_URL, headerConfig)
  //     .then((res) => {
  //       console.log("get data both res***", res);
  //       setLoader(false);
  //       setEditData(res?.data?.data);
  //       setOrg(
  //         res?.data?.data?.orgName === "" ? "NA" : res?.data?.data?.orgName
  //       );
  //       setAmazonLink(
  //         res?.data?.data?.amazonUrl === "" ? "NA" : res?.data?.data?.amazonUrl
  //       );
  //       setFileName(
  //         res?.data?.data?.fileName === "" ? "NA" : res?.data?.data?.fileName
  //       );
  //       setFilesize(
  //         res?.data?.data?.fileSize === "" ? "NA" : res?.data?.data?.fileSize
  //       );
  //       setFiletype(
  //         res?.data?.data?.fileType === "" ? "NA" : res?.data?.data?.fileType
  //       );
  //       setDesc(
  //         res?.data?.data?.description === ""
  //           ? "NA"
  //           : res?.data?.data?.description
  //       );
  //       setKeyword(
  //         res?.data?.data?.keywords === "" ? "NA" : res?.data?.data?.keywords
  //       );
  //       setTag(
  //         res?.data?.data?.fileTags === "" ? "NA" : res?.data?.data?.fileTags
  //       );
  //       setCid(
  //         res?.data?.data?.fileCId === "" ? "NA" : res?.data?.data?.fileCId
  //       );
  //       setFname(
  //         res?.data?.data?.pFName === "" ? "NA" : res?.data?.data?.pFName
  //       );
  //       setLname(
  //         res?.data?.data?.pLName === "" ? "NA" : res?.data?.data?.pLName
  //       );
  //       setMname(
  //         res?.data?.data?.pMName === "" ? "NA" : res?.data?.data?.pMName
  //       );
  //       var Birthdate = res?.data?.data?.pDOB;
  //       setDateofbirth(Birthdate === null ? new Date() : new Date(Birthdate));
  //       setAge(res?.data?.data?.pAge === "" ? 0 : res?.data?.data?.pAge);
  //       setGender(
  //         res?.data?.data?.pGender === "" ? "NA" : res?.data?.data?.pGender
  //       );
  //       setEmail(
  //         res?.data?.data?.pEmail === "" ? "NA" : res?.data?.data?.pEmail
  //       );
  //       setPhone(
  //         res?.data?.data?.pPhone === "" ? "NA" : res?.data?.data?.pPhone
  //       );

  //       setSelectedCardID1(
  //         res?.data?.data?.h_id_1?.name === ""
  //           ? "NA"
  //           : res?.data?.data?.h_id_1?.name
  //       );
  //       setSelectedCardID2(
  //         res?.data?.data?.h_id_2?.name === ""
  //           ? "NA"
  //           : res?.data?.data?.h_id_2?.name
  //       );
  //       setSelectedCardID3(
  //         res?.data?.data?.h_id_3?.name === ""
  //           ? "NA"
  //           : res?.data?.data?.h_id_3?.name
  //       );
  //       setenterId1(
  //         res?.data?.data?.h_id_1?.value === ""
  //           ? "NA"
  //           : res?.data?.data?.h_id_1?.value
  //       );
  //       setenterId2(
  //         res?.data?.data?.h_id_2?.value === ""
  //           ? "NA"
  //           : res?.data?.data?.h_id_2?.value
  //       );
  //       setenterId3(
  //         res?.data?.data?.h_id_3?.value === ""
  //           ? "NA"
  //           : res?.data?.data?.h_id_3?.value
  //       );

  //       setCountry(res?.data?.data?.country);
  //       setFileSizeInBite(
  //         res?.data?.data?.sizeInBytes === "" ? 0 : res?.data?.data?.sizeInBytes
  //       );
  //       setPrivateOrPublic(res?.data?.data?.bucketACLOption);
  //       setAmazonKey(res?.data?.data?.amazonKey);
  //     })
  //     .catch((err) => {
  //       console.log("get data both err***", err);
  //       setLoader(false);
  //       if (err.response.status === 427) {
  //         sessionStorage.clear();
  //         localStorage.clear();
  //         Cookie.remove("fName");
  //         Cookie.remove("lName");
  //         Cookie.remove("userID");
  //         Cookie.remove("userToken");
  //         Cookie.remove("token_start_time");
  //         navigate("/");
  //       }
  //     })
  //     .then(function () {});
  // }, []);

  //*****Post All Data *******/
  const handleEditData = () => {
    var userID = sessionStorage.getItem("userID");
    var data = {
      fileId: FileID,
      active: true,
      fileSize: filesize,
      isAmazon: location?.state?.EditFileData?.isAmazon,
      isIPFS: location?.state?.EditFileData?.isIPFS,
      sizeInBytes: Number(FileSizeInBytes),
      fileName: fileName === "NA" ? "" : fileName,
      fileNameReplace: fileName === "NA" ? "" : fileName,
      fileNameWithUuid: location?.state?.EditFileData?.fileNameWithUuid,
      description: desc === "NA" ? "" : desc,
      fileTags: tag === "NA" ? "" : tag,
      keywords: keyWord === "NA" ? "" : keyWord,
      orgName: org === "NA" ? "" : org,
      isMedData: true,
      pFName: fName === "NA" ? "" : fName,
      pMName: mName === "NA" ? "" : mName,
      pLName: lName === "NA" ? "" : lName,
      pDOB: dateOfBirth,
      h_id_1_value: enterId1,
      h_id_2_value: enterId2,
      h_id_3_value: enterId3,
      pAge: age === 0 ? 1 : age,
      pGender: gender === "NA" ? "Other" : gender,
      pEmail: email === "NA" ? "" : email,
      pPhone: phone === "NA" ? "" : phone,
      file_unique_id: location?.state?.EditFileData?.file_unique_id,
    };

    let headerConfig = {
      headers: {
        authorization: "Bearer " + sessionStorage.getItem("userToken"),
      },
    };

    var API_URL = GLOBAL_URL_DOMAIN_LIVE + "/file/edit-file";

    axios
      .put(API_URL, data, headerConfig)
      .then((res) => {
        console.log("add_folder_id_func res***", res);
        if (res?.status === 200) {
          successToast(res?.data?.message);
          console.log(
            typeof location?.state?.selectedTab,
            location?.state?.selectedTab
          );
          // if (location?.state?.selectedTab == 0) {
          dispatch(fetchEditFileData(FileID));

          if (
            location?.state?.EditFileData?.isAmazon === true &&
            location?.state?.EditFileData?.isIPFS === false
          ) {
            dispatch(
              foderwiseAwsFiless(location?.state?.EditFileData?.bucketId_aws)
            );
          } else if (
            location?.state?.EditFileData?.isIPFS === true &&
            location?.state?.EditFileData?.isAmazon === false
          ) {
            dispatch(
              getIPFSFileData(
                `atom-storage/${sessionStorage.getItem("userID")}/`,
                "6e2f3b90-1dd9-4b7c-9be8-904bd4801e55"
              )
            );
          } else if (
            location?.state?.EditFileData?.isAmazon === true &&
            location?.state?.EditFileData?.isIPFS === true
          ) {
            dispatch(getBothFileData());
          }
          // }
          setTimeout(() => {
            navigate("/my-files", {
              state: { selectedTab: selecttab },
            });
          }, 3000);
        }
      })
      .catch((err) => {
        console.log("add_folder_id_func err***", err);
        errorToast(err?.response?.data.message);
        if (err?.response?.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          Cookie.remove("fName");
          Cookie.remove("lName");
          Cookie.remove("userID");
          Cookie.remove("userToken");
          Cookie.remove("token_start_time");
          navigate("/");
        }
      })
      .then(function () {});
  };
  return (
    <>
      <div className="upf-main">
        <Header />
        <div className="upf-main-container">
          <div className="upf-container editfile-container">
            <div className="upf-1 login-title-text uf-title">
              {text_editfiledata}{" "}
            </div>
            <hr className="upf-hr" />
            {loader === false ? (
              <>
                <div className="upf-2 editfile-aws-atom-div">
                  {data.isAmazon === false ? (
                    <></>
                  ) : (
                    <div className="uf-inputs-main upf-aws">
                      {privateOrPublic === "public" ? (
                        <>
                          <div className="flex-column">
                            <label className="login-labels">
                              {text_amazons3link1}
                            </label>
                          </div>
                          <div className="inputForm uf-inputs upf-aws-inner mt-1 w-100 editfile-aws-input">
                            <img
                              src={awsLinkImg}
                              alt=""
                              className="login-emailphone"
                            />
                            <input
                              type="text"
                              className="input login-input upf-inp w-100"
                              placeholder={text_amazons3link1}
                              value={amazonLink}
                              disabled={true}
                            />
                            <Tooltip
                              PopperProps={{
                                disablePortal: true,
                              }}
                              arrow
                              className="mt-5 ml-2"
                              open={openTooltipamazon}
                              disableFocusListener
                              disableHoverListener
                              disableTouchListener
                              title={`${text_copied}!`}
                            ></Tooltip>
                            {amazonLink === "" || amazonLink === "NA" ? (
                              ""
                            ) : (
                              <ContentCopyIcon
                                className="text-black copysize copy-sty "
                                onClick={() => [
                                  navigator.clipboard.writeText(amazonLink),
                                  setOpenTooltipAmazon(true),
                                ]}
                                onMouseOut={onMouseOutamazon}
                              />
                            )}
                          </div>
                          <div className="flex-column mt-3">
                            <label className="login-labels">
                              {text_amazonlocation}
                            </label>
                          </div>
                          <div className="inputForm uf-inputs upf-aws-inner mt-1 w-100 editfile-aws-input">
                            <input
                              type="text"
                              className="input login-input upf-inp w-100"
                              placeholder={text_amazonlocation}
                              value={amazonKey}
                              disabled={true}
                            />
                          </div>
                        </>
                      ) : (
                        <>
                          {" "}
                          <div className="flex-column">
                            <label className="login-labels">
                              {text_amazonlocation}
                            </label>
                          </div>
                          <div className="inputForm uf-inputs upf-aws-inner mt-1 w-100 editfile-aws-input">
                            <input
                              type="text"
                              className="input login-input upf-inp w-100"
                              placeholder={text_amazonlocation}
                              value={amazonKey}
                              disabled={true}
                            />
                          </div>
                        </>
                      )}
                    </div>
                  )}
                  <div
                    className="uf-inputs-main upf-aws"
                    id={privateOrPublic === "public" ? "top-file-id" : ""}
                  >
                    <div className="flex-column">
                      <label className="login-labels">{text_atomfileid}</label>
                    </div>
                    <div
                      className={`inputForm uf-inputs upf-aws-inner mt-1   editfile-aws-input ${
                        data.isAmazon === true ? "w-100" : "w-50"
                      }`}
                    >
                      <img src={cIDImg} alt="" className="login-emailphone" />
                      <input
                        type="text"
                        className="input login-input upf-inp  w-100"
                        placeholder={text_atomstore}
                        value={cid}
                        disabled={true}
                      />
                      <Tooltip
                        PopperProps={{
                          disablePortal: true,
                        }}
                        arrow
                        className="mt-5 ml-2"
                        open={openTooltipCid}
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        title={`${text_copied}!`}
                      ></Tooltip>
                      {cid === "" || cid === "NA" ? (
                        ""
                      ) : (
                        <ContentCopyIcon
                          className="text-black copysize copy-sty"
                          onClick={() => [
                            cid === "NA"
                              ? setOpenTooltipCid(false)
                              : [
                                  navigator.clipboard.writeText(cid),
                                  setOpenTooltipCid(true),
                                ],
                          ]}
                          onMouseOut={onMouseOutCid}
                        />
                      )}
                    </div>
                  </div>
                </div>
                {/*** Uploaded File Data start****/}
                <div className="upf-3 mt-3">
                  <div className="uf-inputs-main">
                    <div className="flex-column">
                      <label className="login-labels">{text_filename1}</label>
                    </div>
                    <div className="inputForm uf-inputs mt-1 select-dropdown-myfile">
                      <img src={FileImg} alt="" className="login-emailphone" />
                      <input
                        type="text"
                        className="input login-input"
                        value={fileName}
                        onChange={(e) => setFileName(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="uf-inputs-main">
                    <div className="flex-column">
                      <label className="login-labels">{text_filetype1}</label>
                    </div>
                    <div className="inputForm uf-inputs mt-1 select-dropdown-myfile">
                      <img
                        src={FiletypeImg}
                        alt=""
                        className="login-emailphone"
                      />
                      <input
                        type="text"
                        className="input login-input"
                        value={filetype}
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className="uf-inputs-main">
                    <div className="flex-column">
                      <label className="login-labels">{text_filesize1}</label>
                    </div>
                    <div className="inputForm uf-inputs mt-1 select-dropdown-myfile">
                      <img src={FileImg} alt="" className="login-emailphone" />
                      <input
                        type="text"
                        className="input login-input"
                        value={filesize}
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className="uf-inputs-main">
                    <div className="flex-column">
                      <label className="login-labels">
                        {text_description1}
                      </label>
                    </div>
                    <div className="inputForm uf-inputs mt-1 select-dropdown-myfile">
                      <img src={DescImg} alt="" className="login-emailphone" />
                      <input
                        type="text"
                        className="input login-input"
                        value={desc}
                        onChange={(e) => setDesc(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="uf-inputs-main">
                    <div className="flex-column">
                      <label className="login-labels">{text_filetag}</label>
                    </div>
                    <div className="inputForm uf-inputs mt-1 select-dropdown-myfile">
                      <img src={HashImg} alt="" className="login-emailphone" />
                      <input
                        type="text"
                        className="input login-input"
                        value={tag}
                        onChange={(e) => setTag(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="uf-inputs-main">
                    <div className="flex-column">
                      <label className="login-labels">{text_keywords}</label>
                    </div>
                    <div className="inputForm uf-inputs mt-1 select-dropdown-myfile">
                      <img
                        src={keywordImg}
                        alt=""
                        className="login-emailphone"
                      />
                      <input
                        type="text"
                        className="input login-input"
                        value={keyWord}
                        onChange={(e) => setKeyword(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="uf-inputs-main">
                    <div className="flex-column">
                      <label className="login-labels">{text_org1}</label>
                    </div>
                    <div className="inputForm uf-inputs mt-1 select-dropdown-myfile">
                      <img src={OrgImg} alt="" className="login-emailphone" />
                      <input
                        type="text"
                        className="input login-input"
                        value={org}
                        onChange={(e) => setOrg(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                {/*** Uploaded File Data ****/}
                {/*** Medical Data start****/}
                <div className="upf-5 uf-phd-text mt-4">
                  {text_personalhealthdata}
                </div>
                <div className="upf-6">
                  <div className="uf-inputs-main">
                    <div className="flex-column">
                      <label className="login-labels">
                        {text_patient} {text_firstname1}
                      </label>
                    </div>
                    <div className="inputForm uf-inputs mt-1 select-dropdown-myfile">
                      <img src={UserImg} alt="" className="login-emailphone" />
                      <input
                        type="text"
                        className="input login-input"
                        placeholder={text_enterfirstname}
                        value={fName}
                        onChange={(e) => setFname(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="uf-inputs-main">
                    <div className="flex-column">
                      <label className="login-labels">
                        {text_patient} {text_lastname1}
                      </label>
                    </div>
                    <div className="inputForm uf-inputs mt-1 select-dropdown-myfile">
                      <img src={UserImg} alt="" className="login-emailphone" />
                      <input
                        type="text"
                        className="input login-input"
                        placeholder={text_enterlastname}
                        value={lName}
                        onChange={(e) => setLname(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="uf-inputs-main">
                    <div className="flex-column">
                      <label className="login-labels">{text_country}</label>
                    </div>
                    <div className="inputForm uf-inputs mt-1 select-dropdown-myfile">
                      <ReactFlagsSelect
                        className="input login-input login-input-2 reg-input-2"
                        selected={country}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="uf-inputs-main">
                    <div className="flex-column">
                      <label className="login-labels mx-2">{text_id} 1</label>
                    </div>
                    <div className="inputForm   mt-1">
                      <select
                        className="ID-dropdown select-ID-sty select-ID-1"
                        value={selectedID1}
                        disabled
                      >
                        <option value={selectedID1}> {selectedID1}</option>
                      </select>
                      <input
                        className="inputForm uf-inputs  input  id-input edit-id-input"
                        placeholder={text_enterid}
                        onChange={(e) => setenterId1(e.target.value)}
                        value={enterId1}
                      />
                    </div>
                  </div>
                  <div className="uf-inputs-main">
                    <div className="flex-column">
                      <label className="login-labels mx-2">{text_id} 2</label>
                    </div>
                    <div className="inputForm  mt-1">
                      <select
                        className="ID-dropdown select-ID-sty select-ID-2"
                        value={selectedID2}
                        disabled
                      >
                        <option value={selectedID2}> {selectedID2}</option>
                      </select>
                      <input
                        className="inputForm uf-inputs  input id-input edit-id-input"
                        placeholder={text_enterid}
                        onChange={(e) => setenterId2(e.target.value)}
                        value={enterId2}
                      />
                    </div>
                  </div>
                  <div className="uf-inputs-main">
                    <div className="flex-column">
                      <label className="login-labels">{text_id} 3</label>
                    </div>
                    <div className="inputForm  mt-1">
                      <select
                        className="ID-dropdown select-ID-sty select-ID-3"
                        value={selectedID3}
                        disabled
                      >
                        <option value={selectedID3}> {selectedID3}</option>
                      </select>
                      <input
                        className="inputForm uf-inputs  input  id-input edit-id-input"
                        placeholder={text_enterid}
                        onChange={(e) => setenterId3(e.target.value)}
                        value={enterId3}
                      />
                    </div>
                  </div>
                  <div className="uf-inputs-main">
                    <div className="flex-column">
                      <label className="login-labels">{text_dob1}</label>
                    </div>
                    <div className="inputForm uf-inputs mt-1 select-dropdown-myfile">
                      <img src={DOBImg} alt="" className="login-emailphone" />
                      <DatePicker
                        selected={dateOfBirth}
                        onChange={(date) => setDateofbirth(date)}
                        dateFormat="MMM dd, yyyy"
                        className="uploaded-datepicker-sty"
                      />
                    </div>
                  </div>
                  <div className="uf-inputs-main">
                    <div className="flex-column">
                      <label className="login-labels">{text_age1}</label>
                    </div>
                    <div className="inputForm uf-inputs mt-1 select-dropdown-myfile">
                      <img src={AgeImg} alt="" className="login-emailphone" />
                      <input
                        type="number"
                        className="input login-input"
                        placeholder={text_enterage}
                        value={age}
                        onChange={(e) => setAge(e.target.value)}
                        min={0}
                      />
                    </div>
                  </div>
                  <div className="uf-inputs-main">
                    <div className="flex-column">
                      <label className="login-labels">{text_gender1}</label>
                    </div>

                    <div className="inputForm uf-inputs  mt-1 select-dropdown-myfile">
                      <img
                        src={GenderImg}
                        alt=""
                        className="login-emailphone"
                      />
                      <select
                        className="uf-inputs uploadselectdrop"
                        onChange={(e) => [setGender(e.target.value)]}
                        value={gender}
                      >
                        <option disabled selected value="NA">
                          {text_selectgender}
                        </option>
                        <option value="Male">{text_male}</option>
                        <option value="Female">{text_female}</option>
                        <option value="Other">{text_other}</option>
                        <option value="Prefer not to disclose">
                          {text_pntd}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div className="uf-inputs-main">
                    <div className="flex-column">
                      <label className="login-labels">{text_email1}</label>
                    </div>
                    <div className="inputForm uf-inputs mt-1 select-dropdown-myfile">
                      <img src={EmailImg} alt="" className="login-emailphone" />
                      <input
                        type="email"
                        className="input login-input"
                        placeholder={text_email1}
                        value={email}
                        onChange={(e) => setEmail(e.target.value.toLowerCase())}
                      />
                    </div>
                  </div>
                  <div className="uf-inputs-main">
                    <div className="flex-column">
                      <label className="login-labels">{text_phoneno1}</label>
                    </div>
                    <div className="inputForm uf-inputs mt-1 select-dropdown-myfile">
                      <PhoneInput
                        country={country}
                        disableDropdown
                        inputStyle={{
                          background: "transparent",
                          height: "40px",
                          width: "300px",
                          border: "none",
                        }}
                        inputClass="Register-input input login-input"
                        placeholder={text_enterphoneno}
                        value={phone}
                        onChange={(e) => [setPhone(e)]}
                      />
                    </div>
                  </div>
                </div>
                {/*** Medical Data end ****/}
              </>
            ) : (
              <>
                <div className="text-center">
                  <Loader3 />
                </div>
              </>
            )}

            <div className="uf-btns">
              <div>
                <button
                  className="box-btn"
                  onClick={() =>
                    navigate("/my-files", {
                      state: { selectedTab: selecttab },
                    })
                  }
                >
                  {text_cancel}
                </button>
              </div>
              <div>
                <button className=" box-btn-save" onClick={handleEditData}>
                  <span className="upload-file-btn-s2">{text_save}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}

export default EditFile;
