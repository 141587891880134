import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Error.css";
import errImg from "../../utils/images/ipfs-err-image.png";
import { FaHome } from "react-icons/fa";
import { BsFillArrowLeftCircleFill } from "react-icons/bs";
import Header from "../../Components/Header/Header";

const Error = () => {
  const navigate = useNavigate();
  const[text_warning,setText_warning] = useState("The page you are looking for might have been removed, had its name changed, or is temporarily unavailable!");
const[text_gohome,setText_gohome] = useState("Go Home");
const[text_goback,setText_goback] = useState("Go Back");

  return (
    <div>
      <Header />
      <div className="error-main">
        <div className="error-img-div">
          <img src={errImg} alt="" />
        </div>
        <div className="error-text">
          {text_warning}
        </div>
        <div className="error-btns">
          <button className="error-back-btn" onClick={() => navigate(-1)}>
            <BsFillArrowLeftCircleFill className="error-back-icon" /> {text_goback}
          </button>
          <button className="error-home-btn" onClick={() => navigate("/")}>
            <FaHome className="error-home-icon" /> {text_gohome}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Error;
