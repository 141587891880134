import { combineReducers, configureStore } from "@reduxjs/toolkit";
import MetaDataSlice from "./MetaDataSlice";
import AwsRootFolderReducer from "./AwsRootFolderSlice";
import BothFilesReducer from "./BothFilesSlice";
import SelectBucketReducer from "./SelectBucketSlice";
import GetBucketDataReducer from "./GetBucketdataSlice";
import IPFSRootFolderReducer from "./IPFSRootFolderSlice";
import sharewithmereducer from "./sharewithmeslice";
import CompanyUserListReducer from "./UserListSlice";
import CompanyProfileReducer from "./CompanyProfileSlice";
import RegisteredUserReducer from "./RegisteredUserSlice";
import EditFileDataReducer from "./EditFileDataSlice";
import UploadReducer from "./UploadSlice";
import sharedByMeReducer from "./SharedByMeSlice";
import cancelReducer from "./CancelSlice";

const combinedreducer = combineReducers({
  metadata: MetaDataSlice,
  singleUploadData: UploadReducer,

  awsRootData: AwsRootFolderReducer,
  bothfileData: BothFilesReducer,
  selectBucketData: SelectBucketReducer,
  getBucketData: GetBucketDataReducer,
  ipfsRootData: IPFSRootFolderReducer,
  sharewithmedata: sharewithmereducer,
  comapanyUserList: CompanyUserListReducer,
  comapanypofileData: CompanyProfileReducer,
  registeredUserList: RegisteredUserReducer,
  EditFileData: EditFileDataReducer,
  sharedByMe: sharedByMeReducer,
  cancel: cancelReducer
});

const store = configureStore({
  reducer: combinedreducer,
});

export default store;
