import React, { useEffect, useMemo, useState } from "react";
import Header from "../../Components/Header/Header";
import DataTable from "react-data-table-component";
import Cookie from "js-cookie";
import { GLOBAL_URL_DOMAIN_LIVE } from "../../utils/Files/url";
import axios from "axios";
import { errorToast, successToast } from "../../utils/Files/toasterMsgs";
import { useNavigate } from "react-router-dom";
import { customTableStyles } from "../../utils/Files/tableStyle";
import FilterComponent from "../../utils/Files/FilterComponent";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { ToastContainer } from "react-toastify";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import { GlobalConstants } from "../../utils/globalconstant";
import "./CompProfile.css";
import { useDispatch, useSelector } from "react-redux";
import { getAllUserData } from "../../redux/UserListSlice";

const xpath = require("xpath");
const dom = require("xmldom").DOMParser;

function UserList() {
  //variable
  const navigate = useNavigate();
  var CompanyId = sessionStorage.getItem("companyID");
  var CompanyName = sessionStorage.getItem("companyName");
  const useremail = sessionStorage.getItem("email");
  // const [userData, setUserData] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  //language variable start
  const [text_searchhere, setText_searchhere] = useState("Search Here...");
  const [text_back, setText_back] = useState("Back");
  const [text_email, setText_email] = useState("Email");
  const [text_phoneno, setText_phoneno] = useState("Phone Number");
  const [text_srno, setText_srno] = useState("SR NO.");
  const [text_username, setText_username] = useState("User Name");
  const [text_remove, setText_remove] = useState("Remove");
  const [text_cmp_user, setText_cmp_user] = useState("Company Users");
  //language variable end
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.comapanyUserList.userdata);
  console.log(userData);
  const columns = [
    {
      name: <>{text_srno}</>,
      selector: (row, index) => index + 1,
      width: "100px",
    },
    {
      name: <>{text_username}</>,
      selector: (row, index) =>
        (row?.userID?.firstName === "" ||
          row?.userID?.firstName === undefined) &&
        (row?.userID?.lastName === "" || row?.userID?.lastName === undefined)
          ? "NA"
          : row?.userID?.firstName + " " + row?.userID?.lastName,
    },
    {
      name: <>{text_email}</>,
      selector: (row, index) =>
        row?.userID?.email === "" || row?.userID?.email === undefined
          ? "NA"
          : row?.userID?.email,
    },
    {
      name: <>{text_phoneno}</>,
      selector: (row, index) =>
        row?.userID?.mobileNumber === "" ||
        row?.userID?.mobileNumber === undefined
          ? "NA"
          : row?.userID?.mobileNumber,
    },
    // {
    //   name: <>{text_remove}</>,
    //   selector: (row, index) =>
    //     userData.filter((val) => {
    //       val.userEmail !== sessionStorage.getItem("email");
    //     }) ? (
    //       <button
    //         className="unpin-file-btn"
    //         onClick={() => handleDeleteUser(row.companyID, row.userID._id)}
    //       >
    //         <DeleteOutlineIcon className="yf-ed-icon" />
    //       </button>
    //     ) : (
    //       <></>
    //     ),

    //   width: "180px",
    // },
    {
      name: <>{text_remove}</>,
      selector: (row, index) =>
        row?.userEmail !== sessionStorage.getItem("email") ? (
          <button
            className="unpin-file-btn"
            onClick={() => handleDeleteUser(row?.companyID, row?.userID._id)}
          >
            <DeleteOutlineIcon className="yf-ed-icon" />
          </button>
        ) : (
          <></>
        ),
      width: "180px",
    },
  ];

  const handleDeleteUser = (cmp_id, user_id) => {
    let headerConfig = {
      headers: {
        authorization: "Bearer " + sessionStorage.getItem("userToken"),
      },
    };
    var data = {
      company_id: cmp_id,
      user_id: user_id,
    };
    var API_URL = GLOBAL_URL_DOMAIN_LIVE + "/company/remove-user";

    axios
      .put(API_URL, data, headerConfig)
      .then((res) => {
        console.log("get data ipfs res***", res);
        if (res?.status === 200) {
          dispatch(getAllUserData());
          successToast(res?.data?.message);
        }
      })
      .catch((err) => {
        console.log("get data ipfs err***", err);
        if (err?.response?.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          Cookie.remove("fName");
          Cookie.remove("lName");
          Cookie.remove("userID");
          Cookie.remove("userToken");
          Cookie.remove("token_start_time");
          navigate("/");
        }
      })
      .then(function () {});
  };

  useEffect(() => {
    if (userData?.length === 0) {
      dispatch(getAllUserData());
    }
  }, [userData?.length]);

  const filteredItems = userData.filter(
    (item) =>
      JSON.stringify(item.userID)
        .toLowerCase()
        .indexOf(filterText.toLowerCase()) !== -1
  );

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <>
        <div className=" d-flex justify-content-sm-between flex-wrap px-5">
          <div className="mx-3">
            <button
              className="back-btn mx-3"
              onClick={() => navigate("/company-profile")}
            >
              <span>
                <MdOutlineKeyboardBackspace className="back-btn-icon back-icon" />
              </span>
              <span>{text_back}</span>
            </button>
          </div>
          <div>
            <FilterComponent
              onFilter={(e) => setFilterText(e.target.value)}
              PlaceHolder={text_searchhere}
              onClear={handleClear}
              filterText={filterText}
              className="fc-input fc-input-userlist"
              clearClass="fc-x"
            />
          </div>
        </div>
      </>
    );
  }, [filterText, resetPaginationToggle, text_searchhere]);

  useEffect(() => {
    try {
      SetLanguageText();
    } catch (err) {
      console.log("" + err);
    }
  }, []);

  const SetLanguageText = () => {
    try {
      var xml = localStorage.getItem(GlobalConstants.session_lang_xml);
      var doc = new dom().parseFromString(xml);
      setText_searchhere(
        xpath.select1("/resources/string[@name='text_searchhere']", doc)
          .firstChild.data
      );
      setText_back(
        xpath.select1("/resources/string[@name='text_back']", doc).firstChild
          .data
      );
      setText_email(
        xpath.select1("/resources/string[@name='text_email']", doc).firstChild
          .data
      );
      setText_phoneno(
        xpath.select1("/resources/string[@name='text_phoneno']", doc).firstChild
          .data
      );
      setText_srno(
        xpath.select1("/resources/string[@name='text_srno']", doc).firstChild
          .data
      );
      setText_username(
        xpath.select1("/resources/string[@name='text_username']", doc)
          .firstChild.data
      );
      setText_remove(
        xpath.select1("/resources/string[@name='text_remove']", doc).firstChild
          .data
      );
      setText_cmp_user(
        xpath.select1("/resources/string[@name='text_cmp_user']", doc)
          .firstChild.data
      );
    } catch (ert) {}
  };

  return (
    <>
      <Header />
      <div className="yf-ipfs-main mt-5">
        <h4 className="text-center mb-4">{text_cmp_user}</h4>
        {subHeaderComponent}
        <center>
          <div className="userlist mt-2">
            <DataTable
              columns={columns}
              data={filteredItems}
              pagination
              fixedHeader
              selectableRowsHighlight
              highlightOnHover
              customStyles={customTableStyles}
            />
          </div>
        </center>
      </div>
      <ToastContainer />
    </>
  );
}

export default UserList;
