import React, { useState, useEffect } from "react";
import "./TearmsAndPolicy.css";
import { Link, useNavigate } from "react-router-dom";
import { BsArrowLeft } from "react-icons/bs";
import GoToTop from "../../Components/GoToTop/GoToTop";

function Tearmsandservices() {
  const navigate = useNavigate();
  useEffect(() => {
    if (sessionStorage.getItem("ProgressBox") === "true") {
      sessionStorage.setItem("ProgressBox", false);
    }
  }, []);
  return (
    <>
      <div className="d-flex justify-content-end  p-5">
        <button className="backbtn-service" onClick={() => navigate("/signup")}>
          <BsArrowLeft />
          Back
        </button>
      </div>
      <div className="container termsbox ">
        <div className="termsheadbox p-3">
          <h3 className="text-center mt-4">TERMS OF SERVICE</h3>
          <h5 className="text-start">Last Revision: April 24, 2024</h5>
        </div>
        <p className="mt-4">
          Welcome to <b>Atom Ventures Inc Delaware, USA</b> This website is
          owned and operated by Atom Ventures Inc, Private Limited. By visiting
          our website and accessing the information, resources, services,
          products, and tools we provide, you understand and agree to accept and
          adhere to the following terms and conditions as stated in this policy
          (hereafter referred to as 'User Agreement'), along with the terms and
          conditions as stated in our Privacy Policy (please refer to the
          Privacy Policy section below for more information).{" "}
        </p>
        <p>
          We reserve the right to change this User Agreement from time to time
          without notice. You acknowledge and agree that it is your
          responsibility to review this User Agreement periodically to
          familiarize yourself with any modifications. Your continued use of
          this site after such modifications will constitute acknowledgment and
          agreement of the modified terms and conditions.
        </p>
        <h5>1. RESPONSIBLE USE AND CONDUCT</h5>
        <div>
          <ol className="conditionliststy">
            <li className="liststyle">
              In order to access our Resources, you may be required to provide
              certain information about yourself (such as identification, email,
              phone number, contact details, etc.) as part of the registration
              process, or as part of your ability to use the Resources. You
              agree that any information you provide will always be accurate,
              correct, and up to date.
            </li>
            <li className="liststyle">
              You are responsible for maintaining the confidentiality of any
              login information associated with any account you use to access
              our Resources. Accordingly, you are responsible for all activities
              that occur under your accounts.
            </li>
            <li className="liststyle">
              c. Accessing (or attempting to access) any of our Resources by any
              means other than through the means we provide, is strictly
              prohibited. You specifically agree not to access (or attempt to
              access) any of our Resources through any automated, unethical or
              unconventional means.
            </li>
            <li className="liststyle">
              d. Engaging in any activity that disrupts or interferes with our
              Resources, including the servers and/or networks to which our
              Resources are located or connected, is strictly prohibited.
            </li>
            <li className="liststyle">
              e. We do not assume any liability for any content posted by you or
              any other 3rd party users of our website. However, any content
              posted by you using any open communication tools on our website,
              provided that it doesn't violate or infringe on any 3rd party
              copyrights or trademarks, becomes the property of{" "}
              <Link
                to="https://www.atomcenter.io"
                target="blank"
              >
                https://www.atomcenter.io
              </Link>{" "}
              , and as such, gives us a perpetual, irrevocable, worldwide,
              royalty-free, exclusive license to reproduce, modify, adapt,
              translate, publish, publicly display and/or distribute as we see
              fit. This only refers and applies to content posted via open
              communication tools as described, and does not refer to
              information that is provided as part of the registration process,
              necessary in order to use our Resources. All information provided
              as part of our registration process is covered by our Privacy
              Policy.
            </li>
          </ol>
        </div>
        <h5>2. PRIVACY</h5>
        <p>
          Your privacy is very important to us, which is why we've created a
          separate Privacy Policy in order to explain in detail how we collect,
          manage, process, secure, and store your private information. Our
          privacy policy is included under the scope of this User Agreement. To
          read our privacy policy in its entirety, click here.
        </p>
        <h5>3. LIMITATION OF WARRANTIES</h5>
        <p>
          By using our website, you understand and agree that all Resources we
          provide are "as is" and "as available". This means that we do not
          represent or warrant to you that:
        </p>
        <h5>4. LIMITATION OF LIABILITY</h5>
        <p>
          In conjunction with the Limitation of Warranties as explained above,
          you expressly understand and agree that any claim against us shall be
          limited to the amount you paid, if any, for use of products and/or
          services. Atom Ventures Inc will not be liable for any direct,
          indirect, incidental, consequential or exemplary loss or damages which
          may be incurred by you as a result of using our Resources, or as a
          result of any changes, data loss or corruption, cancellation, loss of
          access, or downtime to the full extent that applicable limitation of
          liability laws apply.
        </p>
        <h5>5. COPYRIGHTS / TRADEMARKS</h5>
        <p>
          All content available on{" "}
          <b>https://www.atomcenter.io</b>, including but not
          limited to text, graphics, website name, code, images and logos are
          the intellectual property of <b>Atom Ventures Inc</b>, and are
          protected by applicable copyright and trademark law. Any inappropriate
          use, including but not limited to the reproduction, distribution,
          display or transmission of any content on this site is strictly
          prohibited, unless specifically authorized by <b>Atom Ventures Inc</b>
          .
        </p>
        <h5>6. TERMINATION OF USE</h5>
        <p>
          You agree that we may, at our sole discretion, suspend or terminate
          your access to all or part of our website and Resources with or
          without notice and for any reason, including, without limitation,
          breach of this User Agreement. Any suspected illegal, fraudulent or
          abusive activity may be grounds for terminating your relationship and
          may be referred to appropriate law enforcement authorities. Upon
          suspension or termination, your right to use the Resources we provide
          will immediately cease, and we reserve the right to remove or delete
          any information that you may have on file with us, including any
          account or login information.
        </p>
        <h5>7. GOVERNING LAW</h5>
        <p>
          This website is controlled by <b>Atom Ventures Inc</b> From our
          offices located in the state of Maharashtra, India. It can be accessed
          by most countries around the world. As each country has laws that may
          differ from those of Maharashtra, India by accessing our website, you
          agree that the statutes and laws of Maharashtra, India without regard
          to the conflict of laws and the United Nations Convention on the
          International Sales of Goods, will apply to all matters relating to
          the use of this website and the purchase of any products or services
          through this site.
        </p>
        <p>
          Furthermore, any action to enforce this User Agreement shall be
          brought in the federal or state courts located in Maharashtra, India.
          You hereby agree to personal jurisdiction by such courts, and waive
          any jurisdictional, venue, or inconvenient forum objections to such
          courts.
        </p>
        <h5>8. GUARANTEE</h5>
        <p>
          UNLESS OTHERWISE EXPRESSED, <b>ATOM VENTURES INC</b> EXPRESSLY
          DISCLAIMS ALL WARRANTIES AND CONDITIONS OF ANY KIND, WHETHER EXPRESS
          OR IMPLIED, INCLUDING, BUT NOT LIMITED TO THE IMPLIED WARRANTIES AND
          CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND
          NON-INFRINGEMENT.
        </p>
        <h5>9. Fees and Payment</h5>
        <p>
          Some of our services may be subject to fees. You agree to pay all fees
          associated with your use of such services. All fees are non-refundable
          unless otherwise stated in writing by the Company. We reserve the
          right to change our fees at any time upon notice to you.
        </p>
        <h5>10. Changes to Terms</h5>
        <p>
          We reserve the right to modify these Terms at any time without prior
          notice. Any changes to these Terms will be effective immediately upon
          posting on our website. Your continued use of our services after the
          posting of any changes to these Terms will constitute your acceptance
          of such changes.
        </p>
        <h5>11. CONTACT INFORMATION</h5>
        <p>
          If you have any questions or comments about these our Terms of Service
          as outlined above, you can contact us at:{" "}
          <Link to="mailto:support@atomcenter.io">support@atomcenter.io</Link>
        </p>
        <p>
          <b>Atom Ventures Inc </b>
        </p>
        <p>
          <b>Address:</b>
        </p>
        <p>
          <b>Phone:</b>
        </p>
      </div>
      <br />
      <br />
      <GoToTop />
    </>
  );
}

export default Tearmsandservices;
