import React, { useState, useEffect } from "react";
import "./TearmsAndPolicy.css";
import { Link, useNavigate } from "react-router-dom";
import { BsArrowLeft } from "react-icons/bs";
import GoToTop from "../../Components/GoToTop/GoToTop";

function Tearmsandservicesupdated() {
  // const navigate = useNavigate();
  // useEffect(() => {
  //   if (sessionStorage.getItem("ProgressBox") === "true") {
  //     sessionStorage.setItem("ProgressBox", false);
  //     console.log("object");
  //   }
  // }, []);
  return (
    <>
      {/* <div className="d-flex justify-content-end  p-5">
        <button className="backbtn-service" onClick={() => navigate("/signup")}>
          <BsArrowLeft />
          Back
        </button>
      </div>
      <div className="container termsbox ">
        <div className="termsheadbox p-3">
          <h3 className="text-center mt-4">TERMS OF SERVICE</h3>
          <h5 className="text-start">Last Modified: September 10, 2024</h5>
        </div>
        <b>1. Overview</b>
        <p>
          Andrena, Inc. dba moramba (<b>“moramba”, “we”, “us”, or “our”</b>)
          operates the moramba web browser extension (the “moramba Validator
          Extension”) as made available through our website
          https://www.morambainternet.com/ (the “Website”, and together with the
          moramba Validator Extension, the “moramba Services”) that permit users
          to participate as a proof of bandwidth validator in our decentralized
          wireless network for delivering Internet services that is maintained
          through proof of coverage related to bandwidth (which may include,
          without limitation, backhaul bandwidth, data served, and device
          geolocation) (the “moramba Network”).
        </p>
        <p>
          Please read these Terms and Conditions (these “Terms”) carefully as
          they govern your access to and use of the moramba Services and
          participation in the moramba Network through your access to and use of
          the moramba Services. These Terms incorporate by reference our Privacy
          Policy, available at https://www.morambainternet.com/privacy/. BY
          DOWNLOADING THE moramba VALIDATOR EXTENSION, ACCESSING OR USING ANY OF
          THE moramba SERVICES, OR OTHERWISE PARTICIPATING IN THE moramba
          NETWORK, YOU REPRESENT THAT (1) YOU HAVE READ, UNDERSTAND, AND AGREE
          TO BE BOUND BY THESE TERMS, (2) YOU ARE OF LEGAL AGE TO FORM A BINDING
          CONTRACT WITH moramba, (3) YOU HAVE THE AUTHORITY TO ENTER INTO THESE
          TERMS PERSONALLY OR ON BEHALF OF THE ENTITY (WHETHER OR NOT SUCH
          ENTITY IS REGISTERED OR INCORPORATED UNDER THE LAWS OF ANY
          JURISDICTION) YOU HAVE NAMED AS THE USER, AND TO BIND THAT ENTITY TO
          THESE TERMS; AND (4) YOU UNDERSTAND AND AGREE THAT YOU ARE SOLELY
          RESPONSIBLE FOR ENSURING THAT YOUR ACCESS TO AND USE OF THE moramba
          SERVICES AND PARTICIPATION IN THE moramba NETWORK COMPLY WITH THE LAWS
          OF YOUR JURISDICTION. THE TERM “YOU” REFERS TO THE INDIVIDUAL OR
          ENTITY, AS APPLICABLE, IDENTIFIED AS THE USER WHEN YOU REGISTERED ON
          THE moramba SERVICES. IF YOU DO NOT AGREE TO BE BOUND BY THESE TERMS,
          MAY NOT ACCESS OR USE THE moramba SERVICES OR PARTICIPATE IN THE
          moramba NETWORK.
        </p>
        <p>
          PLEASE READ SECTION 16 CAREFULLY AS IT CONTAINS PROVISIONS THAT GOVERN
          HOW TO RESOLVE DISPUTES BETWEEN YOU AND moramba. AMONG OTHER THINGS,
          SECTION 16 INCLUDES AN AGREEMENT TO ARBITRATE WHICH REQUIRES, WITH
          LIMITED EXCEPTIONS, THAT ALL DISPUTES BETWEEN YOU AND US SHALL BE
          RESOLVED BY BINDING AND FINAL ARBITRATION. SECTION 16 ALSO CONTAINS A
          CLASS ACTION AND JURY TRIAL WAIVER.{" "}
        </p>
        <p>
          Your use of, and participation in, certain moramba Services may be
          subject to additional terms (“Supplemental Terms”) and such
          Supplemental Terms will either be listed in these Terms or will be
          presented to you for your acceptance when you sign up to use the
          applicable supplemental moramba Service. If these Terms are
          inconsistent with the Supplemental Terms, the Supplemental Terms shall
          control with respect to the applicable supplemental moramba Service.
          These Terms and any applicable Supplemental Terms are referred to
          herein as this “Agreement.”
        </p>
        <b>2. Eligibility</b>
        <p>
          You represent and warrant that you and any entity on behalf of which
          you are entering into this Agreement: (a) do not reside, are not
          located, are not organized, are not ordinarily resident, and do not
          have an office or personnel in any jurisdiction that is subject to
          comprehensive U.S. sanctions (currently, Cuba, Iran, North Korea,
          Syria, and the Crimea, so-called Donetsk People’s Republic, and
          so-called Luhansk People’s Republic regions of Ukraine), or where
          access to or use of the moramba Services or participation in the
          moramba Network is illegal or not permitted by applicable law; and (b)
          are not Designated on any economic or financial sanctions list
          maintained by the U.S. Department of the Treasury’s Office of Foreign
          Assets Control or governmental authorities in any other applicable
          jurisdiction.{" "}
        </p>
        <b>
          3. Access to the moramba Services; Account Registration and
          Maintenance
        </b>
        <p>
          Subject to this Agreement, moramba grants you with a limited,
          personal, non-exclusive, non-transferable, non-sublicensable license
          to access and use the moramba Services and participate in the moramba
          Network solely as described hereunder. The foregoing license may be
          revoked by moramba at any time, in accordance with this Agreement. You
          acknowledge and agree that nothing set forth herein shall be construed
          as a sale of any ownership interest in or to the moramba Services,
          moramba Network, or any intellectual property rights associated
          therewith.
        </p>
        <p>
          In order to access the moramba Services, you will be required to
          register an account on the moramba Services (“Account”). In
          registering an Account, you shall (a) provide true, accurate, current,
          and complete information about yourself as prompted by the
          registration form (the “Registration Data”); and (b) maintain and
          promptly update the Registration Data to keep it true, accurate,
          current, and complete at a ll times.
        </p>
        <p>
          You will be responsible for the confidentiality and use of your
          username and password and agree not to transfer to any third party
          your right to use or access the moramba Services or the moramba
          Network via your Account. moramba disclaims any and all responsibility
          or liability for any unauthorized use of your Account. You agree to
          notify us immediately of any unauthorized use, theft, or appropriation
          of your Account, username, or password. We will not be liable for any
          loss that you incur as a result of someone else’s use of your Account,
          username, or password, either with or without your knowledge.{" "}
        </p>
        <b>4. moramba Property; Trademarks; Feedback</b>
        <p>
          4.1 You acknowledge and agree that moramba, its affiliates and
          licensors own all right, title, and interest in and to the moramba
          Services, moramba Network, and all works, designs, content, data,
          information, features, functionality, software, code, objects,
          algorithms, methods of operation, documentation, and other materials
          that form part of the moramba Services and moramba Network
          (collectively, the “moramba Property”). Neither this Agreement nor
          your use of the moramba Property convey or will convey to you any
          right, title, or interest in or in relation to the moramba Property,
          except for the limited right to access and use the moramba Services
          and participate in the moramba Network that is granted to you
          expressly in this Agreement. All rights in and to the moramba
          Property, and the intellectual property rights embodied in and
          associated with them are expressly reserved by their owner(s). Without
          limiting the foregoing, you may not use, reproduce, or otherwise
          exploit the moramba Property for any purpose, whether commercial or
          non-commercial, that is not expressly authorized by this Agreement
          without the prior written permission of moramba. You may not remove,
          alter or obscure any copyright, trademark, service mark or other
          proprietary rights notices incorporated in or accompanying any moramba
          Services.
        </p>
        <p>
          4.2 moramba’s stylized name and all related graphics, logos, service
          marks and trade names used on or in connection with any moramba
          Services, are the trademarks of moramba and may not be used without
          permission in connection with your, or any third-party, products or
          services. Other trademarks, service marks and trade names that may
          appear on or in the moramba Services are the property of their
          respective owners.{" "}
        </p>
        <p>
          4.3 You agree that submission of any ideas, suggestions, documents,
          and/or proposals to moramba through its suggestion, feedback, wiki,
          discord, forum, or other pages or means (“Feedback”) is at your own
          risk and that moramba has no obligations (including without limitation
          obligations of confidentiality) with respect to such Feedback. You
          represent and warrant that you have all rights necessary to submit the
          Feedback. You hereby grant to moramba a fully paid, royalty-free,
          perpetual, irrevocable, worldwide, non-exclusive, and fully
          sublicensable right and license to use, reproduce, perform, display,
          distribute, adapt, modify, re-format, create derivative works of, and
          otherwise commercially or non-commercially exploit in any manner, any
          and all Feedback, and to sublicense the foregoing rights, in
          connection with the operation and maintenance of the moramba Services
          and/or moramba’s business.{" "}
        </p>
        <b>5. Code of Conduct; Restrictions</b>
        <p>
          You agree that you are solely responsible for your conduct in
          connection with your access to and use of the moramba Property. You
          agree that you will comply with this Agreement and all applicable laws
          and will not (and will not attempt to or permit others to):
        </p>
        <p>
          ‍5.1 provide, disclose, divulge or make available to, or permit use of
          the moramba Property by, any third party;
        </p>
        <p>
          5.2 rent, lease, lend, sell, sublicense, assign, distribute, publish,
          transfer, or otherwise make available any moramba Property to any
          third party, including on or in connection with the Internet or any
          time-sharing, service bureau, software as a service, cloud, or other
          technology or service;
        </p>
        <p>
          5.3 copy, modify, or create derivative works or improvements of or
          based on the moramba Property;
        </p>
        <p>
          5.4 interfere, or attempt to interfere, with the moramba Property in
          any way, including to reverse engineer, disassemble, decompile,
          decode, adapt, or otherwise attempt to derive or gain access to the
          source code of the moramba Property, in whole or in part;
        </p>
        <p>
          5.5 circumvent, or attempt to circumvent, any geographical
          restrictions, privacy measures, or other access controls, including
          the use of VPNs for such purposes;
        </p>
        <p>
          5.6 facilitate, incite, or engage in the unethical manipulation or
          misuse of data or information in a manner that violates applicable law
          or undermines the access to, use, or operation of the moramba
          Property;
        </p>
        <p>
          5.7 engage in spamming, mailbombing, spoofing or any other fraudulent,
          illegal or unauthorized use of the moramba Property;
        </p>
        <p>
          5.8 engage in any activity that impedes or restricts any other user’s
          access, use, or enjoyment of the moramba Property or which may cause
          harm to such user;
        </p>
        <p>
          5.9 remove, obscure, or alter any copyright notice, trademarks or
          other proprietary rights notices affixed to or contained within the
          moramba Property;
        </p>
        <p>
          5.10 engage in or allow any action involving the moramba Property that
          is inconsistent with the terms and conditions of this Agreement;
        </p>
        <p>
          5.11 impersonate any user or other person or entity or otherwise
          engage is any fraudulent or intentionally misleading activities in
          connection with your access or use of the moramba Property;
        </p>
        <p>
          5.12 bypass or breach any security device, or protection used by or in
          connection with the moramba Property, including in connection with the
          access or use of the moramba Property;
        </p>
        <p>
          5.13 input, upload, transmit, or otherwise provide to or through the
          moramba Property any information or materials that are unlawful or
          injurious, or contain, transmit, or activate any software or other
          technology, including any virus, trojan horse, worm, backdoor,
          malware, or other malicious computer code, the purpose or effect of
          which is to: (a) permit unauthorized access to, or to destroy,
          disrupt, disable, distort, or otherwise harm or impede in any manner
          any: (i) computer, software, firmware, hardware, system or network; or
          (ii) application or function of any of the foregoing or the security,
          integrity, confidentiality, or use of any data; or (b) prevent you or
          any other authorized user from accessing or using the moramba Services
          or moramba Network as intended (Harmful Code);
        </p>
        <p>
          5.14 damage, destroy, disrupt, disable, impair, interfere with, or
          otherwise impede or harm in any manner moramba, its affiliates, or the
          moramba Property, including our provision of any data, products, or
          services to any third party, in whole or in part;
        </p>
        <p>
          5.15 access or use the moramba Property in any manner or for any
          purpose that (i) infringes, misappropriates, or otherwise violates the
          intellectual property or other rights of the moramba or any third
          party; or (ii) violates any applicable law;
        </p>
        <p>
          5.16 access or use the moramba Property for purposes of competitive
          analysis of the moramba Property, the development, provision or use of
          a competing software service or product, or any other purpose that is
          to the moramba’s detriment or commercial disadvantage; or
        </p>
        <p>
          5.17 otherwise access or use the moramba Property beyond the scope of
          the authorizations granted under this Agreement.
        </p>
        <p>
          Engaging in prohibited conduct constitutes a breach of this Agreement
          and may subject you to civil liability or criminal prosecution under
          applicable laws. moramba reserves the right to investigate and/or
          terminate your Account, without a refund of any purchases or
          settlement of any outstanding Accounts, if you have violated this
          Agreement, misused any moramba Property or have acted in a manner that
          moramba considers as inappropriate or unlawful.
        </p>
        <b>‍6. Availability; Downtime; Updates</b>
        <p>
          You are solely responsible, at your own expense, for obtaining and
          maintaining all Internet access, computer hardware, and other
          equipment and services needed to access and use the moramba Property.
        </p>
        <p>
          While we endeavor to maintain the availability of the moramba Services
          and moramba Network, we cannot promise that the moramba Services or
          moramba Network or their availability will be uninterrupted, secure,
          or error-free. We reserve the right to interrupt or suspend access to
          the moramba Services, moramba Network, or any other moramba Property,
          or any part thereof, with or without prior notice for any reason, and
          you will not be entitled to any refund of fees or other compensation
          for any such interruption or suspension.
        </p>
        <p>
          You understand that the moramba Services and the moramba Network are
          evolving. You acknowledge and agree that moramba may update moramba
          Services and the moramba Network, or revise, supplement or delete any
          information, content, features, functionality, services, or resources
          contained on the moramba Services or the moramba Network, and moramba
          reserves the right to make such updates or changes with or without
          prior notification to past, current, or prospective users, including
          you. You may need to update third-party software from time to time in
          order to use the moramba Services and participate in the moramba
          Network.
        </p>
        <b>7. Disclaimers</b>
        <p>
          The disclaimers set out in this Section 7 will not limit or exclude
          any warranties that cannot be excluded or limited excluded under
          applicable law.
        </p>
        <p>7.1 General Disclaimer</p>
        <p>
          You acknowledge and agree that the moramba Services, the moramba
          Network and all other moramba Property are provided as-is and as
          available, without any warranty whatsoever. moramba does not make any,
          and expressly disclaims all, representations, warranties, and
          conditions of any kind, whether express or implied, statutory or
          otherwise, including without limitation any implied warranty or
          condition of merchantability, fitness for a particular purpose, title,
          or non-infringement arising from the use of the moramba Services, to
          the maximum extent permitted by applicable law. For clarity, and
          without limiting the generality of the foregoing, moramba make no
          warranty of any kind that the moramba Property, including the moramba
          Services or moramba Network, or any other products, services,
          opportunities, Rewards (as defined below), communications, or the
          results of the receipt of use thereof, will meet your or any other
          person’s requirements, operate without interruption, achieve any
          intended result, be compatible or work with any software, system,
          device, or other services, or be available, timely, secure, accurate,
          complete, free of Harmful Code, or error-free. You are responsible for
          implementing adequate measures to protect the security and integrity
          of your activities on the Internet, including by installing anti-virus
          protection and maintaining external backups to recover any lost data
          or information.
        </p>
        <p>7.2 Disclaimer re: Professional Advice</p>
        <p>
          No advice or information provided or through the moramba Services,
          moramba Network, or any other moramba Property is intended to be
          investment, financial, legal, tax, or other professional advice.
          moramba and its affiliates do not provide investment, financial,
          legal, tax, or other professional advice and recommend that all users
          of the moramba Services and moramba Network obtain any investment,
          financial, legal, tax, or other professional advice that such user
          deems necessary in connection with their download of the moramba
          Validator Extension, access to or use of the moramba Services, or
          participation in the moramba Network.
        </p>
        <p>7.3 Disclaimer re: Harmful Code</p>
        <p>
          moramba is not responsible or liable for any software, computer
          viruses, Harmful Code that may infect or otherwise affect the use of
          your computer equipment or other property on account of your access to
          or use of the moramba Services or your downloading or otherwise
          acquiring any content or other information from or through the moramba
          Services.
        </p>
        <p>7.4 Disclaimer re: Rewards</p>
        <p>
          The moramba Services allow users to contribute their Internet
          connectivity and bandwidth in support of the creation, operation, and
          proliferation of the moramba Network. Users may also use a Chrome
          extension that enables them to prove the coverage and bandwidth of the
          moramba Network (the “Chrome Extension”). In exchange for their use of
          the Chrome Extension and contribution to the moramba Network, certain
          users will be able to participate in a program to accumulate points
          (such points, “Rewards” and such program the “Rewards Program”). Your
          opportunity to receive such Rewards in connection will be determined
          by us in accordance with the then-current terms of the Rewards
          program, which is subject to change from time to time without notice
          in our sole discretion. You understand and agree that your access to
          and use of the moramba Services and participation in the moramba
          Network does not entitle to you to receive any Rewards. To the extent
          that you are attributed any Rewards, such Rewards are not, and may
          never convert to, accrue to, be used to calculate, or become any other
          physical or virtual assets, including without limitation cash, any
          cash equivalent, or cryptocurrency. Rewards have no monetary value and
          do not constitute currency of any kind. Rewards are not transferrable
          or redeemable for any fiat, currency, property, or other form of
          value. Users are not permitted to sell, transfer, loan, rent, lease,
          trade, or exchange Rewards, or rely on Rewards to obtain credit or
          set-off or pay-down any amount. moramba does not make any
          representation or warranty of any kind, whether express or implied,
          statutory or otherwise as to the availability, amount, nature, or
          utility of any Rewards. Rewards are provided solely as an optional
          enhancement to users to incentivize participation in the moramba
          Network. Rewards do not constitute compensation or any other form of
          consideration for services. You agree that Rewards may be canceled or
          revoked by moramba at any time, including if you breach this
          Agreement; misuse or abuse the Rewards Program; or commit or
          participate in any fraudulent activity related to the Rewards Program.
          moramba reserves the right to modify or terminate the Rewards Program
          and/or cancel or revoke any of your Rewards at any time, for any or
          for no reason, with or without notice, and without liability to you.{" "}
        </p>
        <p>7.5 Disclaimer re: Digital Wallets</p>
        <p>If you provide information relating to your </p>
        <p>
          on through, or in connection with the moramba Services or any linked
          or related website that permits the payment, acquisition, or transfer
          of any type of currency or other assets, you understand and agree that
          you are solely responsible and liable for maintaining the security of
          your digital wallet and your control over your passwords, private
          keys, seed phrases, or other credentials relating to the access and
          use of your digital wallet. moramba will never ask for your seed
          phrases, private keys, or other access credentials, and you should not
          provide any such access credentials to moramba. We are not responsible
          or liable for managing or maintaining the security of your digital
          wallet or for any unauthorized access to or use of your digital
          wallet, including but not limited to any loss of any assets or
          currency in such digital wallet. You acknowledge and agree that you
          understand that unauthorized access to your digital wallet by third
          parties could result in the loss or theft of the contents of your
          wallet and that we have no responsibility or liability for storing,
          retaining, securing, or recovering your digital wallet, access to your
          digital wallet, or the contents of your digital wallet, including any
          passwords, private keys, seed phrases, or other credentials. By
          providing or using any digital wallet in connection with the moramba
          Services or any linked or related website, you agree that you are
          using the digital wallet under the terms and conditions of the
          applicable provider of the digital wallet. No digital wallet is
          created, operated, or maintained by moramba or affiliated with
          moramba, and moramba does not have custody or control over the
          contents of your digital wallet and have no ability to retrieve or
          transfer its contents. Your relationship with any digital wallet
          provider is governed by the applicable terms and conditions of that
          wallet provider, not this Agreement. You further represent and warrant
          that you own or have the authority to use such digital wallet.
        </p>
        <p>
          7.6 Disclaimer re Blockchain Technologies; Representations and
          warranties.
        </p>
        <p>
          The moramba Services may also reference or provide links to
          applications or applications related to smart contracts, protocols,
          and other blockchain technologies (collectively, “Blockchain
          Technologies”). The Blockchain Technologies are not part of the
          moramba Services or the moramba Network, and your access to and use of
          any Blockchain Technologies is entirely at your own risk. In addition,
          any third party technologies required to be accessed or used in order
          to interact with the Blockchain Technologies, including any digital
          wallet, are not part of the moramba Services or the moramba Network
          and your access to and use of such third party technologies are at
          your own risk. You represent and warrant that:
        </p>
        <p>
          (a) There is no legal proceeding pending that relates to your
          activities relating to buying, selling, staking, or otherwise using
          cryptocurrency or any other token- or digital asset- trading or
          blockchain technology related activities; and (b) You have not failed
          to comply with, and have not violated, any applicable legal
          requirement relating to any blockchain technologies or token- or
          digital asset-trading activities, and no investigation or review by
          any governmental entity is pending or, to your knowledge, has been
          threatened against or with respect to you, nor does any government
          order or action prohibit you or any of your representatives from
          engaging in or continuing any conduct, activity or practice relating
          to cryptocurrency.{" "}
        </p>
        <b>8. Assumption of Risk; Responsibilities</b>
        <p>
          All users are responsible for their (a) download of the moramba
          Validator Extension, access and use of the moramba Services,
          participation in the moramba Network, and use of any other moramba
          Property; and (b) access, use, and interaction with the Third-Party
          Services and any other third party services, products, or
          technologies, including your compliance with all applicable laws in
          connection with the foregoing. Your access to and use of the moramba
          Services, participation in the moramba Network, and use of any other
          moramba Property is purely voluntary, and you freely accept all risks
          and liabilities associated with the foregoing.
        </p>
        <b>9. Investigations; Enforcement</b>
        <p>You acknowledge and agree that we reserve the right to:</p>
        <ul>
          <li>
            take any necessary legal action, including but not limited to,
            referral to law enforcement, for any illegal or unauthorized use of
            the moramba Services, moramba Network, or any other moramba
            Property;
          </li>
          <li>
            cooperate fully with any law enforcement authorities or court order
            requesting or directing us to disclose the identity or other
            information of anyone using the moramba Services or moramba Network.
          </li>
        </ul>
        <p>
          You waive and release moramba and its affiliates from and against any
          and all claims resulting from any action taken by moramba or its
          affiliates during, or as a consequence of, investigations by any
          governmental authority.
        </p>
        <b>10. Limitation of Liability</b>
        <p>10.1 Disclaimer of Certain Damages</p>
        <p>
          TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL
          moramba OR ITS AFFILIATES BE LIABLE FOR ANY INDIRECT, SPECIAL,
          INCIDENTAL, EXEMPLARY, PUNITIVE, OR CONSEQUENTIAL DAMAGES, OR LOSS OF
          PROFITS, USE, DATA, OR OTHER ECONOMIC ADVANTAGE, ARISING UNDER, IN
          CONNECTION WITH, OR RELATED TO (A) THIS AGREEMENT; (B) THE OPERATION
          OR PROVISION OF THE moramba SERVICES, moramba NETWORK, OR ANY OTHER
          moramba PROPERTY; (C) YOUR ACCESS TO OR USE OF THE moramba SERVICES,
          moramba NETWORK, OR ANY OTHER moramba PROPERTY; OR (D) YOUR
          PARTICIPATION IN THE moramba NETWORK, HOWSOEVER ARISING, EITHER OF A
          BREACH OF THIS AGREEMENT, NEGLIGENCE, STRICT LIABILITY, TORT, OR ANY
          OTHER THEORY OF LIABILITY, EVEN IF moramba OR ITS AFFILIATES HAVE BEEN
          PREVIOUSLY ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
        </p>
        <p>10.2 Limitation of Liability for Direct Damages</p>
        <p>
          TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE TOTAL AGGREGATE
          LIABILITY OF THE moramba AND ITS AFFILIATES FOR DIRECT DAMAGES ARISING
          UNDER, IN CONNECTION WITH, OR RELATED TO (A) THIS AGREEMENT; (B) THE
          OPERATION OR PROVISION OF THE moramba SERVICES, moramba NETWORK, OR
          ANY OTHER moramba PROPERTY; (C) YOUR ACCESS TO OR USE OF THE moramba
          SERVICES, moramba NETWORK, OR ANY OTHER moramba PROPERTY; OR (D) YOUR
          PARTICIPATION IN THE moramba NETWORK, WILL BE LIMITED TO $1.
        </p>
        <p>10.3 Exclusions</p>
        <p>
          CERTAIN JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF
          CERTAIN DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE
          EXCLUSIONS OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MIGHT HAVE
          ADDITIONAL RIGHTS.{" "}
        </p>
        <b>11. Indemnification</b>
        <p>
          You agree to indemnify, defend, and hold harmless moramba, its
          affiliates, and their respective directors, officers, shareholders,
          employees, staff, representatives, agents, licensors, licensees,
          successors, and assigns (the moramba Indemnified Parties) from and
          against any and all claims, demands, damages, judgments, penalties,
          fines, costs, and expenses, including legal fees, and disbursements,
          incurred by the moramba Indemnified Parties (or any of them), relating
          to, arising from, or in connection with a third party claim, demand,
          action, or proceeding arising out of (a) an allegation that your
          unauthorized use of any of the moramba Property infringes,
          misappropriates, or otherwise violates any third party’s rights or is
          otherwise unlawful; (b) your breach of this Agreement, including, any
          representation, warranty, covenant, or obligation in this Agreement;
          (c) your negligence, fraud, or willful misconduct; (d) your use of the
          moramba Property in a manner that is not authorized by this Agreement;
          (e) your use of the moramba Property in combination with data,
          software, hardware, equipment or technology not provided by moramba or
          authorized by moramba in writing; or (f) any modifications made by you
          to any moramba Property that have not been authorized by moramba in
          writing.
        </p>
        <b>12. Term; Suspension; Termination</b>
        <p>12.1 Term</p>
        <p>
          The Agreement commences on the date when you accept the Agreement (as
          described in the preamble above) and remain in full force and effect
          while you access or use the moramba Services, participate in the
          moramba Network, or to otherwise access or use any moramba Property,
          unless terminated earlier in accordance with the Agreement.
        </p>
        <p>12.2 Termination or Suspension by moramba</p>
        <p>
          moramba may, at any time and from time to time, without notice,
          suspend or terminate your access or right to access or use the moramba
          Services, participate in the moramba Network, or to otherwise access
          or use any moramba Property, if moramba determines, in its sole
          discretion, that you have violated or otherwise breached this
          Agreement.
        </p>
        <p>
          A suspension may be for such period of time as moramba considers
          necessary to permit the thorough investigation of the conduct at
          issue. You agree that, upon the suspension or termination of your
          access to or use of the moramba Services or participation in the
          moramba Network, you will immediately cease and desist from all access
          to and use of the moramba Services, participation in the moramba
          Network, and access to or use of the moramba Property, including in
          accordance with the written directions of moramba.
        </p>
        <p>12.3 Termination by You</p>
        <p>
          If you want to terminate the moramba Services provided by moramba, you
          may do so by (a) notifying moramba at any time and (b) ceasing all
          further use of the moramba Property and all further participation in
          the moramba Network. Your notice should be sent, in writing, to
          moramba’s address set forth below.
        </p>
        <p>12.4 Effect of Termination</p>
        <p>
          Upon termination of the moramba Services, your right to use the
          moramba Services and to participate in the moramba Network will
          automatically terminate immediately. moramba will not have any
          liability whatsoever to you for any suspension or termination. All
          provisions of the Agreement which by their nature should survive,
          shall survive termination of the moramba Services, including without
          limitation, ownership provisions, warranty disclaimers,
          indemnification and limitation of liability.
        </p>
        <p>12.5 No Subsequent Registration</p>
        <p>
          If your ability to access the moramba Services, is discontinued by
          moramba due to your violation of any portion of the Agreement, then
          you agree that you shall not attempt to re-register with or access the
          moramba Services, and you acknowledge that you will not be entitled to
          receive a refund for any Fees related to those Services to which your
          access has been terminated.
        </p>
        <b>13. Third-Party Services</b>
        <p>
          Certain features of the moramba Services and the moramba Network may
          rely on third party websites, social channels, services, technology,
          or applications accessible or otherwise connected to the moramba
          Services or the moramba Network but not provided by moramba, including
          without limitation the Blockchain Technologies, your digital wallet,
          links to third party websites for the purpose of connecting you with
          third parties who may offer Rewards in connection with your use of the
          moramba Services (each, a “Third-Party Service” and, collectively,
          “Third-Party Services”). Notwithstanding anything to the contrary in
          this Agreement, you acknowledge and agree that (i) moramba is not
          responsible or liable for any damages, liabilities, or other harms
          caused by or arising in connection with your use of or reliance on (or
          inability to use or rely on) the Third-Party Services or any
          information, content, goods, or services available on or through the
          Third-Party Services; and (ii) moramba shall be under no obligation to
          inquire into and shall not be liable for any damages, other
          liabilities or harm to any person or entity relating to any losses,
          delays, failures, errors, interruptions or loss of data occurring
          directly or indirectly by reason of Third-Party Services or any other
          circumstances beyond moramba’s control, including without limitation
          the failure of a Third-Party Service. Please be aware that the
          Third-Party Services may be governed by separate terms and conditions
          and privacy policies. We encourage you to be aware when you click on
          any links to Third-Party Services. Your use of the Third-Party
          Services is at your own risk.
        </p>
        <b>14. Changes to this Agreement</b>
        <p>
          You acknowledge and agree that we may make changes to this Agreement
          from time to time, for any reason, in our sole and absolute
          discretion. When changes are made, moramba will make a new copy of
          these Terms available at the Website and any new Supplemental Terms
          will be made available from within, or through, the affected moramba
          Service on the Website. We will also update the “Last Updated” date at
          the top of these Terms. If the changes that we make include material
          changes that affect your rights and obligations, we will take
          reasonable steps to notify you of the changes in advance, including by
          email or by notification through the moramba Services or through our
          social media channels. Any changes to this Agreement will be effective
          immediately for new users of the Website and/or the moramba Services
          and will be effective thirty (30) days after posting notice of such
          changes on the Website for existing users. moramba may require you to
          provide consent to the updated Agreement in a specified manner before
          further use of the Website, and/or the moramba Services is permitted.
          If you do not agree to any change(s) after receiving a notice of such
          change(s), you shall stop using the moramba Services. Otherwise, your
          continued use of the moramba Services constitutes your acceptance of
          such change(s). PLEASE REGULARLY CHECK THE WEBSITE TO VIEW THE
          THEN-CURRENT TERMS.
        </p>
        <b>15. Governing Law</b>
        <p>
          This Agreement and any action arising under or in connection with this
          Agreement shall be governed by and construed in accordance with, in
          all respects including as to its validity, interpretation and effect,
          the laws of the state of New York, without giving effect to its
          principles or rules of conflict of laws that provide for the
          application of the laws of another jurisdiction. The United Nations
          Convention on Contracts for the International Sale of Goods does not
          apply to this Agreement.{" "}
        </p>
        <b>16. Dispute Resolution</b>
        <p>
          Please read this Section 16 (the “Arbitration Agreement”) carefully.
          It is part of your contract with moramba and affects your rights. It
          contains procedures for MANDATORY BINDING ARBITRATION AND A CLASS
          ACTION WAIVER.{" "}
        </p>
        <p>16.1 Applicability of Arbitration Agreement </p>
        <p>
          Subject to the terms of this Arbitration Agreement (including Section
          16.2, which shall apply notwithstanding any laws or rules in your
          jurisdiction that may prohibit mandatory or compelled arbitration),
          you and moramba agree that any dispute, claim, disagreements arising
          out of or relating in any way to your access to or use of the moramba
          Property or this Agreement and prior versions of this Agreement,
          including claims and disputes that arose between us before the
          effective date of this Agreement (each, a “Dispute”) will be resolved
          by binding arbitration, rather than in court, except that: (1) you and
          moramba may assert claims or seek relief in small claims court if such
          claims qualify and remain in small claims court; and (2) you or
          moramba may seek equitable relief in court for infringement or other
          misuse of intellectual property rights (such as trademarks, trade
          dress, domain names, trade secrets, copyrights, and patents). For
          purposes of this Arbitration Agreement, “Dispute” will also include
          disputes that arose or involve facts occurring before the existence of
          this or any prior versions of this Agreement as well as claims that
          may arise after the termination of this Agreement.
        </p>
        <b>16.2 Informal Dispute Resolution </b>
        <p>
          There might be instances when a Dispute arises between you and
          moramba. If that occurs, moramba is committed to working with you to
          reach a reasonable resolution. You and moramba agree that good faith
          informal efforts to resolve Disputes can result in a prompt, low‐cost
          and mutually beneficial outcome. You and moramba therefore agree that
          before either party commences arbitration against the other (or
          initiates an action in small claims court if a party so elects), we
          will personally meet and confer telephonically or via videoconference,
          in a good faith effort to resolve informally any Dispute covered by
          this Arbitration Agreement (“Informal Dispute Resolution Conference”).
          If you are represented by counsel, your counsel may participate in the
          conference, but you will also participate in the conference. The party
          initiating a Dispute must give notice to the other party in writing of
          its intent to initiate an Informal Dispute Resolution Conference
          (“Notice”), which shall occur within 45 days after the other party
          receives such Notice, unless an extension is mutually agreed upon by
          the parties. Notice to moramba that you intend to initiate an Informal
          Dispute Resolution Conference should be sent by email to the contact
          information set forth below. The Notice must include: (1) your name,
          telephone number, mailing address, and e‐mail address; (2) the name,
          telephone number, mailing address and e‐mail address of your counsel,
          if any; and (3) a description of your Dispute. The Informal Dispute
          Resolution Conference shall be individualized such that a separate
          conference must be held each time either party initiates a Dispute,
          even if the same law firm or group of law firms represents multiple
          users in similar cases, unless all parties agree; multiple individuals
          initiating a Dispute cannot participate in the same Informal Dispute
          Resolution Conference unless all parties agree. In the time between a
          party receiving the Notice and the Informal Dispute Resolution
          Conference, nothing in this Arbitration Agreement shall prohibit the
          parties from engaging in informal communications to resolve the
          initiating party’s Dispute. Engaging in the Informal Dispute
          Resolution Conference is a condition precedent and requirement that
          must be fulfilled before commencing arbitration. The statute of
          limitations and any filing fee deadlines shall be tolled while the
          parties engage in the Informal Dispute Resolution Conference process
          required by this section.
        </p>
        <b>16.3 Waiver of Jury Trial</b>
        <p>
          YOU AND moramba HEREBY WAIVE ANY CONSTITUTIONAL AND STATUTORY RIGHTS
          TO SUE IN COURT AND HAVE A TRIAL IN FRONT OF A JUDGE OR A JURY. You
          and moramba are instead electing that all Disputes shall be resolved
          by arbitration under this Arbitration Agreement, except as specified
          in the Section entitled “Applicability of Arbitration Agreement”
          above. There is no judge or jury in arbitration, and court review of
          an arbitration award is subject to very limited review.
        </p>
        <p>16.4 Waiver of Class and Other Non-Individualized Relief </p>
        <p>
          YOU AND moramba AGREE THAT, EXCEPT AS SPECIFIED IN SECTION 16.9, EACH
          OF US MAY BRING CLAIMS AGAINST THE OTHER ONLY ON AN INDIVIDUAL BASIS
          AND NOT ON A CLASS, REPRESENTATIVE, OR COLLECTIVE BASIS, AND THE
          PARTIES HEREBY WAIVE ALL RIGHTS TO HAVE ANY DISPUTE BE BROUGHT, HEARD,
          ADMINISTERED, RESOLVED, OR ARBITRATED ON A CLASS, COLLECTIVE,
          REPRESENTATIVE, OR MASS ACTION BASIS. ONLY INDIVIDUAL RELIEF IS
          AVAILABLE, AND DISPUTES OF MORE THAN ONE CUSTOMER OR USER CANNOT BE
          ARBITRATED OR CONSOLIDATED WITH THOSE OF ANY OTHER CUSTOMER OR USER.
          Subject to this Arbitration Agreement, the arbitrator may award
          declaratory or injunctive relief only in favor of the individual party
          seeking relief and only to the extent necessary to provide relief
          warranted by the party’s individual claim. Nothing in this paragraph
          is intended to, nor shall it, affect the terms and conditions under
          Section 16.9. Notwithstanding anything to the contrary in this
          Arbitration Agreement, if a court decides by means of a final
          decision, not subject to any further appeal or recourse, that the
          limitations of this Section 16.4 are invalid or unenforceable as to a
          particular claim or request for relief (such as a request for public
          injunctive relief), you and moramba agree that that particular claim
          or request for relief (and only that particular claim or request for
          relief) shall be severed from the arbitration and may be litigated in
          the courts of New York, sitting in New York. All other Disputes shall
          be arbitrated or litigated in small claims court. This Section 16.4
          does not prevent you or moramba from participating in a class-wide
          settlement of claims.
        </p>
        <p>16.5 Rules and Forum </p>
        <p>
          This Agreement evidences a transaction involving interstate commerce;
          and notwithstanding any other provision herein with respect to the
          applicable substantive law, the Federal Arbitration Act, 9 U.S.C. § 1
          et seq., will govern the interpretation and enforcement of this
          Arbitration Agreement and any arbitration proceedings. If the Informal
          Dispute Resolution Conference process described above does not resolve
          satisfactorily within sixty (60) days after receipt of your Notice,
          you and moramba agree that either party shall have the right to
          finally resolve the Dispute through binding arbitration. The
          arbitration will be administered by the American Arbitration
          Association (“AAA”), in accordance with the Consumer Arbitration Rules
          (the “AAA Rules”) then in effect, except as modified by this section
          of this Arbitration Agreement. The AAA Rules are currently available
          at https://www.adr.org/sites/default/files/Consumer%20Rules.pdf. A
          party who wishes to initiate arbitration must provide the other party
          with a request for arbitration (the “Request”). The Request must
          include: (1) the name, telephone number, mailing address, e‐mail
          address of the party seeking arbitration (if applicable) as well as
          the applicable digital wallet address; (2) a statement of the legal
          claims being asserted and the factual bases of those claims; (3) a
          description of the remedy sought and an accurate, good‐faith
          calculation of the amount in controversy in United States Dollars; (4)
          a statement certifying completion of the Informal Dispute Resolution
          Conference process as described above; and (5) evidence that the
          requesting party has paid any necessary filing fees in connection with
          such arbitration. If the party requesting arbitration is represented
          by counsel, the Request shall also include counsel’s name, telephone
          number, mailing address, and email address. Such counsel must also
          sign the Request. By signing the Request, counsel certifies to the
          best of counsel’s knowledge, information, and belief, formed after an
          inquiry reasonable under the circumstances, that: (1) the Request is
          not being presented for any improper purpose, such as to harass, cause
          unnecessary delay, or needlessly increase the cost of dispute
          resolution; (2) the claims, defenses and other legal contentions are
          warranted by existing law or by a nonfrivolous argument for extending,
          modifying, or reversing existing law or for establishing new law; and
          (3) the factual and damages contentions have evidentiary support or,
          if specifically so identified, will likely have evidentiary support
          after a reasonable opportunity for further investigation or discovery.
          Unless you and moramba otherwise agree, or the Batch Arbitration
          process discussed in Section 16.9 is triggered, the arbitration will
          be conducted in the county where you reside. Subject to the AAA Rules,
          the arbitrator may direct a limited and reasonable exchange of
          information between the parties, consistent with the expedited nature
          of the arbitration. If the AAA is not available to arbitrate, the
          parties will select an alternative arbitral forum. Your responsibility
          to pay any AAA fees and costs will be solely as set forth in the
          applicable AAA Rules. You and moramba agree that all materials and
          documents exchanged during the arbitration proceedings shall be kept
          confidential and shall not be shared with anyone except the parties’
          attorneys, accountants, or business advisors, and then subject to the
          condition that they agree to keep all materials and documents
          exchanged during the arbitration proceedings confidential.
        </p>
        <p>16.6 Arbitrator </p>
        <p>
          The arbitrator will be either a retired judge or an attorney licensed
          to practice law in the State of New York and will be selected by the
          parties from the AAA’s roster of consumer dispute arbitrators. If the
          parties are unable to agree upon an arbitrator within thirty-five (35)
          days of delivery of the Request, then the AAA will appoint the
          arbitrator in accordance with the AAA Rules, provided that if the
          Batch Arbitration process under Section 16.9 is triggered, the AAA
          will appoint the arbitrator for each batch.
        </p>
        <p>16.7 Authority of Arbitrator </p>
        <p>
          The arbitrator shall have exclusive authority to resolve any Dispute,
          including, without limitation, disputes arising out of or related to
          the interpretation or application of the Arbitration Agreement,
          including the enforceability, revocability, scope, or validity of the
          Arbitration Agreement or any portion of the Arbitration Agreement,
          except for the following: (1) all Disputes arising out of or relating
          to Subsection 16.4, including any claim that all or part of Subsection
          16.4 is unenforceable, illegal, void or voidable, or that Subsection
          16.4 has been breached, shall be decided by a court of competent
          jurisdiction and not by an arbitrator; (2) except as expressly
          contemplated in Section 16.9, all Disputes about the payment of
          arbitration fees shall be decided only by a court of competent
          jurisdiction and not by an arbitrator; (3) all Disputes about whether
          either party has satisfied any condition precedent to arbitration
          shall be decided only by a court of competent jurisdiction and not by
          an arbitrator; and (4) all Disputes about which version of the
          Arbitration Agreement applies shall be decided only by a court of
          competent jurisdiction and not by an arbitrator. The arbitration
          proceeding will not be consolidated with any other matters or joined
          with any other cases or parties, except as expressly provided in
          Section 16.9. The arbitrator shall have the authority to grant motions
          dispositive of all or part of any Dispute. The arbitrator shall issue
          a written award and statement of decision describing the essential
          findings and conclusions on which the award is based, including the
          calculation of any damages awarded. The award of the arbitrator is
          final and binding upon you and us. Judgment on the arbitration award
          may be entered in any court having jurisdiction.
        </p>
        <p>16.8 Attorneys’ Fees and Costs </p>
        <p>
          The parties shall bear their own attorneys’ fees and costs in
          arbitration unless the arbitrator finds that either the substance of
          the Dispute or the relief sought in the Request was frivolous or was
          brought for an improper purpose (as measured by the standards set
          forth in Federal Rule of Civil Procedure 11(b)). If you or moramba
          need to invoke the authority of a court of competent jurisdiction to
          compel arbitration, then the party that obtains an order compelling
          arbitration in such action shall have the right to collect from the
          other party its reasonable costs, necessary disbursements, and
          reasonable attorneys’ fees incurred in securing an order compelling
          arbitration. The prevailing party in any court action relating to
          whether either party has satisfied any condition precedent to
          arbitration, including the Informal Dispute Resolution Conference
          process, is entitled to recover their reasonable costs, necessary
          disbursements, and reasonable attorneys’ fees and costs.
        </p>
        <p>16.9 Batch Arbitration </p>
        <p>
          To increase the efficiency of administration and resolution of
          arbitrations, you and moramba agree that in the event that there are
          one hundred (100) or more individual Requests of a substantially
          similar nature filed against moramba by or with the assistance of the
          same law firm, group of law firms, or organizations, within a thirty
          (30) day period (or as soon as possible thereafter), the AAA shall (1)
          administer the arbitration demands in batches of 100 Requests per
          batch (plus, to the extent there are less than 100 Requests left over
          after the batching described above, a final batch consisting of the
          remaining Requests); (2) appoint one arbitrator for each batch; and
          (3) provide for the resolution of each batch as a single consolidated
          arbitration with one set of filing and administrative fees due per
          side per batch, one procedural calendar, one hearing (if any) in a
          place to be determined by the arbitrator, and one final award (“Batch
          Arbitration”). All parties agree that Requests are of a “substantially
          similar nature” if they arise out of or relate to the same event or
          factual scenario and raise the same or similar legal issues and seek
          the same or similar relief. To the extent the parties disagree on the
          application of the Batch Arbitration process, the disagreeing party
          shall advise the AAA, and the AAA shall appoint a sole standing
          arbitrator to determine the applicability of the Batch Arbitration
          process (“Administrative Arbitrator”). In an effort to expedite
          resolution of any such dispute by the Administrative Arbitrator, the
          parties agree the Administrative Arbitrator may set forth such
          procedures as are necessary to resolve any disputes promptly. The
          Administrative Arbitrator’s fees shall be paid by moramba. You and
          moramba agree to cooperate in good faith with the AAA to implement the
          Batch Arbitration process including the payment of single filing and
          administrative fees for batches of Requests, as well as any steps to
          minimize the time and costs of arbitration, which may include: (1) the
          appointment of a discovery special master to assist the arbitrator in
          the resolution of discovery disputes; and (2) the adoption of an
          expedited calendar of the arbitration proceedings. This Batch
          Arbitration provision shall in no way be interpreted as authorizing a
          class, collective and/or mass arbitration or action of any kind, or
          arbitration involving joint or consolidated claims under any
          circumstances, except as expressly set forth in this provision.
        </p>
        <p>16.10 30-Day Right to Opt Out </p>
        <p>
          You have the right to opt out of the provisions of this Arbitration
          Agreement by sending written notice of your decision to opt out to the
          address set forth below, within thirty (30) days after first becoming
          subject to this Arbitration Agreement. Your notice must include your
          name and address, email address, digital wallet address (if you have
          one), and an unequivocal statement that you want to opt out of this
          Arbitration Agreement. If you opt out of this Arbitration Agreement,
          all other parts of this Agreement will continue to apply to you.
          Opting out of this Arbitration Agreement has no effect on any other
          arbitration agreements that you may currently have, or may enter in
          the future, with us.
        </p>
        <p>16.11 Invalidity, Expiration </p>
        <p>
          Except as provided in Subsection 16.4, if any part or parts of this
          Arbitration Agreement are found under the law to be invalid or
          unenforceable, then such specific part or parts shall be of no force
          and effect and shall be severed and the remainder of the Arbitration
          Agreement shall continue in full force and effect. You further agree
          that any Dispute that you have with moramba as detailed in this
          Arbitration Agreement must be initiated via arbitration within the
          applicable statute of limitation for that claim or controversy, or it
          will be forever time barred. Likewise, you agree that all applicable
          statutes of limitation will apply to such arbitration in the same
          manner as those statutes of limitation would apply in the applicable
          court of competent jurisdiction.
        </p>
        <p>16.12 Modification </p>
        <p>
          Notwithstanding any provision in this Agreement to the contrary, we
          agree that if moramba makes any future material change to this
          Arbitration Agreement, it will notify you. Unless you reject the
          change within thirty (30) days of such change become effective by
          writing to moramba at the address set forth in Section 16.10, your
          continued use of the moramba Property, including the acceptance of
          products and services offered on the moramba Services and moramba
          Network following the posting of changes to this Arbitration Agreement
          constitutes your acceptance of any such changes. Changes to this
          Arbitration Agreement do not provide you with a new opportunity to opt
          out of the Arbitration Agreement if you have previously agreed to a
          version of this Agreement and did not validly opt out of arbitration.
          If you reject any change or update to this Arbitration Agreement, and
          you were bound by an existing agreement to arbitrate Disputes arising
          out of or relating in any way to your access to or use of the moramba
          Property, any communications you receive, or this Agreement, the
          provisions of this Arbitration Agreement as of the date you first
          accepted this Agreement (or accepted any subsequent changes to this
          Agreement) remain in full force and effect. moramba will continue to
          honor any valid opt outs of the Arbitration Agreement that you made to
          a prior version of this Agreement.
        </p>
        <p>16.13 Confidentiality </p>
        <p>
          All aspects of the arbitration proceeding, including but not limited
          to the award of the arbitrator and compliance therewith, shall be
          strictly confidential. The parties agree to maintain confidentiality
          unless otherwise required by law. This paragraph shall not prevent a
          party from submitting to a court of law any information necessary to
          enforce this Agreement, to enforce an arbitration award, or to seek
          injunctive or equitable relief.{" "}
        </p>
        <p>16.14 Survival of Agreement </p>
        <p>
          This Arbitration Agreement will survive the termination of your
          relationship with moramba.
        </p>
        <b>17. Limitation on Time to Commence Disputes</b>
        <p>
          To the maximum extent permitted by applicable law, you acknowledge and
          agree that any Dispute that you may have arising out of or relating to
          (a) this Agreement; (b) the operation or provision of the moramba
          Services, moramba Network, or any other moramba Property; or (c) your
          access to or use of the moramba Services, moramba Network, or any
          other moramba Property or your participation in the moramba Network,
          must be commenced within one year after the date that is the earlier
          of: (i) the date you discovered the Dispute; or (ii) the day on which
          a reasonable person with the abilities in the circumstances of the
          person with the Dispute first ought to have known of the Dispute.
        </p>
        <b>18. International Users</b>
        <p>
          The moramba Services can be accessed from countries around the world
          and may contain references to moramba Services that are not available
          in your country. These references do not imply that moramba intends to
          announce such moramba Services in your country. moramba makes no
          representations that moramba Services are appropriate or available for
          use in other locations. Those who access or use moramba Services from
          other countries do so at their own volition and are responsible for
          compliance with local law.
        </p>
        <b>19. Entire Agreement</b>
        <p>
          This Agreement and to any separate written agreement between you and
          moramba, constitute the entire agreement between you and moramba with
          respect to the subject matter of this Agreement. There are no
          representations, covenants, or other terms other than those set out
          under this Agreement. This Agreement supersedes any previous
          discussions, understandings, or agreements, between the parties
          relating to the subject matter of this Agreement.
        </p>
        <b>20. No Waiver</b>
        <p>
          No waiver of satisfaction of a condition or non-performance of an
          obligation under this Agreement is effective unless it is in writing
          and signed by the party granting the waiver or that party’s authorized
          representative. Unless expressly granted in writing and signed by the
          party granting the waiver or that party’s authorized representative,
          no waiver will extend to any subsequent non-satisfaction of a
          condition or non-performance of an obligation under this Agreement,
          whether or not of the same or similar nature to that which was waived.
          No waiver will affect the exercise of any other rights or remedies
          under this Agreement. Any failure or delay in exercising any right or
          remedy will not constitute, or be deemed to constitute, a waiver of
          that right or remedy. No single or partial exercise of any right or
          remedy will affect further exercise of any right or remedy.
        </p>
        <b>21. Severability</b>
        <p>
          If any provision of this Agreement is found to be unlawful, void, or
          for any reason unenforceable, then that provision will be deemed
          severable from this Agreement and will not affect the validity and
          enforceability of any remaining provisions.
        </p>
        <b>22. Assignment</b>
        <p>
          You may not assign, transfer, sublicense, or convey this Agreement,
          including any rights granted by moramba and obligations assumed by
          you, except with moramba’s prior written consent. We may assign,
          transfer, sublicense, or convey this Agreement, and our rights and
          obligations under them, to any third party without notice to you.
        </p>
        <b>23. Contact Information</b>
        <p>
          If you have any questions, concerns or suggestions regarding the
          moramba Services or this Agreement, please contact us at
          hello@morambainternet.com.
        </p>
      </div>
      <br />
      <br /> */}
      {/* <GoToTop /> */}
    </>
  );
}

export default Tearmsandservicesupdated;
