import React, { useState, useEffect } from "react";
import "./TearmsAndPolicy.css";
import { Link, useNavigate } from "react-router-dom";
import GoToTop from "../../Components/GoToTop/GoToTop";
import { BsArrowLeft } from "react-icons/bs";

function Privacypolicyupdated() {
  // const navigate = useNavigate();
  // useEffect(() => {
  //   if (sessionStorage.getItem("ProgressBox") === "true") {
  //     sessionStorage.setItem("ProgressBox", false);
  //     console.log("object");
  //   }
  // }, []);
  return (
    <>
      {/* <div className="d-flex justify-content-end mt-2 p-4">
        <button className="backbtn-service" onClick={() => navigate("/signup")}>
          <BsArrowLeft />
          Back
        </button>
      </div> */}
      {/* <div className="container termsbox ">
        <div className="termsheadbox p-3">
          <h3 className="text-center mt-4">PRIVACY POLICY</h3>
          <h5 className="">Effective as of September 10, 2024. </h5>
        </div>
        <p className="mt-4">
          <b>European Users:</b> Please see the Notice to European Users section
          below for additional information for individuals located in the
          European Economic Area or United Kingdom (which we refer to as
          <b> “Europe”</b>, and <b>“European”</b> should be understood
          accordingly)
        </p>
        <p>
          This Privacy Policy describes how Andrena, Inc. dba moramba
          (“moramba,” “we”, “us” or “our”) processes personal information that
          we collect through our digital or online properties or services that
          link to this Privacy Policy (including as applicable, our website,
          social media pages, marketing activities, web extensions and other
          activities described in this Privacy Policy (collectively, the
          “Service”)). moramba operates the moramba web extension that is
          designed to permit users to participate in a proof of bandwidth
          ecosystem that facilitates the moramba validator system determining
          proof of coverage related to bandwidth.
        </p>
        <b> Personal information we collect</b>
        <p>
          Information you provide to us. Personal information you may provide to
          us through the Service or otherwise includes:
        </p>
        <ul>
          <li>
            <b> Contact data</b>, such as your first and last name and email
            address.
          </li>
          <li>
            <b> Account data</b>, such as the username and password that you may
            set to establish an online account on the Service, redemption code,
            referral link, and any other information that you add to your
            account profile.{" "}
          </li>
          <li>
            <b> Communications data</b> based on our exchanges with you,
            including when you contact us through the Service, social media, or
            otherwise.{" "}
          </li>
          <li>
            <b> Marketing data</b>, such as your preferences for receiving our
            marketing communications and details about your engagement with
            them.
          </li>
          <li>
            <b> Financial data</b>, such as your virtual currency or wallet
            accounts, and associated details.
          </li>
          <li>
            <b> User-generated content data</b> such as usage data (including
            your participation in the moramba network), comments, questions, and
            other content or information that you generate, transmit, or
            otherwise make available on the Service, as well as associated
            metadata. Metadata includes information on how, when, where and by
            whom a piece of content was collected and how that content has been
            formatted or edited. Metadata also includes information that
            individuals can add or can have added to their content, such as
            keywords.
          </li>
          <li>
            <b> Other data</b> not specifically listed here, which we will use
            as described in this Privacy Policy or as otherwise disclosed at the
            time of collection.
          </li>
        </ul>
        <p>
          <b>Third-party sources.</b> We may combine personal information we
          receive from you with personal information we obtain from other
          sources, such as:
        </p>
        <ul>
          <li>
            <b> Public sources</b>, such as blockchains, government agencies,
            public records, social media platforms, and other publicly available
            sources.
          </li>
          <li>
            <b>Marketing partners</b>, such as joint marketing partners.
          </li>
        </ul>
        <p>
          <b> Service providers.</b> We may combine personal information we
          receive from or about you with personal information we receive from
          service providers that provide services on our behalf or help us
          operate the Service or our business.
        </p>
        <p>
          <b> Automatic data collection.</b> We, our service providers, and our
          business partners may automatically log information about you, your
          computer or mobile device, and your interaction over time with the
          Service, our communications and other online services, such as:
        </p>
        <ul>
          <li>
            <b> Device data</b>, such as your computer or mobile device’s
            operating system type and version, manufacturer and model, browser
            type, screen resolution, RAM and disk size, CPU usage, device type
            (e.g., phone, tablet), IP address, unique identifiers, language
            settings, mobile device carrier, radio/network information (e.g.,
            Wi-Fi, LTE, 3G), and general location information such as city,
            state, country, or geographic area.
          </li>
          <li>
            <b> Online activity data</b>, such as pages or screens you viewed,
            how long you spent on a page or screen, the website you visited
            before browsing to the Service, navigation paths between pages or
            screens, information about your activity on a page or screen, access
            times and duration of access, and whether you have opened our emails
            or clicked links within them.
          </li>
          <li>
            <b> Communication interaction data</b> such as your interactions
            with our email or other communications (e.g., whether you open
            and/or forward emails) – we may do this through use of pixel tags
            (which are also known as clear GIFs), which may be embedded
            invisibly in our emails.{" "}
          </li>
        </ul>
        <p>
          <b> Cookies and similar technologies.</b> Some of the automatic
          collection described above is facilitated by the following
          technologies:
        </p>
        <ul>
          <li>
            <b> Cookies</b>, which are small text files that websites store on
            user devices and that allow web servers to record users’ web
            browsing activities and remember their submissions, preferences, and
            login status as they navigate a site. Cookies used on our sites
            include both “session cookies” that are deleted when a session ends,
            “persistent cookies” that remain longer, “first party” cookies that
            we place and “third party” cookies that our third-party business
            partners and service providers place.
          </li>
          <li>
            <b> Local storage technologies</b>, like HTML5, that provide
            cookie-equivalent functionality but can store larger amounts of data
            on your device outside of your browser in connection with specific
            applications.{" "}
          </li>
          <li>
            <b>Web beacons,</b> also known as pixel tags or clear GIFs, which
            are used to demonstrate that a webpage or email was accessed or
            opened, or that certain content was viewed or clicked.{" "}
          </li>
        </ul>
        <p>
          <b> Data about others.</b> We may offer features that help users
          invite their friends or contacts to use the Service, and we may
          collect contact details about these invitees so we can deliver their
          invitations. Please do not refer someone to us or share their contact
          details with us unless you have their permission to do so.{" "}
        </p>
        <b>How we use your personal information</b>
        <p>
          We may use your personal information for the following purposes or as
          otherwise described at the time of collection:
        </p>
        <p>
          Service delivery and operations. We may use your personal information
          to:
        </p>
        <ul>
          <li>
            provide, operate, and improve the Service (including, as applicable,
            to enable the moramba proof of bandwidth validator ecosystem) and
            our business;
          </li>
          <li>establish and maintain your user account on the Service;</li>
          <li>
            enable security features of the Service, such as by sending you
            security codes via email and remembering devices from which you have
            previously logged in;
          </li>
          <li>
            understand your needs and interests, and personalize your experience
            with the Service and our communications;{" "}
          </li>
          <li>
            communicate with you about the Service, including by sending
            Service-related announcements, updates, security alerts, and support
            and administrative messages; and
          </li>
          <li>
            provide support for the Service, and respond to your requests,
            questions and feedback.
          </li>
        </ul>
        <p>
          <b> Service personalization. </b>We may use your personal information
          to personalize the Service for you, which may include using your
          personal information to:
        </p>
        <ul>
          <li>understand your needs and interests;</li>
          <li>
            personalize your experience with the Service and our Service-related
            communications; and{" "}
          </li>
          <li>
            remember your selections and preferences as you navigate the Service
          </li>
        </ul>
        <p>
          <b> Research and development.</b> We may use your personal information
          for research and development purposes, including to analyze and
          improve the Service and our business and to develop new products and
          services. As part of these activities, we may create aggregated,
          de-identified and/or anonymized data from personal information we
          collect. We make personal information into de-identified or anonymized
          data by removing information that makes the data personally
          identifiable to you. We may use this aggregated, de-identified or
          otherwise anonymized data and share it with third parties for our
          lawful business purposes, including to analyze and improve the Service
          and promote our business.
        </p>
        <p>
          Marketing. We and our service providers may collect and use certain of
          your personal information to send you direct marketing communications
          and may personalize these messages based on your needs and interests.
          You may opt-out of our marketing communications as described in the
          Opt-out of marketing section below.{" "}
        </p>
        <p>
          <b> Service improvement and analytics.</b> We may use your personal
          information to analyze your usage of the Service, improve the Service,
          improve the rest of our business, help us understand user activity on
          the Service, including which pages are most and least visited and how
          visitors move around the Service, as well as user interactions with
          our emails, and to develop new products and services.
        </p>
        <p>
          <b> Rewards program, promotions, and contests.</b> We may use your
          personal information to:{" "}
        </p>
        <ul>
          <li>
            administer our rewards program, promotions, or other contests; and
          </li>
          <li>
            communicate with you about our rewards program, promotions, or other
            contests in which you participate.
          </li>
        </ul>
        <p>
          <b> Compliance and protection.</b> We may use your personal
          information to:
        </p>
        <ul>
          <li>
            comply with applicable laws, lawful requests, and legal process,
            such as to respond to subpoenas, investigations or requests from
            government authorities
          </li>
          <li>
            protect our, your or others’ rights, privacy, safety or property
            (including by making and defending legal claims);{" "}
          </li>
          <li>
            audit our internal processes for compliance with legal and
            contractual requirements or our internal policies;{" "}
          </li>
          <li>
            enforce the terms and conditions that govern the Service; and{" "}
          </li>
          <li>
            prevent, identify, investigate and deter fraudulent, harmful,
            unauthorized, unethical or illegal activity, including cyberattacks
            and identity theft.{" "}
          </li>
        </ul>
        <p>
          {" "}
          <b> Cookies and similar technologies.</b> In addition to the other
          uses included in this section, we may use the Cookies and similar
          technologies described above for the following purposes:
        </p>
        <ul>
          <li>
            Technical operation. To allow the technical operation of the
            Service, such as by remembering your selections and preferences as
            you navigate the site, and whether you are logged in when you visit
            password protected areas of the Service.
          </li>
          <li>
            Functionality. To enhance the performance and functionality of our
            services.
          </li>
        </ul>
        <p>
          With your consent. In some cases, we may specifically ask for your
          consent to collect, use or share your personal information, such as
          when required by law.{" "}
        </p>
        <b>How we share your personal information</b>
        <p>
          We may share your personal information with the following parties and
          as otherwise described in this Privacy Policy, in other applicable
          notices, or at the time of collection.
        </p>
        <p>
          <b>Affiliates.</b> To the extent relevant, our corporate parent,
          subsidiaries, and affiliates.
        </p>
        <p>
          <b>Business and marketing partners</b>. Third parties with whom we
          jointly offer products or services, or whose products or services may
          be of interest to you.
        </p>
        <p>
          <b>Blockchain providers.</b> Any information collected necessary to
          process our reward program (such as your wallet address) is collected
          and processed directly by your chosen cryptocurrency platform. Please
          review the privacy policy for the relevant cryptocurrency platform to
          learn how they may use your information.{" "}
        </p>
        <p>
          <b> Professional advisors.</b> Professional advisors, such as lawyers,
          auditors, bankers and insurers, where necessary in the course of the
          professional services that they render to us.
        </p>
        <p>
          <b> Authorities and others.</b> Law enforcement, government
          authorities, and private parties, as we believe in good faith to be
          necessary or appropriate for the Compliance and protection purposes
          described above.{" "}
        </p>
        <p>
          <b> Business transferees.</b> We may disclose personal information in
          the context of actual or prospective business transactions (e.g.,
          investments in moramba, financing of moramba, public stock offerings,
          or the sale, transfer or merger of all or part of our business, assets
          or shares), for example, we may need to share certain personal
          information with prospective counterparties and their advisers. We may
          also disclose your personal information to an acquirer, successor, or
          assignee of moramba as part of any merger, acquisition, sale of
          assets, or similar transaction, and/or in the event of an insolvency,
          bankruptcy, or receivership in which personal information is
          transferred to one or more third parties as one of our business
          assets.
        </p>
        <p>
          <b>Other users and the public.</b> We may share moramba network users’
          personal information, in connection with their participation or use of
          the moramba network to facilitate bandwidth validation, with other
          users and others (including the public). Due to the nature of
          blockchain technologies, any data posted to a blockchain will be
          visible to other users of the Service and the public. This information
          can be seen, collected and used by others, including being cached,
          copied, screen captured or stored elsewhere by others (e.g., search
          engines), and we are not responsible for any such use of this
          information.
        </p>
        <b>Your choices </b>
        <p>
          In this section, we describe the rights and choices available to all
          users. Users who are located in Europe can find additional information
          about their rights below in the <b>Notice to European Users,</b>{" "}
          below.
        </p>
        <p>
          <b>Access, update, and delete certain of your information. </b>If you
          have registered for an account with us through the Service, you may be
          able to access, update and delete certain personal information through
          account features.
        </p>
        <p>
          <b> Opt-out of communications.</b> You may opt-out of
          marketing-related emails by following the opt-out or unsubscribe
          instructions at the bottom of the email, or by contacting us. Please
          note that if you choose to opt-out of marketing-related emails, you
          may continue to receive service-related and other non-marketing
          emails.{" "}
        </p>
        <p>
          <b> Cookies</b>. Most browsers let you remove or reject cookies. To do
          this, follow the instructions in your browser settings. Many browsers
          accept cookies by default until you change your settings. Please note
          that if you set your browser to disable cookies, the Service may not
          work properly. For more information about cookies, including how to
          see what cookies have been set on your browser and how to manage and
          delete them, visit www.allaboutcookies.org. You can also configure
          your device to prevent images from loading to prevent web beacons from
          functioning.
        </p>
        <p>
          <b>Blocking images/clear gifs:</b> Most browsers and devices allow you
          to configure your device to prevent images from loading. To do this,
          follow the instructions in your particular browser or device settings.{" "}
        </p>
        <p>
          <b>Do Not Track.</b> Some Internet browsers may be configured to send
          “Do Not Track” signals to the online services that you visit. We
          currently do not respond to “Do Not Track” or similar signals except
          where we expressly indicate otherwise. To find out more about “Do Not
          Track,” please visit http://www.allaboutdnt.com.
        </p>
        <p>
          <p> Declining to provide information.</p> We need to collect personal
          information to provide certain services. If you do not provide the
          information we identify as required or mandatory, we may not be able
          to provide those services.
        </p>
        <b>Other sites and services</b>
        <p>
          The Service may contain links to websites, mobile applications, and
          other online services operated by third parties. In addition, our
          content may be integrated into web pages or other online services that
          are not associated with us. These links and integrations are not an
          endorsement of, or representation that we are affiliated with, any
          third party. We do not control websites, mobile applications or online
          services operated by third parties, and we are not responsible for
          their actions. We encourage you to read the privacy policies of the
          other websites, mobile applications and online services you use.
        </p>
        <b>Security </b>
        <p>
          We employ technical, organizational and physical safeguards designed
          to protect the personal information we collect. However, security risk
          is inherent in all internet and information technologies, and we
          cannot guarantee the security of your personal information.{" "}
        </p>
        <b>International data transfer</b>
        <p>
          We are headquartered in the United States and may use service
          providers that operate in other countries. Your personal information
          may be transferred to the United States or other locations where
          privacy laws may not be as protective as those in your state,
          province, or country.{" "}
        </p>
        <p>
          Users in Europe should read the important information provided below
          about transfer of personal information outside of Europe contained in
          the Notice to European Users below.
        </p>
        <b>Children </b>
        <p>
          The Service is not intended for use by anyone under 18 years of age.
          If you are a parent or guardian of a child from whom you believe we
          have collected personal information in a manner prohibited by law,
          please contact us. If we learn that we have collected personal
          information through the Service from a child without the consent of
          the child’s parent or guardian as required by law, we will comply with
          applicable legal requirements to delete the information.
        </p>
        <b>Changes to this Privacy Policy </b>
        <p>
          We reserve the right to modify this Privacy Policy at any time. If we
          make material changes to this Privacy Policy, we will notify you by
          updating the date of this Privacy Policy and posting it on the Service
          or other appropriate means. Any modifications to this Privacy Policy
          will be effective upon our posting the modified version (or as
          otherwise indicated at the time of posting). In all cases, your use of
          the Service after the effective date of any modified Privacy Policy
          indicates your acknowledging that the modified Privacy Policy applies
          to your interactions with the Service and our business.
        </p>
        <b>How to contact us</b>
        <p>
          <b>
            Email:
            <a href="hello@morambainternet.com">
              {" "}
              hello@morambainternet.com
            </a>{" "}
          </b>
        </p>
        <b>Notice to European users</b>
        <p>General</p>
        <p>
          <b> Where this Notice to European users applies.</b> The information
          provided in this ‘Notice to European users’ section applies only to
          individuals located in the European Economic Area (EEA) or United
          Kingdom (UK) (i.e., <b>“Europe” </b>as defined at the top of this
          Privacy Policy).
        </p>
        <p>
          <b> Personal information</b>. References to “personal information” in
          this Privacy Policy should be understood to include a reference to
          “personal data” as defined in the <b>“GDPR” </b>(i.e., the General
          Data Protection Regulation 2016/679 <b>(“EU GDPR”))</b> and the EU
          GDPR as it forms part of the laws of the United Kingdom{" "}
          <b>(“UK GDPR”)</b>
          Under the GDPR, “personal data” means information about individuals
          from which they are either directly identified or can be identified.
          The personal information that we collect from and about you is
          described in greater detail in the section “Personal Information we
          collect”.
        </p>
        <p>
          <b> Controller</b>. moramba is the “controller” in respect of the
          processing of your personal information covered by this Privacy Policy
          for purposes of the GDPR. See the ‘How to contact us’ section above
          for our contact details.
        </p>
        <p>
          <b>Our GDPR Representatives.</b> We have appointed the following
          representatives in Europe as required by the GDPR – you can contact
          them directly should you wish:
        </p>
        <p>
          Our Representative in the EU. Our EU representative appointed under
          the EU GDPR is DataRep. You can contact them:
        </p>
        <ul>
          <li>By email to: dararequest@datarep.com</li>
          <li>By postal mail to:</li>
        </ul>
        <p>
          DataRep <br></br>72 rue de Lessard<br></br>Rouen, 76100<br></br>France
        </p>
        <p>
          Our Representative in the UK. Our UK representative appointed under
          the UK GDPR is DataRep. You can contact them:
        </p>
        <ul>
          <li>By email to: datarequest@datarep.com</li>
          <li>By postal mail to:</li>
        </ul>
        <p>
          DataRep <br></br>107-111 Fleet Street<br></br>London, EC4A 2AB
          <br></br>United Kingdon France
        </p>
        <p>Our legal bases for processing </p>
        <p>
          In respect of each of the purposes for which we use your personal
          information, the GDPR requires us to ensure that we have a “legal
          basis” for that use.{" "}
        </p>
        <p>
          Our legal bases for processing your personal information described in
          this Privacy Policy are listed below.
        </p>
        <ul>
          <li>
            Where we need to process your personal information in order to
            deliver the Service to you, or where you have asked us to take
            specific action which requires us to process your personal
            information (“<b>Contractual Necessity</b>”).
          </li>
          <li>
            Where it is necessary for our legitimate interests and your
            interests and fundamental rights do not override those interests (“
            <b>Legitimate Interests</b>”). More detail about the specific
            legitimate interests pursued in respect of each purpose we use your
            personal information for is set out in the table below.
          </li>
          <li>
            Where we need to comply with a legal or regulatory obligation (“
            <b>Compliance with Law</b>”).
          </li>
          <li>
            Where we have your specific consent to carry out the processing for
            the Purpose in question (“<b>Consent</b>”).{" "}
          </li>
        </ul>
        <p>
          We have set out below the legal bases we rely on in respect of the
          relevant purposes for which we use your personal information – for
          more information on these purposes and the data types involved, see
          the ‘How we use your personal information’ section above and the
          description of associated data sharing relevant to such purposes set
          out in the ‘How we share your personal information’ section also
          above.
        </p>
        <p>
          1. <b>Purpose</b>: Service delivery and operations
        </p>
        <b>Categories of personal information involved:</b>
        <ul>
          <li>Contact data</li>
          <li>Account data</li>
          <li>Communications data</li>
          <li>User-generated content data</li>
          <li>Device data</li>
        </ul>
        <b>Legal basis</b>
        <ul>
          Delivery and operations
          <li>
            <b>Contractual Necessity</b>
          </li>
          <li>
            <b> Legitimate Interests.</b> We have a legitimate interest in
            ensuring the ongoing security and proper operation of our Service,
            our business and associated IT services, systems and networks.
          </li>
          Personalization
          <li>
            <b>Legitimate Interests.</b> We have a legitimate interest in
            providing you with a good service via the Service, which is
            personalized to you and that remembers your selections and
            preferences.
          </li>
          <li>
            <b>Consent,</b> in respect of any optional processing relevant to
            personalization (including processing directly associated with any
            optional cookies used for this purpose).
          </li>
        </ul>
        <p>
          2. <b>Purpose</b>: Research and development
        </p>
        <b>Categories of personal information involved: </b>
        <ul>
          <li>Any and all data types relevant in the circumstances</li>
        </ul>
        <b>Legal basis:</b>
        <ul>
          <li>
            Legitimate Interests. We have legitimate interest in taking steps to
            preserve our users’ privacy as we research how they use our Service.
          </li>
        </ul>
        <p>
          3. <b>Purpose</b>: Direct marketing
        </p>
        <b>Categories of personal information involved: </b>
        <ul>
          <li>Contact data</li>
          <li>Marketing data </li>
        </ul>
        <b>Legal basis:</b>
        <ul>
          <li>
            <b>Legitimate Interests.</b> We have a legitimate interest in
            promoting our operations and goals as an organization and sending
            marketing communications for that purpose
          </li>
          <li>
            <b>Consent</b>, in circumstances or in jurisdictions where consent
            is required under applicable data protection laws to the sending of
            any given marketing communications. ‍{" "}
          </li>
        </ul>
        <p>
          4. <b>Purpose</b>: Service improvement and analytics
        </p>
        <b>Categories of personal information involved: </b>
        <ul>
          <li>Contact data</li>
          <li>Communications data</li>
          <li>Account data</li>
          <li>User-generated content data</li>
          <li>Device data</li>
          <li>Online activity data</li>
          <li>Communication interaction data</li>
        </ul>
        <b>Legal basis:</b>
        <ul>
          <li>
            <b>Legitimate Interests.</b> We have a legitimate interest in
            providing you with a good service and analysing how you use it so
            that we can improve it over time, as well as developing and growing
            our business.
          </li>
          <li>
            <b>Consent,</b> in respect of any optional cookies used for this
            purpose.
          </li>
        </ul>
        <p>
          5. <b>Purpose</b>: Compliance and protection
        </p>
        <b>Categories of personal information involved: </b>
        <ul>
          <li>Any and all data types relevant in the circumstances</li>
        </ul>
        <b>Legal basis:</b>
        <ul>
          <li>
            <b>Compliance with Law</b>
          </li>
          <li>
            <b> Legitimate Interests.</b> Where Compliance with Law is not
            applicable, we have a legitimate interest in participating in,
            supporting, and following legal process and requests, including
            through co-operation with authorities. We may also have a legitimate
            interest in ensuring the protection, maintenance, and enforcement of
            our rights, property, and/or safety.
          </li>
        </ul>
        <p>
          6. <b>Purpose</b>: Further uses
        </p>
        <b>Categories of personal information involved: </b>
        <ul>
          <li>Any and all data types relevant in the circumstances</li>
        </ul>
        <b>Legal basis:</b>
        <li>
          The original legal basis relied upon, if the relevant further use is
          compatible with the initial purpose for which the personal information
          was collected.{" "}
        </li>
        <li>
          Consent, if the relevant further use is not compatible with the
          initial purpose for which the personal information was collected
        </li>
        <b>Retention</b>
        <p>
          We retain personal information for as long as necessary to fulfill the
          purposes for which we collected it, including for the purposes of
          satisfying any legal, accounting, or reporting requirements, to
          establish or defend legal claims, or for the ‘Compliance and
          protection’ purposes outlined above.{" "}
        </p>
        <p>
          To determine the appropriate retention period for personal
          information, we consider the amount, nature, and sensitivity of the
          personal information, the potential risk of harm from unauthorized use
          or disclosure of your personal information, the purposes for which we
          process your personal information and whether we can achieve those
          purposes through other means, and the applicable legal requirements.{" "}
        </p>
        <p>
          When we no longer require the personal information that we have
          collected about you, we will either delete or anonymize it or, if this
          is not possible (for example, because your personal information has
          been stored in backup archives), then we will securely store your
          personal information and isolate it from any further processing until
          deletion is possible. If we anonymize your personal information (so
          that it can no longer be associated with you), we may use this
          information indefinitely without further notice to you. ‍
        </p>
        <b>Other information</b>
        <p>
          <b> No obligation to provide personal information. </b>You do not have
          to provide personal information to us. However, where we need to
          process your personal information either to comply with applicable law
          or to deliver our Service to you, and you fail to provide that
          personal information when requested, we may not be able to provide
          some or all of our Service to you. We will notify you if this is the
          case at the time.
        </p>
        <p>
          <b> No sensitive information.</b> We ask that you not provide us with
          any sensitive personal information (e.g., social security numbers,
          information related to racial or ethnic origin, political opinions,
          religion or other beliefs, health, criminal background or trade union
          membership, or biometric or genetics characteristic other than as
          requested by us as part of the Service) on or through the Service, or
          otherwise to us. If you provide us with any such sensitive personal
          information to us when you use the Service, you must consent to our
          processing and use of such sensitive personal information in
          accordance with this Privacy Policy. If you do not consent to our
          processing and use of such sensitive personal information, you must
          not submit such sensitive personal information through our Service.
        </p>
        <p>
          <b> No automated decision-making and profiling.</b> As part of the
          Service, we do not engage in automated decision-making and/or
          profiling, which produces legal or similarly significant effects. We
          provide our analysis to our business customers and our business
          customers make decisions that may affect you.{" "}
        </p>
        <p>Your additional rights</p>
        <b>
          General. European data protection laws may give you certain rights
          regarding your personal information in certain circumstances. If you
          are located in Europe, you may ask us to take the following actions in
          relation to your personal information that we hold:
        </b>
        <ul>
          <li>
            Access. Provide you with information about our processing of your
            personal information and give you access to your personal
            information
          </li>
          <li>
            Correct. Update or correct inaccuracies in your personal
            information.
          </li>
          <li>
            <b> Delete</b>. Delete your personal information where there is no
            good reason for us continuing to process it - you also have the
            right to ask us to delete or remove your personal information where
            you have exercised your right to object to processing (see below).{" "}
          </li>
          <li>
            <b> Transfer</b>. Transfer to you or a third party of your choice a
            machine-readable copy of your personal information which you have
            provided to us.
          </li>
          <li>
            <b> Restrict</b>. Restrict the processing of your personal
            information, for example if you want us to establish its accuracy or
            the reason for processing it.
          </li>
          <li>
            <b> Object/b</b>. Object to our processing of your personal
            information where we are relying on Legitimate Interests – you also
            have the right to object where we are processing your personal
            information for direct marketing purposes.
          </li>
          <li>
            <b> Withdraw Consent.</b> When we use your personal information
            based on your consent, you have the right to withdraw that consent
            at any time. ‍
          </li>
        </ul>
        <p>
          <b> Exercising These Rights.</b> You may submit these requests by
          contacting us. We may request specific information from you to help us
          confirm your identity and process your request. Whether or not we are
          required to fulfill any request you make will depend on a number of
          factors (e.g., why and how we are processing your personal
          information), if we reject any request you may make (whether in whole
          or in part) we will let you know our grounds for doing so at the time,
          subject to any legal restrictions. Typically, you will not have to pay
          a fee to exercise your rights; however, we may charge a reasonable fee
          if your request is clearly unfounded, repetitive or excessive. We try
          to respond to all legitimate requests within a month of receipt. It
          may take us longer than a month if your request is particularly
          complex or if you have made a number of requests; in this case, we
          will notify you and keep you updated.
        </p>
        <p>
          <b>
            {" "}
            Your Right to Lodge a Complaint with your Supervisory Authority.
          </b>
          Although we urge you to contact us first to find a solution for any
          concern you may have, in addition to your rights outlined above, if
          you are not satisfied with our response to a request you make, or how
          we process your personal information, you can make a complaint to the
          data protection regulator in your habitual place of residence.{" "}
        </p>
        <ul>
          <li>
            For users in the European Economic Area – the contact information
            for the data protection regulator in your place of residence can be
            found here: https://edpb.europa.eu/about-edpb/board/members_en
          </li>
          <li>
            For users in the UK – the contact information for the UK data
            protection regulator can be found here:
            https://ico.org.uk/make-a-complaint/{" "}
          </li>
        </ul>
        <p>Data Processing outside Europe </p>
        <p>
          We are a U.S.-based company and many of our service providers,
          advisers, partners or other recipients of data are also based in the
          U.S. This means that, if you use the Service, your personal
          information will necessarily be accessed and processed in the U.S. It
          may also be provided to recipients in other countries outside Europe.{" "}
        </p>
        <p>
          Where we share your personal information with third parties who are
          based outside Europe, we try to ensure a similar degree of protection
          is afforded to it by making sure one of the following mechanisms is
          implemented:{" "}
        </p>
        <ul>
          <li>
            <b> Transfers to territories with an adequacy decision.</b> We may
            transfer your personal information to countries or territories whose
            laws have been deemed to provide an adequate level of protection for
            personal information by the European Commission or UK Government (as
            and where applicable) (from time to time) or under specific adequacy
            frameworks approved by the European Commission or UK Government (as
            and where applicable) (from time to time), such as the EU-U.S. Data
            Privacy Framework or the UK Extension thereto.
          </li>
          <li>
            <b>
              {" "}
              Transfers to territories without an adequacy decision. some text{" "}
            </b>
            <br></br>We may transfer your personal information to countries or
            territories whose laws have not been deemed to provide such an
            adequate level of protection (e.g., the U.S.).<br></br>However, in
            these cases:some text
            <ul>
              <li>
                we may use specific appropriate safeguards, which are designed
                to give personal information effectively the same protection it
                has in Europe – for example, standard-form contracts approved by
                relevant authorities for this purpose; or{" "}
              </li>
              <li>
                in limited circumstances, we may rely on an exception, or
                ‘derogation’, which permits us to transfer your personal
                information to such country despite the absence of an ‘adequacy
                decision’ or ‘appropriate safeguards’ – for example, reliance on
                your explicit consent to that transfer.
              </li>
            </ul>
          </li>
        </ul>
        <p>
          You may contact us if you want further information on the specific
          mechanism used by us when transferring your personal information out
          of Europe.
        </p>
      </div> */}
      {/* <br /> */}
      {/* <br /> */}
      {/* <GoToTop /> */}
    </>
  );
}

export default Privacypolicyupdated;
