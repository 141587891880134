import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import Cookie from "js-cookie";
import { GLOBAL_URL_DOMAIN_LIVE } from "../utils/Files/url";
import { errorToast } from "../utils/Files/toasterMsgs";

const UserListSlice = createSlice({
  name: "UserList",
  initialState: {
    userdata: [],
    userCount: 0,
  },
  //  {
  //   userdata: [],
  //   navigateData: false,
  // },
  reducers: {
    setUserData(state, action) {
      return { ...state, userdata: action.payload };
      // return (state = action.payload);
    },
    setUserCountData(state, action) {
      return { ...state, userCount: action.payload };
      // return (state = action.payload);
    },

    // setNavigateData(state, action) {
    //   return { ...state, navigateData: action.payload };
    //   // return (state = action.payload);
    // },
  },
});

const { setUserData, setUserCountData, setNavigateData } =
  UserListSlice.actions;
export default UserListSlice.reducer;

export function getAllUserData() {
  return async function getAllUserDataThunk(dispatch, getstate) {
    try {
      var CompanyId = sessionStorage.getItem("companyID");
      var useremail = sessionStorage.getItem("email");
      var API_URL =
        GLOBAL_URL_DOMAIN_LIVE +
        `/company/user-count?companyId=${sessionStorage.getItem("companyID")}`;

      let headerConfig = {
        headers: {
          authorization: "Bearer " + sessionStorage.getItem("userToken"),
        },
      };

      axios
        .get(API_URL, headerConfig)
        .then(function (response) {
          ///****** get all company emploayee data */
          var data = response?.data?.companyUsers;
          console.log("User Count", data);
          // var FinalData = data.filter((val) => {
          //   if (val.userID === undefined || val.userID === null) {
          //     console.log(val.userID);
          //   } else {
          //     return val.userID.email !== sessionStorage.getItem("email");
          //   }
          // });
          dispatch(setUserData(data));
          ///****** get company emploayee count */
          var usercount = response?.data?.count;
          console.log(usercount);
          dispatch(setUserCountData(usercount));
        })
        .catch(function (error) {
          console.log(error);
          if (error?.response?.status === 427) {
            sessionStorage.clear();
            localStorage.clear();
            Cookie.remove("fName");
            Cookie.remove("lName");
            Cookie.remove("userID");
            Cookie.remove("userToken");
            Cookie.remove("token_start_time");
            // setTimeout(() => {
            // dispatch(setNavigateData(true));
            window.location.replace("/");
            // });
          }
          errorToast(error?.response?.data?.message);
        })
        .then(function () {});
    } catch (err) {
      console.log(err);
    }
  };
}
export function resetUserListData() {
  return async function resetUserListDataThunk(dispatch, getState) {
    try {
      dispatch(setUserData([]));
      dispatch(setUserCountData(0));
      // dispatch(setNavigateData(false));
    } catch (err) {
      console.log(err);
    }
  };
}
