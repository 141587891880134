import React, { useEffect } from "react";
import { IoIosArrowBack } from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";
import EmailPhoneImg from "../../utils/images/EmailPhone.png";
import atomImg from "../../utils/images/atom_1.png";
import { ToastContainer } from "react-toastify";
import { useState } from "react";
import { GLOBAL_URL_DOMAIN_LIVE } from "../../utils/Files/url";
import axios from "axios";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { FcFeedback } from "react-icons/fc";

function Support() {
  const navigate = useNavigate();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [disableBtn, setDisableBtn] = useState(false);
  const [emailOrPhone, setEmailOrPhone] = useState("");
  const [errEmailorPhone, setErrEmailorPhone] = useState("");
  const [Open, setOpen] = useState(false);
  useEffect(() => {
    if (sessionStorage.getItem("ProgressBox") === "true") {
      sessionStorage.setItem("ProgressBox", false);
    }
  }, []);
  const handleClose = () => {
    setOpen(false);
  };
  const handleResendLink = async (userid, companyid) => {
    if (emailOrPhone === "") {
      setErrEmailorPhone("*Please Enter Email or Phone Number!");
    } else {
      try {
        const API_URL = GLOBAL_URL_DOMAIN_LIVE + "/user/having-trouble-login";
        const data = {
          EmailOrPhone: emailOrPhone.toLowerCase(),
        };
        const res = await axios.post(API_URL, data);
        console.log("invitation_email_verified res***", res);
        if (res?.data?.success === true) {
          console.log(res?.data?.message);
          setOpen(true);
        }
      } catch (err) {
        console.log("invitation_email_verified err***", err);
        if (err?.response?.data?.success === false) {
          console.log(err?.response?.data?.message);
          setErrEmailorPhone(err?.response?.data?.message); // T
        }
      }
    }
  };
  return (
    <div className="login-main">
      <Dialog
        fullScreen={fullScreen}
        open={Open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title" className="text-center">
          <FcFeedback className="Email-icon" />
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <h5 className="text-center">
              Check your Register Email or Phone For Verification Link!
            </h5>
            <br />
          </DialogContentText>
          <Divider />
        </DialogContent>
        <DialogActions>
          <button
            className="confirm-btn mx-4"
            onClick={() => navigate("/signin")}
          >
            Ok
          </button>
        </DialogActions>
      </Dialog>
      <div className="form login-form fp-form">
        <div className="login-title-main">
          <div className="login-title-logo">
            <img src={atomImg} alt="" className="login-atom-img" />
          </div>
          <div className="login-title-text">How Can We Help You?</div>
        </div>
        <div className="login-subtitle">
          <h6 className="login-subtitle-1">
            {/* Enter your Email or Phone Number and We'll Send you an Email to Reset
          your Password. */}
            Resend Verification Link
          </h6>
        </div>
        <div className="login-content">
          <div className="flex-column">
            <label className="login-labels">Email or Phone Number</label>
          </div>
          <div className="inputForm">
            <img src={EmailPhoneImg} alt="" className="login-emailphone" />
            <input
              type="text"
              className="input login-input"
              placeholder="Enter your Email or Phone Number"
              onChange={(e) => [
                setEmailOrPhone(e.target.value),
                setErrEmailorPhone(""),
              ]}
              value={emailOrPhone.toLowerCase()}
            />
          </div>
          <span className="text-danger">{errEmailorPhone}</span>
          <button
            className="button-submit mt-2"
            onClick={handleResendLink}
            disabled={disableBtn}
          >
            Send Link
          </button>
          <p className="p login-last-text fp-backto-signin">
            <center>
              <div className="forgot-fp-back">
                <Link to="/signin" className="login-fp-inner fp-backto-signin">
                  <span className="span fp-last">
                    <IoIosArrowBack className="fp-back-icon" /> Back to Sign In
                  </span>
                </Link>
              </div>
            </center>
          </p>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default Support;
