import React, { useState, useEffect } from "react";
import { Tab, Tabs } from "react-bootstrap";
import Header from "../../Components/Header/Header";
import TestSingle from "./TestSingle";
import TestMultiple from "./TestMultiple";
import { GlobalConstants } from "../../utils/globalconstant";
import { ToastContainer } from "react-toastify";
import GeneData from "./GeneData";
const xpath = require("xpath");
const dom = require("xmldom").DOMParser;
const Test = () => {
  const [text_uploadfile, setText_uploadfile] = useState("Upload File");
  const [text_uploadsinglefile, setText_uploadsinglefile] = useState(
    "UPLOAD A SINGLE FILE"
  );
  const [text_uploadmultiefile, setText_uploadmultifile] = useState(
    "UPLOAD MULTIPLE FILES"
  );
  const [text_uploadGeneFiles, setText_uploadGeneFiles] = useState(
    "UPLOAD GENE DATA FILES"
  );
  useEffect(() => {
    try {
      SetLanguageText();
    } catch (err) {
      console.log("" + err);
    }
  }, []);
  const SetLanguageText = () => {
    try {
      var xml = localStorage.getItem(GlobalConstants.session_lang_xml);
      var doc = new dom().parseFromString(xml);
      setText_uploadfile(
        xpath.select1("/resources/string[@name='text_uploadfile']", doc)
          .firstChild.data
      );
      setText_uploadsinglefile(
        xpath.select1("/resources/string[@name='text_uploadsinglefile']", doc)
          .firstChild.data
      );
      setText_uploadGeneFiles(
        xpath.select1("/resources/string[@name='text_uploadGeneFiles']", doc)
          .firstChild.data
      );
    } catch (ert) {}
  };
  return (
    <div>
      <Header />
      <div className="uf-main">
        <div className="uf-main-container">
          <div className="login-title-text uf-title">{text_uploadfile}</div>
          <hr className="uf-hr" />

          <Tabs
            defaultActiveKey="home"
            id="fill-tab-example"
            fill
            className="nav-test"
          >
            <Tab
              eventKey="home"
              title={text_uploadsinglefile}
              className="test-bs-tab"
            >
              <TestSingle />
            </Tab>
            <Tab
              eventKey="profile"
              title={text_uploadmultiefile}
              className="test-bs-tab"
            >
              <TestMultiple />
            </Tab>
            <Tab
              eventKey=""
              title={text_uploadGeneFiles}
              className="test-bs-tab"
            >
              <GeneData />
            </Tab>
          </Tabs>
        </div>
      </div>
      <ToastContainer className="uf-toast" />
    </div>
  );
};

export default Test;
