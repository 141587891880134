import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import {
  GLOBAL_URL_DOMAIN_File_Upload_Live,
  GLOBAL_URL_DOMAIN_LIVE,
} from "../utils/Files/url";
import { partial } from "filesize";
import { v4 as uuidv4 } from "uuid";
import Cookie from "js-cookie";
import { UploadFile, UploadMultipleFile } from "./UploadSlice";
import { getIPFSFileData } from "./IPFSRootFolderSlice";
import { getIPFSRootData } from "./IPFSRootFolderSlice";
import { getBothFileData } from "./BothFilesSlice";
import { foderwiseAwsFiless } from "./AwsRootFolderSlice";
import { getAwsRootData } from "./AwsRootFolderSlice";
import {
  setAbortErrFunc,
  setAbortedFunc,
  setCancelDataFunc,
  setNotStartedIndFunc,
} from "./CancelSlice";

import { ColorRing } from "react-loader-spinner";

const MetaDataSLice = createSlice({
  name: "MetaDataSLice",
  initialState: {
    data: [],
    ProgressBox: false,
    multiMetadata: [],
    uploadLocation: "",
    abortController: [],
    other_data_2: [],
    other_data_2_ipfs: [],
    other_data_invite: [],
    other_data_invite_ipfs: [],
    folderArr_ipfs: [],
    folderArr: [],
    MultiFileandFolderIDAWS: [],
    MultiFileandFolderID: [],
    allFileDataMulti: [],
    allFileData: [],
    singleFiledata: [],
    FileUploadCancel: "",
    uploadFrom: "",
    multi_pro: [],
    multipledata: [],
    newFolderData: [],
    file_unique_id: "",
    callAwsRootApi: false,
    callIpfsRootApi: false,
    callBothRootApi: false,
    disableupload: false,
    metaDataIndex: 0,
  },
  reducers: {
    setMetaData(state, action) {
      return { ...state, data: action.payload };
    },
    setShowProgressBox(state, action) {
      return { ...state, ProgressBox: action.payload };
    },
    setMultiMetadata: (state, action) => {
      return { ...state, multiMetadata: action.payload };
    },
    setUploadLocation: (state, action) => {
      return { ...state, uploadLocation: action.payload };
    },
    setAbortController: (state, action) => {
      return { ...state, abortController: action.payload };
    },
    setOtherData2: (state, action) => {
      return { ...state, other_data_2: action.payload };
    },
    setOtherData2ipfs: (state, action) => {
      return { ...state, other_data_2_ipfs: action.payload };
    },
    setFolderErrIpfs: (state, action) => {
      return { ...state, folderArr_ipfs: action.payload };
    },
    setFolderErr: (state, action) => {
      return { ...state, folderArr: action.payload };
    },
    setMultiFileandFolderIDAWS: (state, action) => {
      return { ...state, MultiFileandFolderIDAWS: action.payload };
    },
    setMultiFileandFolder: (state, action) => {
      return { ...state, MultiFileandFolderID: action.payload };
    },
    setAllFileDataMulti: (state, action) => {
      return { ...state, allFileDataMulti: action.payload };
    },
    setSingleFileData: (state, action) => {
      return { ...state, singleFiledata: action.payload };
    },
    setFileUploadCancel: (state, action) => {
      return { ...state, FileUploadCancel: action.payload };
    },
    setAllFileData: (state, action) => {
      return { ...state, allFileData: action.payload };
    },
    setUploadFrom: (state, action) => {
      return { ...state, uploadFrom: action.payload };
    },
    setmulti_pro: (state, action) => {
      return { ...state, multi_pro: action.payload };
    },

    setMultipledata: (state, action) => {
      return { ...state, multipledata: action.payload };
    },
    setNewFolderData: (state, action) => {
      return { ...state, newFolderData: action.payload };
    },

    setFileUniqueId: (state, action) => {
      return { ...state, file_unique_id: action.payload };
    },
    setCallAwsRootApi(state, action) {
      return { ...state, callAwsRootApi: action.payload };
    },
    setCallIpfsRootApi(state, action) {
      return { ...state, callIpfsRootApi: action.payload };
    },
    setCallBothRootApi(state, action) {
      return { ...state, callBothRootApi: action.payload };
    },
    setDisableupload(state, action) {
      return { ...state, disableupload: action.payload };
    },
    setOtherDataInvite(state, action) {
      return { ...state, other_data_invite: action.payload };
    },
    setOtherDataInviteIpfs(state, action) {
      return { ...state, other_data_invite_ipfs: action.payload };
    },
    setUploadState(state, action) {
      return {
        ...state,
        multi_pro: state.multi_pro.map((v, ind) =>
          ind === action.payload.i
            ? { ...v, uploading: action.payload.data }
            : v
        ),
      };
    },
    setUploadNotStarted(state, action) {
      const { multi_pro, allFileDataMulti } = action.payload;

      return {
        ...state,
        multi_pro: multi_pro,
        allFileDataMulti: allFileDataMulti,
      };
    },
    setMetaDataIndex(state, action) {
      return { ...state, metaDataIndex: action.payload };
    },
  },
});

const {
  setMetaData,
  setShowProgressBox,
  setMultiMetadata,
  setUploadLocation,
  setAbortController,
  setOtherData2,
  setOtherData2ipfs,
  setOtherDataInvite,
  setOtherDataInviteIpfs,
  setFolderErrIpfs,
  setFolderErr,
  setMultiFileandFolderIDAWS,
  setMultiFileandFolder,
  setAllFileDataMulti,
  setAllFileData,
  setSingleFileData,
  setFileUploadCancel,
  setUploadFrom,
  setmulti_pro,
  setMultipledata,
  setNewFolderData,
  setFileUniqueId,
  setCallAwsRootApi,
  setCallIpfsRootApi,
  setCallBothRootApi,
  setDisableupload,
  setUploadState,
  setUploadState2,
  setUploadNotStarted,
  setMetaDataIndex,
} = MetaDataSLice.actions;

export default MetaDataSLice.reducer;

//
export function UploadMetaData(
  uploadDataFrom,
  handleuploadLocation,
  singledata,
  filedata,
  abortController
) {
  return async function UploadMetaDataThunk(dispatch, getstate) {
    try {
      dispatch(setShowProgressBox(true));
      dispatch(setUploadLocation(handleuploadLocation));
      dispatch(setAbortController(abortController));
      dispatch(setAllFileData(singledata));
      dispatch(setSingleFileData(filedata));
      dispatch(setUploadFrom(uploadDataFrom));
      dispatch(setDisableupload(true));
      console.log(abortController);
      const size = partial({ base: 2, standard: "jedec" });
      const dataaa = filedata.path.lastIndexOf("/");

      const str_data = filedata.path.slice(1, dataaa + 1);

      const data_folder = singledata.awsFolder.indexOf("/");
      const str_data_folder = singledata.awsFolder.slice(data_folder + 1);
      const Final_aws_folder =
        singledata.awsFolder === `${sessionStorage.getItem("userID")}/`
          ? `${sessionStorage.getItem("userID")}/`
          : str_data_folder;
      const final_folder_aws =
        handleuploadLocation === "ipfs" ? "" : `${Final_aws_folder}${str_data}`;
      const final_folder_ipfs =
        handleuploadLocation === "aws"
          ? ""
          : `${singledata.FolderNameIPFS}${str_data}`;
      var IPFS_folder_single =
        final_folder_ipfs === "" ||
        final_folder_ipfs === undefined ||
        final_folder_ipfs === null
          ? `atom-storage/${sessionStorage.getItem("userID")}/`
          : final_folder_ipfs;

      var Folder_name_ipfs =
        uploadDataFrom === "single"
          ? singledata?.ipfsfoldername === undefined
            ? `atom-storage/${sessionStorage.getItem("userID")}/`
            : singledata?.ipfsfoldername
          : final_folder_ipfs;
      const str_aws = singledata?.awsFolder;
      // const ind_aws = str_aws.indexOf("/");
      const ind_aws = str_aws;

      // const new_str_aws = str_aws.slice(ind_aws + 1);
      const new_str_aws = str_aws;
      console.log(final_folder_aws);
      console.log(new_str_aws);

      var Folder_name =
        // uploadDataFrom === "single"
        //   ?
        final_folder_aws === ""
          ? `${new_str_aws}/`
          : singledata?.awsFolder.endsWith("/")
          ? singledata?.awsFolder
          : `${singledata[0]?.awsFolder}/`;
      // : final_folder_aws === "" ||
      //   final_folder_aws === `${sessionStorage.getItem("userID")}/` ||
      //   final_folder_aws.endsWith("/") ||
      //   final_folder_aws.includes(`${new_str_aws}/`)
      // ? new_str_aws === ""
      //   ? `${sessionStorage.getItem("userID")}/`
      //   : new_str_aws.includes(`${sessionStorage.getItem("userID")}/`)
      //   ? `${new_str_aws}`
      //   : `${sessionStorage.getItem("userID")}/${new_str_aws}`
      // : `${new_str_aws}${final_folder_aws}/`;

      const file_name_with_hyphen = filedata?.name?.replace(/ /g, "-");
      let file_unique_id = "";
      let nameWithUUID = uuidv4() + "_" + file_name_with_hyphen;
      dispatch(
        setCancelDataFunc(0, "metadata", nameWithUUID, new AbortController())
      );

      console.log(final_folder_aws);

      if (handleuploadLocation === "aws") {
        file_unique_id = "1_0_" + nameWithUUID;
      } else if (handleuploadLocation === "ipfs") {
        file_unique_id = "0_1_" + nameWithUUID;
      } else if (handleuploadLocation === "both") {
        file_unique_id = "1_1_" + nameWithUUID;
      }

      dispatch(setFileUniqueId(file_unique_id)); //

      let headerConfig = {
        headers: {
          authorization: "Bearer " + sessionStorage.getItem("userToken"),
        },
        signal: getstate().cancel.fileData.abortController.signal,
      };
      console.log(singledata, uploadDataFrom);
      var data = {
        userId: sessionStorage.getItem("userID"), //m
        // amazonUrl: Location, //nm
        // amazonKey: Key, //nm
        // fileCId: cid, //nm
        isAmazon: singledata.isAmazon, //m
        isIPFS: singledata.isIPFS, //m
        bucketName: singledata.awsBucketName, //ma
        folderName: Folder_name, //ma
        active: true,
        fileSize: size(filedata.size), //m
        fileName: filedata?.name, //m
        fileNameReplace: filedata?.name?.replace(/ /g, "-"), //m
        fileNameWithUuid: nameWithUUID, //m
        fileType: filedata?.type, //nm
        description: singledata.fileDesc, //nm
        fileTags: singledata.fileTag,
        keywords: singledata.fileKeyWord,
        orgName: singledata.orgName,
        isMedData: singledata.medicalformvisible,
        pFName: singledata.firstName,
        pMName: "",
        pLName: singledata.lastName,
        pDOB: singledata.selectedDate,
        pAge: Number(singledata.age),
        pGender: singledata.gender,
        pEmail: singledata.email,
        pPhone: singledata.phone,
        h_id_1: singledata.h_id_1,
        h_id_2: singledata.h_id_2,
        h_id_3: singledata.h_id_3,
        country: singledata.CountryCode,
        uploadDateAndTime: new Date(),
        file_unique_id: file_unique_id, //m
        // folderId: final_folder_aws, //ma // my folder 2/my folder 22/ (*** make this dynamic ***)
        sizeInBytes: filedata?.size, //m
        ipfsFolderName:
          handleuploadLocation === "both"
            ? IPFS_folder_single
            : Folder_name_ipfs, //mi
        // ipfsFolderId: final_folder_ipfs, //mi
        bucketId_aws: singledata.bucketId, //ma
        bucketId_ipfs: singledata.bucketIdIpfs, //mi
        bucketACLOption:
          handleuploadLocation === "ipfs"
            ? "private"
            : singledata.bucketAclOption, //m
        deleteAt: singledata.dateToSend,
      };

      var API_URL = GLOBAL_URL_DOMAIN_LIVE + "/file/add-metadata";
      console.log(data);
      axios
        .post(API_URL, data, headerConfig)
        .then((res) => {
          console.log("metadata res***", res);
          console.log(abortController);
          if (res?.data?.success === true) {
            dispatch(
              UploadFile(
                handleuploadLocation,
                singledata,
                filedata,
                uploadDataFrom,
                abortController,
                nameWithUUID,
                file_unique_id
              )
            );

            // dispatch(getIPFSFileData());
            // dispatch(foderwiseAwsFiless());
            if (handleuploadLocation === "aws") {
              console.log(singledata.awsBucketName);
              const othdatainvite = [[file_unique_id]];
              dispatch(setOtherDataInvite(othdatainvite));

              dispatch(foderwiseAwsFiless(singledata.bucketId));
              dispatch(
                getAwsRootData(singledata.bucketId, singledata.awsBucketName)
              );
            } else if (handleuploadLocation === "ipfs") {
              const othdatainviteIpfs = [[file_unique_id]];
              dispatch(setOtherDataInviteIpfs(othdatainviteIpfs));
              dispatch(
                getIPFSFileData(
                  `atom-storage/${sessionStorage.getItem("userID")}/`,
                  "6e2f3b90-1dd9-4b7c-9be8-904bd4801e55"
                )
              );
              dispatch(
                getIPFSRootData(
                  `atom-storage/${sessionStorage.getItem("userID")}/`
                )
              );
            } else if (handleuploadLocation === "both") {
              const othdatainvite = [[file_unique_id]];
              dispatch(setOtherDataInvite(othdatainvite));
              const othdatainviteIpfs = [[file_unique_id]];
              dispatch(setOtherDataInviteIpfs(othdatainviteIpfs));
              dispatch(getBothFileData());
            }
          }

          console.log("meta from redux", res?.data?.data);
          dispatch(setMetaData(res?.data?.data));
          //Single file metadata call and open access popup
        })
        .catch((err) => {
          console.log("metadata err***", err);
          dispatch(setFileUploadCancel("Upload canceled"));
          //   setFileUploadCancelAtom("Upload canceled");
          //   multi_pro[id].message = err.response.data.message;

          //   errorToast(err.response.data.message);
          if (err?.response?.status === 427) {
            sessionStorage.clear();
            localStorage.clear();
            Cookie.remove("fName");
            Cookie.remove("lName");
            Cookie.remove("userID");
            Cookie.remove("userToken");
            Cookie.remove("token_start_time");
            window.location.replace("/");
          }
        })
        .then(function () {});
    } catch (err) {
      console.log(err);
      console.log(data);
    }
  };
}

//
export function hideProgressBox() {
  return async function hideProgressBoxThunk(dispatch, getstate) {
    try {
      dispatch(setShowProgressBox(false));
    } catch (err) {
      console.log(err);
    }
  };
}

//
export function rootApiCall() {
  return async function RootApiCallThunk(dispatch, getstate) {
    try {
      dispatch(setCallAwsRootApi(false));
      dispatch(setCallIpfsRootApi(false));
      dispatch(setCallBothRootApi(false));
    } catch (err) {
      console.log(err);
    }
  };
}

//
export function resetUploadMetaData() {
  return async function resetUploadMetaDataThunk(dispatch, getstate) {
    try {
      dispatch(setMetaData([]));
      dispatch(setShowProgressBox(false));
      dispatch(setMultiMetadata([]));
      dispatch(setUploadLocation(""));
      dispatch(setSingleFileData([]));
      dispatch(setAllFileData([]));
      dispatch(setAbortController([]));
      dispatch(setAllFileDataMulti([]));
      dispatch(setOtherData2([]));
      dispatch(setOtherData2ipfs([]));
      dispatch(setFolderErrIpfs([]));
      dispatch(setFolderErr([]));
      dispatch(setMultiFileandFolderIDAWS([]));
      dispatch(setMultiFileandFolder([]));
      dispatch(setFileUploadCancel(""));
      dispatch(setUploadFrom(""));
      dispatch(setmulti_pro([]));
      dispatch(setMultipledata([]));
      dispatch(setNewFolderData([]));
      dispatch(setFileUniqueId(""));
      dispatch(setCallAwsRootApi(false));
      dispatch(setCallIpfsRootApi(false));
      dispatch(setCallBothRootApi(false));
      dispatch(setDisableupload(false));
      dispatch(setMetaDataIndex(0));
    } catch (err) {
      console.log(err);
    }
  };
}

export function MultipleUploadMetaData(
  uploadDataFrom,
  handleuploadLocation,
  multipledata,
  multiplefiledata,
  multi_pro,
  newFolderData,
  abortController,
  index
) {
  return async function MultipleUploadMetaDataThunk(dispatch, getstate) {
    try {
      dispatch(setMetaDataIndex(index));
      dispatch(setShowProgressBox(true));
      dispatch(setUploadLocation(handleuploadLocation));
      // dispatch(setAbortController(abortController));
      dispatch(setUploadFrom(uploadDataFrom));
      var dumy = getstate().metadata.multi_pro;
      console.log(getstate().metadata.multi_pro, dumy.length);
      if (getstate().metadata.multi_pro.length === 0) {
        console.log("inn");
        dispatch(setmulti_pro(multi_pro));
      }
      dispatch(setAllFileDataMulti(multiplefiledata));
      dispatch(setMultipledata(multipledata));
      dispatch(setNewFolderData(newFolderData));
      dispatch(setDisableupload(true));
      dispatch(setUploadState({ i: index, data: "metadata" }));

      console.log(multiplefiledata);
      const size = partial({ base: 2, standard: "jedec" });
      const dataaa = multiplefiledata[0][index].path.lastIndexOf("/");

      const str_data = multiplefiledata[0][index].path.slice(1, dataaa + 1);

      const data_folder = multipledata.awsFolder.indexOf("/");
      const str_data_folder = multipledata.awsFolder.slice(data_folder + 1);
      const Final_aws_folder =
        multipledata.awsFolder === `${sessionStorage.getItem("userID")}/`
          ? `${sessionStorage.getItem("userID")}/`
          : str_data_folder;
      const final_folder_aws =
        handleuploadLocation === "ipfs" ? "" : `${Final_aws_folder}${str_data}`;
      const final_folder_ipfs =
        handleuploadLocation === "aws"
          ? ""
          : `${multipledata.FolderNameIPFS}${str_data}`;
      var IPFS_folder_single =
        final_folder_ipfs === "" ||
        final_folder_ipfs === undefined ||
        final_folder_ipfs === null
          ? `atom-storage/${sessionStorage.getItem("userID")}/`
          : final_folder_ipfs;

      var Folder_name_ipfs =
        uploadDataFrom === "single"
          ? multipledata?.ipfsfoldername === undefined
            ? `atom-storage/${sessionStorage.getItem("userID")}/`
            : multipledata?.ipfsfoldername
          : final_folder_ipfs;
      const str_aws =
        multipledata === undefined ? "/" : multipledata?.awsFolder;
      // const ind_aws = str_aws.indexOf("/");
      const ind_aws = str_aws;

      // const new_str_aws = str_aws.slice(ind_aws + 1);
      const new_str_aws = str_aws;
      console.log(final_folder_aws);
      console.log(new_str_aws);

      var Folder_name = final_folder_aws;

      const file_name_with_hyphen = multiplefiledata[0][index]?.name?.replace(
        / /g,
        "-"
      );
      let file_unique_id = "";
      let nameWithUUID = uuidv4() + "_" + file_name_with_hyphen;
      console.log(final_folder_aws);
      dispatch(
        setCancelDataFunc(
          index,
          "metadata",
          nameWithUUID,
          new AbortController()
        )
      );

      if (handleuploadLocation === "aws") {
        file_unique_id = "1_0_" + nameWithUUID;
      } else if (handleuploadLocation === "ipfs") {
        file_unique_id = "0_1_" + nameWithUUID;
      } else if (handleuploadLocation === "both") {
        file_unique_id = "1_1_" + nameWithUUID;
      }
      dispatch(setFileUniqueId(file_unique_id)); //

      let headerConfig = {
        headers: {
          authorization: "Bearer " + sessionStorage.getItem("userToken"),
        },
        signal: getstate().cancel.fileData.abortController.signal,
      };
      console.log(multipledata, uploadDataFrom);
      var data = {
        userId: sessionStorage.getItem("userID"), //m
        // amazonUrl: Location, //nm
        // amazonKey: Key, //nm
        // fileCId: cid, //nm
        isAmazon: multipledata.isAmazon,
        //m
        isIPFS: multipledata.isIPFS,
        bucketName: multipledata.awsBucketName, //ma
        folderName: Folder_name, //ma
        active: true,
        fileSize: size(multiplefiledata[0][index].size), //m
        fileName: multiplefiledata[0][index]?.name, //m
        fileNameReplace: multiplefiledata[0][index]?.name?.replace(/ /g, "-"), //m
        fileNameWithUuid: nameWithUUID, //m
        fileType: multiplefiledata[0][index]?.type, //nm
        description: multipledata.fileDesc, //nm
        fileTags: multipledata.fileTag,
        keywords: multipledata.fileKeyWord,
        orgName: multipledata.orgName,
        isMedData: multipledata.medicalformvisible,
        pFName: multipledata.firstName,
        pMName: "",
        pLName: multipledata.lastName,
        pDOB: multipledata.selectedDate,
        pAge: Number(multipledata.age),
        pGender: multipledata.gender,
        pEmail: multipledata.email,
        pPhone: multipledata.phone,
        h_id_1: multipledata.h_id_1,
        h_id_2: multipledata.h_id_2,
        h_id_3: multipledata.h_id_3,
        country: multipledata.CountryCode,
        uploadDateAndTime: new Date(),
        file_unique_id: file_unique_id, //m
        // folderId: final_folder_aws, //ma // my folder 2/my folder 22/ (*** make this dynamic ***)
        sizeInBytes: multiplefiledata[0][index]?.size, //m
        ipfsFolderName:
          handleuploadLocation === "both"
            ? IPFS_folder_single
            : Folder_name_ipfs, //mi
        // ipfsFolderId: final_folder_ipfs, //mi
        bucketId_aws: multipledata.bucketId, //ma
        bucketId_ipfs: multipledata.bucketIdIpfs, //mi
        bucketACLOption:
          handleuploadLocation === "ipfs"
            ? "private"
            : multipledata.bucketAclOption, //m
        deleteAt: multipledata.dateToSend,
      };

      var API_URL = GLOBAL_URL_DOMAIN_LIVE + "/file/add-metadata";
      console.log(data);
      axios
        .post(API_URL, data, headerConfig)
        .then((res) => {
          console.log("metadata res***", res);
          console.log(abortController);
          if (res?.data?.success === true) {
            dispatch(
              UploadMultipleFile(
                uploadDataFrom,
                handleuploadLocation,
                multipledata,
                multiplefiledata,
                // multi_pro,
                newFolderData,
                abortController,
                nameWithUUID,
                file_unique_id,
                index
              )
            );
            // dispatch(getIPFSFileData());
            // dispatch(foderwiseAwsFiless());

            if (handleuploadLocation === "aws") {
              dispatch(setCallAwsRootApi(true));
            } else if (handleuploadLocation === "ipfs") {
              dispatch(setCallIpfsRootApi(true));
            } else if (handleuploadLocation === "both") {
              dispatch(setCallBothRootApi(true));
            }
          }
          const previousUniqueId = res?.data?.data?.file_unique_id;
          const bucket = res?.data?.data?.bucketName;
          const folderName = res?.data?.data?.folderName;
          const folderName_ipfs = res?.data?.data?.ipfsFolderName;
          const folderId = res?.data?.data?.folderId;
          const folderId_ipfs = res?.data?.data?.ipfsFolderId;
          console.log("res", res);
          const multidata = [
            ...getstate().metadata.multiMetadata,
            previousUniqueId,
          ];
          dispatch(setMultiMetadata(multidata));
          //For give access user[register user] Multiple File name
          const multifilefolderaws = [
            ...getstate().metadata.MultiFileandFolderIDAWS,
            [res?.data?.data?.file_unique_id],
          ];
          dispatch(setMultiFileandFolderIDAWS(multifilefolderaws));

          //For invite user File Folder Data AWS
          if (handleuploadLocation === "both") {
            console.log("in both ");
            // const mulfilefol = [
            //   ...getstate().metadata.MultiFileandFolder,
            //   [res?.data?.data?.file_unique_id],
            // ];
            // dispatch(setMultiFileandFolder(mulfilefol));
            const othdatainvite = [
              ...getstate().metadata.other_data_invite,
              [previousUniqueId],
            ];
            dispatch(setOtherDataInvite(othdatainvite));

            const othdata2 = [
              ...getstate().metadata.other_data_2,
              [bucket + "/" + folderName, previousUniqueId],
            ];
            console.log(othdata2, folderName, previousUniqueId);
            dispatch(setOtherData2(othdata2));
            //For invite user File Folder Data IPFS
            const othdatainviteIpfs = [
              ...getstate().metadata.other_data_invite_ipfs,
              [previousUniqueId],
            ];
            dispatch(setOtherDataInviteIpfs(othdatainviteIpfs));
            const othdataipfs = [
              ...getstate().metadata.other_data_2_ipfs,
              [folderName_ipfs, previousUniqueId],
            ];
            dispatch(setOtherData2ipfs(othdataipfs));
            //For invite or give access user Folder Data IPFS

            const uniqueRootFolders = Object.values(
              multiplefiledata[0].reduce((acc, current) => {
                const rootFolder = current.path.split("/").filter(Boolean)[0];

                // Create the unique key as the root folder
                const uniqueKey = `${rootFolder}/`;

                // Add to accumulator if not already present
                if (!acc[uniqueKey]) {
                  if (
                    multipledata.awsFolder ===
                    sessionStorage.getItem("userID") + "/"
                  ) {
                    acc[uniqueKey] =
                      multipledata.awsBucketName +
                      "/" +
                      multipledata.awsFolder +
                      uniqueKey;
                  } else {
                    acc[uniqueKey] = multipledata.awsFolder + uniqueKey;
                  }
                }

                return acc;
              }, {})
            );

            console.log(uniqueRootFolders);
            dispatch(setFolderErr(uniqueRootFolders));
            //For give access user Folder name aws

            const uniqueRootFoldersipfs = Object.values(
              multiplefiledata[0].reduce((acc, current) => {
                const rootFolder = current.path.split("/").filter(Boolean)[0];

                // Create the unique key as the root folder
                const uniqueKey = `${rootFolder}/`;

                // Add to accumulator if not already present
                if (!acc[uniqueKey]) {
                  acc[uniqueKey] = multipledata.ipfsfoldername + uniqueKey;
                }

                return acc;
              }, {})
            );

            dispatch(setFolderErrIpfs(uniqueRootFoldersipfs));
            //For give access user Folder name IPFS
          } else if (handleuploadLocation === "ipfs") {
            const othdatainviteIpfs = [
              ...getstate().metadata.other_data_invite_ipfs,
              [previousUniqueId],
            ];
            dispatch(setOtherDataInviteIpfs(othdatainviteIpfs));
            const othdataipfs = [
              ...getstate().metadata.other_data_2_ipfs,
              [folderName_ipfs, previousUniqueId],
            ];
            dispatch(setOtherData2ipfs(othdataipfs));

            const uniqueRootFoldersipfs = Object.values(
              multiplefiledata[0].reduce((acc, current) => {
                const rootFolder = current.path.split("/").filter(Boolean)[0];

                // Create the unique key as the root folder
                const uniqueKey = `${rootFolder}/`;

                // Add to accumulator if not already present
                if (!acc[uniqueKey]) {
                  acc[uniqueKey] = multipledata.ipfsfoldername + uniqueKey;
                }

                return acc;
              }, {})
            );

            dispatch(setFolderErrIpfs(uniqueRootFoldersipfs));
          } else if (handleuploadLocation === "aws") {
            const othdatainvite = [
              ...getstate().metadata.other_data_invite,
              [previousUniqueId],
            ];
            dispatch(setOtherDataInvite(othdatainvite));
            const othdata2 = [
              ...getstate().metadata.other_data_2,
              [bucket + "/" + folderName, previousUniqueId],
            ];
            console.log(othdata2, folderName, previousUniqueId);
            dispatch(setOtherData2(othdata2));

            const uniqueRootFolders = Object.values(
              multiplefiledata[0].reduce((acc, current) => {
                const rootFolder = current.path.split("/").filter(Boolean)[0];

                // Create the unique key as the root folder
                const uniqueKey = `${rootFolder}/`;

                // Add to accumulator if not already present
                if (!acc[uniqueKey]) {
                  if (
                    multipledata.awsFolder ===
                    sessionStorage.getItem("userID") + "/"
                  ) {
                    acc[uniqueKey] =
                      multipledata.awsBucketName +
                      "/" +
                      multipledata.awsFolder +
                      uniqueKey;
                  } else {
                    acc[uniqueKey] = multipledata.awsFolder + uniqueKey;
                  }
                }

                return acc;
              }, {})
            );

            console.log(uniqueRootFolders);
            dispatch(setFolderErr(uniqueRootFolders));
          }

          //For invite or give access user Folder Data aws

          console.log(multiplefiledata, multipledata.awsFolder);

          ///******Check conditions for Cancel file retry file and success upload file****/
          var TotalFileCancelToNumber =
            sessionStorage.getItem("totalFilecancled");
          console.log(sessionStorage.getItem("totalFilecancled"));
        })
        .catch((err) => {
          console.log("metadata err***", err);
          dispatch(setFileUploadCancel("Upload canceled"));
          //   setFileUploadCancelAtom("Upload canceled");
          //   multi_pro[id].message = err.response.data.message;

          //   errorToast(err.response.data.message);
          if (err?.response?.status === 427) {
            sessionStorage.clear();
            localStorage.clear();
            Cookie.remove("fName");
            Cookie.remove("lName");
            Cookie.remove("userID");
            Cookie.remove("userToken");
            Cookie.remove("token_start_time");
            window.location.replace("/");
          }
        })
        .then(function () {});
    } catch (err) {
      console.log(err);
    }
  };
}

export function changeCurrentUploadLoc(index, data) {
  return async function changeCurrentUploadLocThunk(dispatch, getState) {
    try {
      // dispatch(setUploadState({i: index, data: data}))

      //       const tempArr = getstate().metadata.other_data_invite.map(
      //         (obj) => ([
      //           ...obj,
      //         ])
      //       );

      const tempArr = getState().metadata.multi_pro.map((v) => ({ ...v }));
      console.log(tempArr);
      tempArr[index].uploading = data;
      dispatch(setmulti_pro(tempArr));
    } catch (err) {
      console.log("changeCurrentUploadLoc slice error:", err);
    }
  };
}

//
export function updatwMultiPro(multi_pro, index, prog) {
  return async function updatwMultiProThunk(dispatch, getstate) {
    try {
      console.log(multi_pro, index, prog);
      const ind = multi_pro.findIndex((value) => value.index === index);
      console.log(ind);
      if (ind !== -1) {
        const tempArr = multi_pro.map((obj) => ({ ...obj }));
        console.log(tempArr);
        tempArr[index].progress = prog;
        console.log(prog);
        console.log(tempArr);
        dispatch(setmulti_pro(tempArr));
      }
    } catch (err) {
      console.log(err);
    }
  };
}

//
export function updateotherdata2(id, file_unique_id) {
  return async function updateotherdata2Thunk(dispatch, getstate) {
    try {
      const tempArr = getstate().metadata.other_data_2.map((obj) => [...obj]);

      console.log(id, tempArr);
      for (let i = 0; i < tempArr.length; i++) {
        if (tempArr[i][1] === file_unique_id) {
          tempArr[i][2] = id;
        }
      }
      console.log(tempArr);
      dispatch(setOtherData2(tempArr));
    } catch (err) {
      console.log(err);
    }
  };
}

//
export function updateotherdata2ipfs(id, file_unique_id) {
  return async function updateotherdata2ipfsThunk(dispatch, getstate) {
    try {
      const tempArr = getstate().metadata.other_data_2_ipfs.map((obj) => [
        ...obj,
      ]);

      console.log(id, tempArr);
      for (let i = 0; i < tempArr.length; i++) {
        if (tempArr[i][1] === file_unique_id) {
          tempArr[i][2] = id;
        }
      }
      console.log(tempArr);
      dispatch(setOtherData2ipfs(tempArr));
    } catch (err) {
      console.log(err);
    }
  };
}

//
export function updateotherdataInvite(id, file_unique_id) {
  return async function updateotherdataInviteThunk(dispatch, getstate) {
    try {
      const tempArr = getstate().metadata.other_data_invite.map((obj) => [
        ...obj,
      ]);

      console.log(id, tempArr);
      for (let i = 0; i < tempArr.length; i++) {
        if (tempArr[i][0]===file_unique_id) {
          tempArr[i][1] = id;
        }
      }
      console.log(tempArr);
      dispatch(setOtherDataInvite(tempArr));
    } catch (err) {
      console.log(err);
    }
  };
}

//
export function updateotherdataIpfsInvite(id, file_unique_id) {
  return async function updateotherdataIpfsInviteThunk(dispatch, getstate) {
    try {
      const tempArr = getstate().metadata.other_data_invite_ipfs.map((obj) => [
        ...obj,
      ]);

      console.log(id, tempArr);
      for (let i = 0; i < tempArr.length; i++) {
        if (tempArr[i][0] === file_unique_id) {
          tempArr[i][1] = id;
        }
      }
      console.log(tempArr);
      dispatch(setOtherDataInviteIpfs(tempArr));
    } catch (err) {
      console.log(err);
    }
  };
}

//
export function updateMultiFileFolaws(id, file_unique_id) {
  return async function updateMultiFileFolawsThunk(dispatch, getstate) {
    try {
      const tempArr = getstate().metadata.MultiFileandFolderIDAWS.map(
        (obj) => ({
          ...obj,
        })
      );

      console.log(id, tempArr);
      for (let i = 0; i < tempArr.length; i++) {
        if (tempArr[i][0] === file_unique_id) {
          tempArr[i][1] = id;
        }
      }
      console.log(tempArr);
      dispatch(setMultiFileandFolderIDAWS(tempArr));
    } catch (err) {
      console.log(err);
    }
  };
}

//
export function updateMultiFileFol(id, file_unique_id) {
  return async function updateMultiFileFolThunk(dispatch, getstate) {
    try {
      const tempArr = getstate().metadata.MultiFileandFolder.map((obj) => ({
        ...obj,
      }));

      console.log(id, tempArr);
      for (let i = 0; i < tempArr.length; i++) {
        if (tempArr[i][0] === file_unique_id) {
          tempArr[i][1] = id;
        }
      }
      console.log(tempArr);
      dispatch(setMultiFileandFolder(tempArr));
    } catch (err) {
      console.log(err);
    }
  };
}

export function cancel_multi_upload_metadata(
  uploadFrom,
  uploadLocation,
  multipledata,
  allFileDataMulti,
  multi_pro,
  newFolderData,
  abortController2,
  ind
) {
  return async function cancel_multi_upload_metadataThunk(dispatch, getState) {
    try {
      // aborting the request
      const allState = getState();
      const abortController = allState.cancel.fileData.abortController;
      abortController && abortController.abort();

      const file_unique_id = allState.metadata.file_unique_id;
      const file_name = file_unique_id.slice(4);

      // calling the cancel upload api
      const data = {
        file_id: file_unique_id,
        file_name: file_name,
        location: uploadLocation,
        bucketName:
          uploadLocation === "ipfs"
            ? "atom-storage"
            : multipledata.awsBucketName,
      };

      const headerConfig = {
        headers: {
          authorization: "Bearer " + sessionStorage.getItem("userToken"),
        },
      };

      const url = GLOBAL_URL_DOMAIN_File_Upload_Live + `/file/cancel-upload`;

      const res = await axios.post(url, data, headerConfig);

      console.log("new cancel res metadata***", res);

      dispatch(setAbortedFunc(ind));

      dispatch(
        MultipleUploadMetaData(
          uploadFrom,
          uploadLocation,
          multipledata,
          allFileDataMulti,
          multi_pro,
          newFolderData,
          abortController2,
          ind + 1
        )
      );
    } catch (err) {
      console.log("cancel_multi_upload_metadata err:", err);

      if (err?.response?.status === 427) {
        sessionStorage.clear();
        localStorage.clear();
        Cookie.remove("fName");
        Cookie.remove("lName");
        Cookie.remove("userID");
        Cookie.remove("userToken");
        Cookie.remove("token_start_time");
        window.location.replace("/");
      } else {
        dispatch(setAbortErrFunc(ind));
        dispatch(
          MultipleUploadMetaData(
            uploadFrom,
            uploadLocation,
            multipledata,
            allFileDataMulti,
            multi_pro,
            newFolderData,
            abortController2,
            ind + 1
          )
        );
      }
    }
  };
}

export function cancel_multi_not_started(ind) {
  return async function cancel_multi_not_startedThunk(dispatch, getState) {
    try {
      // multi_pro
      // allFileDataMulti,

      const metadataState = getState().metadata;

      const temp_multi_pro = [...metadataState.multi_pro];
      temp_multi_pro.splice(ind, 1);

      const temp_allFileDataMulti = metadataState.allFileDataMulti.map(
        (innerArr) => innerArr.map((obj) => ({ ...obj }))
      );
      temp_allFileDataMulti[0].splice(ind, 1);

      dispatch(
        setUploadNotStarted({
          multi_pro: temp_multi_pro,
          allFileDataMulti: temp_allFileDataMulti,
        })
      );

      // dispatch(setAbortedFunc(ind))
      // dispatch(setNotStartedIndFunc(ind))
    } catch (err) {
      console.log("cancel_multi_not_started slice err***", err);
      dispatch(setAbortErrFunc(ind));
    }
  };
}

export function cancel_single_upload_metadata(uploadLocation, bucketName) {
  return async function cancel_single_upload_metadataThunk(dispatch, getState) {
    try {
      // aborting the request
      const allState = getState();
      const abortController = allState.cancel.fileData.abortController;
      abortController && abortController.abort();

      const file_unique_id = allState.metadata.file_unique_id;
      const file_name = file_unique_id.slice(4);

      // calling the cancel upload api
      const data = {
        file_id: file_unique_id,
        file_name: file_name,
        location: uploadLocation,
        bucketName: uploadLocation === "ipfs" ? "atom-storage" : bucketName,
      };

      const headerConfig = {
        headers: {
          authorization: "Bearer " + sessionStorage.getItem("userToken"),
        },
      };

      const url = GLOBAL_URL_DOMAIN_File_Upload_Live + `/file/cancel-upload`;

      await axios.post(url, data, headerConfig);

      dispatch(setAbortedFunc(0));
    } catch (err) {
      console.log("cancel_single_upload_metadata err:", err);
      if (err?.response?.status === 427) {
        sessionStorage.clear();
        localStorage.clear();
        Cookie.remove("fName");
        Cookie.remove("lName");
        Cookie.remove("userID");
        Cookie.remove("userToken");
        Cookie.remove("token_start_time");
        window.location.replace("/");
      } else {
        dispatch(setAbortErrFunc(0));
      }
    }
  };
}
