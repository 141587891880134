// export const GLOBAL_URL_DOMAIN_LIVE = "http://localhost:3030/n1/r1/c1/p1/api/atom-storage/v1";

// export const GLOBAL_URL_DOMAIN = "http://getway.atomcenter.io:3030/n1/r1/c1/p1/api/atom-storage/v1";

export const GLOBAL_URL_DOMAIN_LIVE = "https://ipfsapi.moramba.io:3030/api/atom-storage/v1";

export const GLOBAL_URL_DOMAIN = "http://localhost:3030/api/atom-storage/v1";

export const GLOBAL_URL_DOMAIN_File_Upload = "http://localhost:5123/api/atom-storage-file/v1";

export const GLOBAL_URL_DOMAIN_File_Upload_Live = "https://ipfsuploadapi.moramba.io:5123/api/atom-storage-file/v1";






