import React, { useEffect, useState } from "react";
import "./Viewer.css";
import { GLOBAL_URL_DOMAIN_File_Upload_Live } from "../../utils/Files/url";
import axios from "axios";
import Cookie from "js-cookie";
import { errorToast, InfoToast } from "../../utils/Files/toasterMsgs";
import { useLocation, useNavigate } from "react-router-dom";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import "@cyntler/react-doc-viewer/dist/index.css";
import { MdOutlineKeyboardBackspace } from "react-icons/md";

const Viewer = () => {
  const { state } = useLocation();
  const navigate = useNavigate();

  const [fileURL, setFileURL] = useState("");
  const [fileUniqueId, setFileUniqueId] = useState("");
  const [filenameUuid, setFilenameUuid] = useState("");
  const [fileType, setFileType] = useState("");
  const [bucketACLOption, setBucketACLOption] = useState("");
  const [amazonKey, setAmazonKey] = useState("");
  const [fileName, setFileName] = useState("");
  const [awsBkt, setAwsBkt] = useState("");
  const [ipfsCID, setIpfsCID] = useState("");

  // Language text begins here
  const [Web_server_text, setWeb_server_text] = useState(
    "Upload to Web Server in progress"
  );

  useEffect(() => {
    if (state) {
      setFileType(state.fileType);
      setBucketACLOption(state.bucketACLOption);
      setAmazonKey(state.amazonKey);
      setAwsBkt(state.awsBkt);
      setIpfsCID(state.ipfsCID);
      setFileName(state.fileName);
      setFilenameUuid(state.filenameUuid);
      setFileUniqueId(state.fileUniqueId);
    }
  }, [state]);

  useEffect(() => {
    if (fileName && filenameUuid && filenameUuid) {
      checkFileLocation();
    }
  }, [fileName, filenameUuid, filenameUuid]);

  const handleDownloadFile = async (
    File_key,
    FileFinalName,
    file_type,
    bucket_aws
  ) => {
    const key = File_key; // variable 1
    const fileType = file_type; // variable 2 (send "" when no fileType)
    const fileName = FileFinalName; // variable 3
    const bucket = bucket_aws; // variable 4

    try {
      const url =
        GLOBAL_URL_DOMAIN_File_Upload_Live +
        `/download-file/download-from-awss3`;
      const headerConfig = {
        headers: {
          authorization: "Bearer " + sessionStorage.getItem("userToken"),
        },
      };

      const data = {
        key: key,
        fileType: fileType,
        bucket: bucket,
      };

      const res = await axios.post(url, data, {
        responseType: "blob",
        ...headerConfig,
      });

      console.log("response****", res);

      const blobData = new Blob([res?.data], {
        type: fileType || "application/octet-stream",
      });

      const tempUrl = window.URL.createObjectURL(blobData);
      setFileURL(tempUrl);
      console.log("tempUrl aws public***", tempUrl);
    } catch (err) {
      console.log("stream_s3_download error:", err);

      if (err?.response?.status === 427) {
        sessionStorage.clear();
        localStorage.clear();
        Cookie.remove("username");
        Cookie.remove("user_id");
        Cookie.remove("AdminFName");
        Cookie.remove("AdminLName");
        Cookie.remove("token");
        window.location.replace("/");
      }
    }
  };

  const downloadPrivateS3File = async (
    bucketName,
    File_key,
    FileFinalName,
    file_type
  ) => {
    const key = File_key; // variable 1
    const fileType = file_type; // variable 2 (send "" when no fileType)
    const fileName = FileFinalName; // variable 3
    const bucket = bucketName; // variable 4

    try {
      const url =
        GLOBAL_URL_DOMAIN_File_Upload_Live +
        `/download-file/download-from-awss3-private`;
      const headerConfig = {
        headers: {
          authorization: "Bearer " + sessionStorage.getItem("userToken"),
        },
      };

      const data = {
        key: key,
        fileType: fileType,
        bucket: bucket,
      };

      const res = await axios.post(url, data, {
        responseType: "blob",
        ...headerConfig,
      });

      console.log("response****", res);

      const blobData = new Blob([res?.data], { type: fileType });

      const tempUrl = window.URL.createObjectURL(blobData);
      setFileURL(tempUrl);
      console.log("tempUrl aws private***", tempUrl);
    } catch (err) {
      console.log("stream_s3_download error:", err);

      if (err?.response?.status === 427) {
        sessionStorage.clear();
        localStorage.clear();
        Cookie.remove("username");
        Cookie.remove("user_id");
        Cookie.remove("AdminFName");
        Cookie.remove("AdminLName");
        Cookie.remove("token");
        window.location.replace("/");
      }
    }
  };

  const handleDownloadFileIPFS = async (
    folder_cid,
    file_format,
    File_type,
    File_Name,
    i,
    row_id
  ) => {
    const filecid = folder_cid;
    const filename = File_Name;
    const fileType = File_type;
    // setLoaderAtom_storage((prevLoader) => ({ ...prevLoader, [row_id]: true }));
    try {
      const url =
        GLOBAL_URL_DOMAIN_File_Upload_Live +
        `/download-file/download-from-ipfs?filecid=${filecid}&filename=${filename}`;
      const headerConfig = {
        headers: {
          authorization: "Bearer " + sessionStorage.getItem("userToken"),
        },
      };

      const res = await axios.get(url, {
        responseType: "blob",
        ...headerConfig,
      });

      console.log("stream_ipfs_download res***", res);

      const blobData = new Blob([res?.data], {
        type: fileType || "application/octet-stream",
      });

      const tempUrl = window.URL.createObjectURL(blobData);
      setFileURL(tempUrl);
      console.log("tempUrl ipfs***", tempUrl);
    } catch (err) {
      console.log("stream_ipfs_download error***", err);

      if (err?.response?.status === 427) {
        sessionStorage.clear();
        localStorage.clear();
        Cookie.remove("username");
        Cookie.remove("user_id");
        Cookie.remove("AdminFName");
        Cookie.remove("AdminLName");
        Cookie.remove("token");
        window.location.replace("/");
      }
    }
  };

  const handleServerDownload = async () => {
    // ***************************
    try {
      const url =
        "https://ipfsuploadapi.moramba.io:5123/api/atom-storage-file/v1/download-file/download-from-server";

      const headerConfig = {
        headers: {
          authorization: "Bearer " + sessionStorage.getItem("userToken"),
        },
      };

      const data = {
        fileName: filenameUuid,
        fileType: fileType,
      };

      const res = await axios.post(url, data, {
        responseType: "blob",
        ...headerConfig,
      });

      console.log("stream_ipfs_server_download res*", res);

      const blobData = new Blob([res?.data], {
        type: fileType || "application/octet-stream",
      });

      const tempUrl = window.URL.createObjectURL(blobData);

      setFileURL(tempUrl);
      console.log("tempUrl server***", tempUrl);
    } catch (err) {
      console.log("server download error***", err);
      errorToast(err.message);
      if (err?.response?.status === 427) {
        sessionStorage.clear();
        localStorage.clear();
        Cookie.remove("username");
        Cookie.remove("user_id");
        Cookie.remove("AdminFName");
        Cookie.remove("AdminLName");
        Cookie.remove("token");
        window.location.replace("/");
      }
    }
  };

  const checkFileLocation = async () => {
    try {
      const headerConfig = {
        headers: {
          authorization: "Bearer " + sessionStorage.getItem("userToken"),
        },
      };

      const API_URL = GLOBAL_URL_DOMAIN_File_Upload_Live + `/file/upload-info`;

      const data = {
        file_unique_id: fileUniqueId,
      };

      const res = await axios.post(API_URL, data, headerConfig);
      console.log("res?????????", res);

      // (1) file is getting uploaded on web server
      if (
        res?.data?.success === true &&
        res?.data?.data?.isUploadDone_WebServer === false
      ) {
        console.log("Block 1");
        InfoToast(`${Web_server_text}...`);
      }

      // (2) upload to web server is completed. file is getting uploaded to aws s3 or ipfs or both
      // (3) upload to aws s3 or ipfs or both is completed. (file is present on server if error occurs in upload to any one or both locations for "both" upload)
      else if (
        res?.data?.success === true &&
        res?.data?.data?.isUploadDone_WebServer === true &&
        res?.data?.data?.uploadEndTime_WebServer !== null
      ) {
        if (
          res?.data?.data?.uploadLocation === "aws" &&
          res?.data?.data?.isUploadDone_AWSS3 === false
        ) {
          // download from server
          handleServerDownload();
          console.log("Block 2");
        } else if (
          res?.data?.data?.uploadLocation === "ipfs" &&
          res?.data?.data?.isUploadDone_IPFS === false
        ) {
          // download from server
          handleServerDownload();
          console.log("Block 3");
        } else if (
          res?.data?.data?.uploadLocation === "aws" &&
          res?.data?.data?.isUploadDone_AWSS3 === true
        ) {
          // download from aws s3
          if (bucketACLOption === "public") {
            handleDownloadFile(amazonKey, fileName, fileType, awsBkt);
          } else if (bucketACLOption === "private") {
            downloadPrivateS3File(awsBkt, amazonKey, fileName, fileType);
          }
          console.log("Block 4");
        } else if (
          res?.data?.data?.uploadLocation === "ipfs" &&
          res?.data?.data?.isUploadDone_IPFS === true
        ) {
          // download from ipfs
          handleDownloadFileIPFS(ipfsCID, "", fileType, fileName, "", "");
          console.log("Block 5");
        } else if (res?.data?.data?.uploadLocation === "both") {
          if (
            res?.data?.data?.isUploadDone_AWSS3 === true &&
            res?.data?.data?.isUploadDone_IPFS === true
          ) {
            // download from ipfs
            handleDownloadFileIPFS(ipfsCID, "", fileType, fileName, "", "");
            console.log("Block 6");
          } else if (
            res?.data?.data?.isUploadDone_AWSS3 === true &&
            res?.data?.data?.isUploadDone_IPFS === false
          ) {
            // download from aws s3
            if (bucketACLOption === "public") {
              handleDownloadFile(amazonKey, fileName, fileType, awsBkt);
            } else if (bucketACLOption === "private") {
              downloadPrivateS3File(awsBkt, amazonKey, fileName, fileType);
            }
            console.log("Block 7");
          } else if (
            res?.data?.data?.isUploadDone_AWSS3 === false &&
            res?.data?.data?.isUploadDone_IPFS === true
          ) {
            // download from ipfs
            handleDownloadFileIPFS(ipfsCID, "", fileType, filenameUuid, "", "");
            console.log("Block 8");
          } else if (
            res?.data?.data?.isUploadDone_AWSS3 === false &&
            res?.data?.data?.isUploadDone_IPFS === false
          ) {
            // download from server
            handleServerDownload();
            console.log("Block 9");
          }
        }
      }
    } catch (err) {
      console.log("checkFileLocation catch block:", err);
      errorToast(err.massage);
      if (err?.response?.status === 427) {
        sessionStorage.clear();
        localStorage.clear();
        Cookie.remove("username");
        Cookie.remove("user_id");
        Cookie.remove("AdminFName");
        Cookie.remove("AdminLName");
        Cookie.remove("token");
        window.location.replace("/");
      }
    }
  };

  const docs = [
    {
      uri: fileURL,
      fileName: fileName,
    },
  ];

  return (
    <>
      <div className="div-viewer">
        <button
          className="back-btn-viewer"
          style={{ marginTop: "35px" }}
          onClick={() => navigate(-1)}
        >
          <span>
            <MdOutlineKeyboardBackspace className="back-btn-icon" />
          </span>
          <span>Back</span>
        </button>
      </div>

      <DocViewer
        documents={docs}
        pluginRenderers={DocViewerRenderers}
        style={{
          width: "90%",
          margin: "auto",
          height: "100vh",
          overflowY: "scroll",
        }}
        theme={{
          primary: "#009087", // bar at the top
          secondary: "#009087", // border of the viewer
          // tertiary: "#009087",
          textPrimary: "#fff", // text color for the bar at the top
          // textSecondary: "#FF0000",
          // textTertiary: "#FF0000",
          disableThemeScrollbar: false, // shows vertical scrollbar
        }}
        config={{
          pdfVerticalScrollByDefault: true,
          pdfZoom: {
            scale: 5, // keep this for pdf
            defaultZoom: 1.1, // keep this for pdf
          },
        }}
        onError={(err) => {
          console.log("Error retrieving document:", err);
        }}
      />

      ;
    </>
  );
};

export default Viewer;
