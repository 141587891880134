import { createSlice } from "@reduxjs/toolkit";
import { GLOBAL_URL_DOMAIN_LIVE } from "../utils/Files/url";
import axios from "axios";
import Cookie from "js-cookie";
import { errorToast } from "../utils/Files/toasterMsgs";

const RegisteredUserSlice = createSlice({
  name: "Register User Data",
  initialState: [],
  reducers: {
    setAll_Users(state, action) {
      return (state = action.payload);
    },
  },
});

const { setAll_Users } = RegisteredUserSlice.actions;
export default RegisteredUserSlice.reducer;

export function getRegisterdUser() {
  return async function getRegisterdUserThunk(dispatch, getstate) {
    try {
      let headerConfig = {
        headers: {
          authorization: "Bearer " + sessionStorage.getItem("userToken"),
        },
      };

      let API_URL = GLOBAL_URL_DOMAIN_LIVE + `/user/registered-users`;
      axios
        .get(API_URL, headerConfig)
        .then(function (response) {
          const data = response?.data?.data;
          console.log("registered user data", data);
          dispatch(setAll_Users(data));
        })
        .catch(function (error) {
          console.log(error);
          // errorToast(err);
          if (error?.response?.status === 427) {
            sessionStorage.clear();
            localStorage.clear();
            Cookie.remove("fName");
            Cookie.remove("lName");
            Cookie.remove("userID");
            Cookie.remove("userToken");
            Cookie.remove("token_start_time");
            window.location.replace("/");
          }
          errorToast(error?.response?.data?.message);
        })
        .then(function () {});
    } catch (err) {
      console.log(err);
    }
  };
}
export function resetgetRegisterdUser() {
  return async function resetgetRegisterdUserThunk(dispatch, getState) {
    try {
      dispatch(setAll_Users([]));
    } catch (err) {
      console.log(err);
    }
  };
}
