import { createSlice} from "@reduxjs/toolkit";

   
const initialState = { 
    fileData: {
        ind: null, 
        data: "",
        filename: "",
        abortController: null,
    },
    overallData: {
        abortedInd: [],
        abortErrInd: [],
        // notStartedInd: []
    }
}

const CancelSlice = createSlice({
    name: "CancelSlice",
    initialState,
    reducers: {
        setCancelData(state, action) {
            const {ind, data, filename, abortController } = action.payload;
            state.fileData = {...state.fileData, ind: ind, data: data, filename: filename, abortController: abortController}
        },
        setAborted(state, action) {
            state.overallData = {...state.overallData, abortedInd: [...state.overallData.abortedInd, action.payload]}
        },
        setAbortErr(state, action) {
            state.overallData = {...state.overallData, abortErrInd: [...state.overallData.abortErrInd, action.payload]}
        },
        // setNotStartedInd(state, action) {
        //     state.overallData = {...state.overallData, notStartedInd: [...state.overallData.notStartedInd, action.payload]}
        // },
        resetCancelState(state, action) {
            return initialState
        }
    }
})  

const {setCancelData, setAborted, setAbortErr, resetCancelState} = CancelSlice.actions;

export default CancelSlice.reducer

export function setCancelDataFunc(ind, data, filename, abortController) {
    return async function setCancelDataFuncThunk(dispatch, getState) {
        try {
            dispatch(setCancelData({ind: ind, data: data, filename: filename, abortController: abortController}))
        } catch (err) {
            console.log("setCancelDataFunc err:", err)
        }
    }
}

export function setAbortedFunc(ind) {
    return async function setAbortedFuncThunk(dispatch, getState) {
        try {
            dispatch(setAborted(ind))
        } catch (err) {
            console.log("setAbortedFunc err:", err)
        }
    }
}

export function setAbortErrFunc(ind) {
    return async function setAbortErrFuncThunk(dispatch, getState) {
        try {
            dispatch(setAborted(ind));
            dispatch(setAbortErr(ind))
        } catch (err) {
            console.log("setAbortErrFunc err:", err)
        }
    }
}

// export function setNotStartedIndFunc(ind) {
//     return async function setNotStartedIndFuncThunk(dispatch, getState) {
//         try {
//             dispatch(setNotStartedInd(ind))
//         } catch (err) {
//             console.log("setNotStartedIndFunc err:", err)
//         }
//     }
// }


export function resetCancelStateFunc() {
    return async function resetCancelStateFuncThunk(dispatch, getState) {
        try {
            dispatch(resetCancelState())
        } catch (err) {
            console.log("resetCancelStateFunc err:", err)
        }
    }
}