import React, { useState, useEffect } from "react";
import "../AddUser/AddUser.css";
import Header from "../../Components/Header/Header";
import UserImg from "../../utils/images/user (5).png";
import EmailImg from "../../utils/images/email.png";
import PhoneInput from "react-phone-input-2";
import ReactFlagsSelect from "react-flags-select";
import { countriesList } from "../../utils/Files/data";
import CountryImg from "../../utils/images/earth.png";
import { GLOBAL_URL_DOMAIN_LIVE } from "../../utils/Files/url";
import axios from "axios";
import { errorToast, successToast } from "../../utils/Files/toasterMsgs";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { EMAIL_REGEX } from "../../utils/Files/validation";
import { GlobalConstants } from "../../utils/globalconstant";
import Cookie from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import { getRegisterdUser } from "../../redux/RegisteredUserSlice";
import { getAllUserData } from "../../redux/UserListSlice";
const xpath = require("xpath");
const dom = require("xmldom").DOMParser;
function AddUser() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const all_users = useSelector((state) => state.registeredUserList);

  //variable
  const [fName, setFName] = useState("");
  const [lName, setLName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [CountryCode, setCountryCode] = useState("US");
  const [country, setCountry] = useState("India");
  const [errFname, setErrFname] = useState("");
  const [errLname, setErrLname] = useState("");
  const [errEmail, setErrEmail] = useState("");
  const [finalEmail, setFinalEmail] = useState();
  const [companyName, setCompanyName] = useState(
    sessionStorage.getItem("companyName")
  );
  const [passVisibility, setPassVisibility] = useState(false);
  // const [all_users, setAll_Users] = useState();

  //language
  const [text_firstnameval, setText_firstnameval] = useState(
    "*Please Enter First Name!"
  );
  const [text_lastnameval, setText_lastnameval] = useState(
    "*Please Enter Last Name!"
  );
  const [text_emailval, setText_emailval] = useState("*Please Enter Email!");
  const [text_validemailval, setText_validemailval] = useState(
    "*Please Enter Valid Email!"
  );
  const [text_adduser, setText_adduser] = useState("Add User");
  const [text_firstname, setText_firstname] = useState("First Name");
  const [text_Lastname, setText_Lastname] = useState("Last Name");
  const [text_email, setText_email] = useState("Email");
  const [text_enteremail, setText_enteremail] = useState("Enter your Email");
  const [text_enterfirstname, setText_enterfirstname] = useState(
    "Enter Your First Name"
  );
  const [text_enterlastname, setText_enterlastname] = useState(
    "Enter Your Last Name"
  );

  const [text_phoneno, setText_phoneno] = useState("Phone Number");
  const [text_enterphoneno, setText_enterphoneno] =
    useState("Enter Phone Number");
  const [text_country, setText_country] = useState("Country");
  const [text_cancel, setText_cancel] = useState("Cancel");
  const [text_save, setText_save] = useState("Save");

  useEffect(() => {
    try {
      SetLanguageText();
    } catch (err) {
      console.log("" + err);
    }
  }, []);

  useEffect(() => {
    if (all_users?.length === 0) {
      dispatch(getRegisterdUser());
    }
  }, [all_users?.length]);
  //**** get register user *****/
  // const getRegisterdUser = async () => {
  //   try {
  //     let headerConfig = {
  //       headers: {
  //         authorization: "Bearer " + sessionStorage.getItem("userToken"),
  //       },
  //     };

  //     let API_URL = GLOBAL_URL_DOMAIN_LIVE + `/user/registered-users`;

  //     let res = await axios.get(API_URL, headerConfig);
  //     console.log("getRegisterdUser res***", res.data.data);
  //     const userData = res.data.data;
  //     setAll_Users(res.data.data);
  //   } catch (err) {
  //     console.log(err);
  //     if (err.response.status === 427) {
  //       sessionStorage.clear();
  //       localStorage.clear();
  //       Cookie.remove("username");
  //       Cookie.remove("user_id");
  //       Cookie.remove("AdminFName");
  //       Cookie.remove("AdminLName");
  //       Cookie.remove("token");
  //       window.location.replace("/");
  //     }
  //   }
  // };
  const handleFindUsers = all_users?.filter(
    (item) =>
      JSON.stringify(item).toLowerCase().indexOf(email.toLowerCase()) !== -1
  );

  console.log(handleFindUsers);
  //filter register user
  useEffect(() => {
    if (handleFindUsers !== undefined && handleFindUsers.length > 0) {
      if (handleFindUsers[0].email == email) {
        const firstName = handleFindUsers[0].firstName;
        const lastName = handleFindUsers[0].lastName;
        const phonenum = handleFindUsers[0].mobileNumber;
        const countrysel =
          handleFindUsers[0].country === undefined
            ? ""
            : handleFindUsers[0].country;

        console.log(phonenum);
        setFinalEmail(email.length);
        setFName(firstName);
        setLName(lastName);
        setCountry(countrysel);
        setPhone(phonenum);
        const key = Object.keys(countriesList).find(
          (key) => countriesList[key] === countrysel
        );
        setCountryCode(key);
        // setCountyData(key);
      }
    } else if (handleFindUsers !== undefined && handleFindUsers.length > 0) {
      // if (handleFindUsers[0].email !== email && email !== "") {
      if (finalEmail.l !== email.length && finalEmail !== 0) {
        console.log("hiiiii");
        setFName("");
        setLName("");
        setCountry("");
        setPhone("");
        setCountryCode("US");
      }
    }
  }, [handleFindUsers]);

  const setCountyData = (d) => {
    var countryName = countriesList[d];
    setCountry(countryName);
    setCountryCode(d);
  };

  //validation
  const validation = () => {
    let AdduserValidation = true;
    if (fName === "") {
      AdduserValidation = false;
      setErrFname(<>{text_firstnameval}</>);
    }
    if (lName === "") {
      AdduserValidation = false;
      setErrLname(<>{text_lastnameval}</>);
    }
    if (!EMAIL_REGEX.test(email) === true) {
      AdduserValidation = false;
      if (email === "") {
        AdduserValidation = false;
        setErrEmail(<>{text_emailval}</>);
      } else {
        setErrEmail(<>{text_validemailval}</>);
      }
    }
    return AdduserValidation;
  };
  //add user
  const handleAddUser = () => {
    if (validation()) {
      let headerConfig = {
        headers: {
          authorization: "Bearer " + sessionStorage.getItem("userToken"),
        },
      };

      var API_URL = GLOBAL_URL_DOMAIN_LIVE + `/user/add-user`;

      var data = {
        firstName: fName,
        lastName: lName,
        email: email,
        mobileNumber: phone,
        country: country,
        isEmail: email === "" ? false : true,
        isOrg: false,
        companyName: companyName,
        companyId: sessionStorage.getItem("companyID"),
        role: sessionStorage.getItem("role"),
      };
      axios
        .post(API_URL, data, headerConfig)
        .then((res) => {
          var val = res?.data?.data;
          console.log("userEmail:-", res);
          console.log("userPhoneno:-", val.mobileNumber);
          successToast(res?.data?.message);
          dispatch(getAllUserData());
          navigate("/selectbucket");
        })
        .catch((err) => {
          console.log("get data ipfs err***", err);
          errorToast(err?.response?.data?.message);
          // setEmailexits(err.response.data.message);
        })
        .then(function () {});
    }
  };

  const SetLanguageText = () => {
    try {
      var xml = localStorage.getItem(GlobalConstants.session_lang_xml);
      var doc = new dom().parseFromString(xml);
      setText_firstnameval(
        xpath.select1("/resources/string[@name='text_firstnameval']", doc)
          .firstChild.data
      );
      setText_lastnameval(
        xpath.select1("/resources/string[@name='text_lastnameval']", doc)
          .firstChild.data
      );
      setText_emailval(
        xpath.select1("/resources/string[@name='text_emailval']", doc)
          .firstChild.data
      );
      setText_validemailval(
        xpath.select1("/resources/string[@name='text_validemailval']", doc)
          .firstChild.data
      );
      setText_adduser(
        xpath.select1("/resources/string[@name='text_adduser']", doc).firstChild
          .data
      );
      setText_firstname(
        xpath.select1("/resources/string[@name='text_firstname']", doc)
          .firstChild.data
      );
      setText_Lastname(
        xpath.select1("/resources/string[@name='text_Lastname']", doc)
          .firstChild.data
      );
      // setText_companyname(
      //   xpath.select1("/resources/string[@name='text_companyname']", doc)
      //     .firstChild.data
      // );
      setText_email(
        xpath.select1("/resources/string[@name='text_email']", doc).firstChild
          .data
      );
      setText_enteremail(
        xpath.select1("/resources/string[@name='text_enteremail']", doc)
          .firstChild.data
      );
      setText_phoneno(
        xpath.select1("/resources/string[@name='text_phoneno']", doc).firstChild
          .data
      );
      setText_enterphoneno(
        xpath.select1("/resources/string[@name='text_enterphoneno']", doc)
          .firstChild.data
      );
      setText_country(
        xpath.select1("/resources/string[@name='text_country']", doc).firstChild
          .data
      );
      setText_cancel(
        xpath.select1("/resources/string[@name='text_cancel']", doc).firstChild
          .data
      );
      setText_save(
        xpath.select1("/resources/string[@name='text_save']", doc).firstChild
          .data
      );
      setText_enterfirstname(
        xpath.select1("/resources/string[@name='text_enterfirstname']", doc)
          .firstChild.data
      );
      setText_enterlastname(
        xpath.select1("/resources/string[@name='text_enterlastname']", doc)
          .firstChild.data
      );
      // setText_enteryourorgname(
      //   xpath.select1("/resources/string[@name='text_enteryourorgname']", doc)
      //     .firstChild.data
      // );
    } catch (ert) {}
  };
  return (
    <>
      <Header />
      <div className="set-mm"></div>
      <div className="adduser-main-container">
        <div className="adduser-container">
          <div className="login-title-text p-0 uf-title">
            {text_adduser} To {companyName}
          </div>
          <hr className="adduser-hr-new" />
          <div className="row justify-content-center">
            <div className="col-md-4">
              <div className="w-100 ">
                <label className="login-labels reg-labels">{text_email}</label>
                <div className="inputForm reg-inputForm" id="adduser-country">
                  <img src={EmailImg} alt="" className="login-emailphone" />
                  <input
                    type="email"
                    placeholder={text_enteremail}
                    className="input login-input reg-input"
                    onChange={(e) => [
                      setEmail(e.target.value.toLowerCase()),
                      setErrEmail(""),
                      // handleFindUsers(),
                      // all_users.length > 0 && handleFindUsers(),
                    ]}
                    value={email}
                  />
                </div>
                <p className="errMsg">{errEmail}</p>
              </div>
            </div>
          </div>
          <div className="row  justify-content-center">
            <div className="col-md-3">
              <div className="w-100 ">
                <label className="login-labels reg-labels">
                  {text_firstname}
                </label>
                <div className="inputForm reg-inputForm" id="adduser-country">
                  <img src={UserImg} alt="" className="login-emailphone" />
                  <input
                    type="text"
                    placeholder={text_enterfirstname}
                    className="input login-input reg-input"
                    onChange={(e) => [
                      setFName(e.target.value),
                      setErrFname(""),
                    ]}
                    value={fName}
                  />
                </div>
                <p className="errMsg">{errFname}</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="w-100 ">
                <label className="login-labels reg-labels">
                  {text_Lastname}
                </label>
                <div className="inputForm reg-inputForm" id="adduser-country">
                  <img src={UserImg} alt="" className="login-emailphone" />
                  <input
                    type="text"
                    placeholder={text_enterlastname}
                    className="input login-input"
                    onChange={(e) => [
                      setLName(e.target.value),
                      setErrLname(""),
                    ]}
                    value={lName}
                  />
                </div>
                <p className="errMsg">{errLname}</p>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-md-3">
              <div className="w-100  " id="add-country-margin">
                <label className="login-labels reg-labels">
                  {text_country}
                </label>
                <div className="inputForm reg-inputForm" id="adduser-country">
                  <img src={CountryImg} alt="" className="login-emailphone" />

                  <ReactFlagsSelect
                    className="input login-input login-input-2 reg-input-2 "
                    selected={CountryCode}
                    onSelect={(code) => [setCountyData(code)]}
                    searchable={true}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="w-100">
                <label className="login-labels reg-labels">
                  {text_phoneno}
                </label>
                <div className="inputForm reg-inputForm" id="adduser-country">
                  <PhoneInput
                    onChange={(e) => setPhone(e)}
                    inputStyle={{
                      background: "transparent",
                      height: "40px",
                      width: "300px",
                      border: "none",
                    }}
                    inputClass="Register-input input"
                    placeholder={text_enterphoneno}
                    value={phone}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-center gap-4 mt-3">
            <button onClick={() => navigate(-1)} className="box-btn">
              {text_cancel}
            </button>
            <button className="box-btn-save" onClick={handleAddUser}>
              {text_save}
            </button>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}

export default AddUser;
