import { createSlice } from "@reduxjs/toolkit";
import { GLOBAL_URL_DOMAIN_LIVE } from "../utils/Files/url";
import axios from "axios";
import Cookie from "js-cookie";

const IPFSRootFolderSlice = createSlice({
  name: "IPFS Root Data",
  initialState: {
    folderData: [],
    fileData: [],
    isLoadingFolder: false,
  },
  reducers: {
    setRootFolderData(state, action) {
      console.log("**********", action.payload);
      return { ...state, folderData: action.payload };
    },
    setData(state, action) {
      return { ...state, fileData: action.payload };
    },
    ipfsRootFolderLoader(state, action) {
      return { ...state, isLoadingFolder: action.payload };
    },
  },
});
const { setRootFolderData, setData, ipfsRootFolderLoader } =
  IPFSRootFolderSlice.actions;
export default IPFSRootFolderSlice.reducer;
var userID = sessionStorage.getItem("userID");

export function getIPFSRootData(Folder_name) {
  return async function getIPFSRootDataThunk(dispatch, getstate) {
    try {
      console.log("***********", Folder_name);
      // setLoaderData(true);
      dispatch(ipfsRootFolderLoader(true));
      var MainFolderData = {
        companyId:
          sessionStorage.getItem("companyID") === null ||
          sessionStorage.getItem("companyID") === undefined
            ? ""
            : sessionStorage.getItem("companyID"),
        userId: sessionStorage.getItem("userID"),
        folderName: `atom-storage/${sessionStorage.getItem("userID")}/`,
        bucketId: "6e2f3b90-1dd9-4b7c-9be8-904bd4801e55",
      };
      let headerConfig = {
        headers: {
          authorization: "Bearer " + sessionStorage.getItem("userToken"),
        },
      };
      var API_URL =
        GLOBAL_URL_DOMAIN_LIVE + "/ipfs-folder/all-child-folders-ipfs";

      axios
        .post(API_URL, MainFolderData, headerConfig)
        .then((res) => {
          console.log("add_folder_IPFS res***", res);
          const sortedData = res?.data?.data?.sort((a, b) => {
            // Assuming you want to sort by a property called "createdAt"
            return new Date(b.createdAt) - new Date(a.createdAt);
          });
          dispatch(setRootFolderData(sortedData));
          console.log(sortedData);
          dispatch(ipfsRootFolderLoader(false));
          // setLoaderData(false);
          // setLeftFolders(sortedData);
          // getFileData(
          //   `atom-storage/${userID}/`,
          //   "6e2f3b90-1dd9-4b7c-9be8-904bd4801e55"
          // );
        })
        .catch((err) => {
          console.log("add_folder_id_func err***", err);
          // setLoaderData(false);
          dispatch(ipfsRootFolderLoader(false));

          if (err?.response?.status === 427) {
            sessionStorage.clear();
            localStorage.clear();
            Cookie.remove("fName");
            Cookie.remove("lName");
            Cookie.remove("userID");
            Cookie.remove("userToken");
            Cookie.remove("token_start_time");
            window.location.replace("/");
            //   navigate("/");
          }
        })
        .then(function () {});
    } catch (err) {
      console.log(err);
    }
  };
}
export function getIPFSFileData(folder_name, bucket_id) {
  return async function getIPFSFileDataThunk(dispatch, getstate) {
    try {
      dispatch(ipfsRootFolderLoader(true));
      console.log(folder_name, bucket_id);
      var Folder_name_Final =
        folder_name === ""
          ? `atom-storage/${sessionStorage.getItem("userID")}/`
          : folder_name;
      console.log(Folder_name_Final);

      let headerConfig = {
        headers: {
          authorization: "Bearer " + sessionStorage.getItem("userToken"),
        },
      };

      var API_URL =
        GLOBAL_URL_DOMAIN_LIVE +
        `/file/filedata-ipfs-folderwise?userId=${sessionStorage.getItem(
          "userID"
        )}&folderName=${Folder_name_Final}&bucketId_ipfs=${bucket_id}`;

      axios
        .get(API_URL, headerConfig)
        .then((res) => {
          console.log("get data ipfs res***", res);
          const sortedData = res?.data?.data?.sort((a, b) => {
            // Assuming you want to sort by a property called "createdAt"
            return new Date(b.createdAt) - new Date(a.createdAt);
          });
          console.log(sortedData);
          dispatch(ipfsRootFolderLoader(false));
          //   setLoaderData(false);
          if (sortedData.length > 0) {
            dispatch(setData(sortedData));
          } else {
            dispatch(setData([]));
          }
        })
        .catch((err) => {
          console.log("get data ipfs err***", err);
          dispatch(ipfsRootFolderLoader(false));
          //   setLoader(false);
          //   setLoaderData(false);
          if (err?.response?.status === 427) {
            sessionStorage.clear();
            localStorage.clear();
            Cookie.remove("fName");
            Cookie.remove("lName");
            Cookie.remove("userID");
            Cookie.remove("userToken");
            Cookie.remove("token_start_time");
            window.location.replace("/");
            // navigate("/");
          }
        })
        .then(function () {});
    } catch (err) {
      console.log(err);
    }
  };
}

export function resetIPFSrootfileData() {
  return async function resetIPFSrootfileDataThunk(dispatch, getState) {
    try {
      dispatch(setRootFolderData([]));
      dispatch(setData([]));
      dispatch(ipfsRootFolderLoader(false));
    } catch (err) {
      console.log(err);
    }
  };
}
