import React, { useState, useEffect, useMemo } from "react";
import "./Settings.css";
import Header from "../../Components/Header/Header";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  FormControlLabel,
  Switch,
  Typography,
} from "@mui/material";
import axios from "axios";
import { GLOBAL_URL_DOMAIN_LIVE } from "../../utils/Files/url";
import DataTable from "react-data-table-component";
import FilterComponent from "../../utils/Files/FilterComponent";
import { customTableStyles } from "../../utils/Files/tableStyle";
import { CgCloseO } from "react-icons/cg";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import moment from "moment/moment";
import { useNavigate } from "react-router-dom";
import Cookie from "js-cookie";
import { GlobalConstants } from "../../utils/globalconstant";
const xpath = require("xpath");
const dom = require("xmldom").DOMParser;
const Settings = () => {
  //variable
  const [apiKey, setApiKey] = useState("");
  const userID = sessionStorage.getItem("userID");
  const userEmail = sessionStorage.getItem("email");
  const userMobile = sessionStorage.getItem("mobile");
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [popup, setPopup] = useState(false);
  const [admin, setAdmin] = useState(false);
  const [hashMetaData, setHashMetaData] = useState(false);
  const [pinByHash, setPinByHash] = useState(false);
  const [pinFileToIPFS, setPinFileToIPFS] = useState(false);
  const [pinJobs, setPinJobs] = useState(false);
  const [pinJSONToIPFS, setPinJSONToIPFS] = useState(false);
  const [unpin, setUnpin] = useState(false);
  const [pinList, setPinList] = useState(false);
  const [userPinnedDataTotal, setUserPinnedDataTotal] = useState(false);
  const [addPinObject, setAddPinObject] = useState(false);
  const [getPinObject, setGetPinObject] = useState(false);
  const [removePinObject, setRemovePinObject] = useState(false);
  const [replacePinObject, setReplacePinObject] = useState(false);
  const [limitMaxUse, setLimitMaxUse] = useState(0);
  const [maxUsesCount, setMaxUsesCount] = useState(false);
  const [KeyName, setKeyName] = useState("");
  const [keyError, setKeyError] = useState("");
  const [expanded, setExpanded] = useState(false);
  // language starts here //
  const [text_srno, setText_srno] = useState("SR NO.");
  const [text_createkey, setText_createkey] = useState("Create Key");
  const [text_keyname, setText_keyname] = useState("Key Name");
  const [text_key, setText_key] = useState("Key");
  const [text_dateissued, setText_dateissued] = useState("DATE ISSUED");
  const [text_permissions, setText_permissions] = useState("PERMISSIONS");
  const [text_searchhere, setText_searchhere] = useState("Search Here...");
  const [text_apikey, setText_apikey] = useState("API Key");
  const [text_aes, setText_aes] = useState("AES-256");
  const [text_newkey, setText_newkey] = useState("+ New Key");
  const [text_createnewapikey, setText_createnewapikey] =
    useState("Create New API Key");
  const [text_admin, setText_admin] = useState("Admin");
  const [text_pinning, setText_pinning] = useState("Pinning");
  const [text_hashmetadata, setText_hashmetadata] = useState("hashMetadata");
  const [text_pinbyhash, setText_pinbyhash] = useState("pinByHash");
  const [text_pinfiletoipfs, setText_pinfiletoipfs] = useState("pinFileToIPFS");
  const [text_pinjobs, setText_pinjobs] = useState("pinJobs");
  const [text_pinjsontoipfs, setText_pinjsontoipfs] = useState("pinJSONToIPFS");
  const [text_unpin, setText_unpin] = useState("unpin");
  const [text_data, setText_data] = useState("Data");
  const [text_pinlist, setText_pinlist] = useState("pinList");
  const [text_userpinneddata, setText_userpinneddata] = useState(
    "userPinnedDataTotal"
  );
  const [text_pinningserviceapi, setText_pinningserviceapi] = useState(
    "Pinning Services API"
  );
  const [text_maxuses, setText_maxuses] = useState("MAX USES");
  const [text_notice, setText_notice] = useState(
    "When enabled, you can set the maximum number of times a key can be used"
  );
  const [text_limitmaxuses, setText_limitmaxuses] = useState("Limit Max Uses");
  const [text_pins, setText_pins] = useState("Pins");
  const [text_noticesettings, setText_noticesettings] = useState(
    "Admin Keys have access to all endpoints and account settings"
  );
  const [text_removepinobject, setText_removepinobject] =
    useState("removePinObject");
  const [text_addpinobject, setText_addpinobject] = useState("addPinObject");
  const [text_getpinobject, setText_getpinobject] = useState("getPinObject");
  const [text_apiendpointaccess, setText_apiendpointaccess] = useState(
    "API Endpoint Access"
  );
  const [text_replace_pin, setText_replace_pin] = useState("replacePinObject");
  const [text_revoke, setText_revoke] = useState("Revoke");
  // language end here //
  const navigate = useNavigate();
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    let headerConfig = {
      headers: {
        authorization: "Bearer " + sessionStorage.getItem("userToken"),
      },
    };

    var API_URL =
      GLOBAL_URL_DOMAIN_LIVE +
      `/user/user-apikey?userId=${sessionStorage.getItem("userID")}`;

    axios
      .get(API_URL, headerConfig)
      .then((res) => {
        console.log("get user apikey res***", res);
        setApiKey(res?.data?.data);
      })
      .catch((err) => {
        console.log("get user apikey err***", err);
        if (err?.response?.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          Cookie.remove("fName");
          Cookie.remove("lName");
          Cookie.remove("userID");
          Cookie.remove("userToken");
          Cookie.remove("token_start_time");
          navigate("/");
        }
      })
      .then(function () {});
  }, []);
  //**** Get Key  Data****/
  const getApiKeyData = () => {
    let headerConfig = {
      headers: {
        authorization: "Bearer " + sessionStorage.getItem("userToken"),
      },
    };

    var API_URL =
      GLOBAL_URL_DOMAIN_LIVE +
      `/user-apikey/get-apikeys?userID=${sessionStorage.getItem("userID")}`;

    axios
      .get(API_URL, headerConfig)
      .then((res) => {
        console.log("get user apikey res***", res);
        setKeyData(res?.data?.data);
      })
      .catch((err) => {
        console.log("get user apikey err***", err);
      })
      .then(function () {});
  };
  useEffect(() => {
    getApiKeyData();
  }, []);
  //**** Create Api Key ****/
  const CreateApiKey = () => {
    let headerConfig = {
      headers: {
        authorization: "Bearer " + sessionStorage.getItem("userToken"),
      },
    };

    var API_URL = GLOBAL_URL_DOMAIN_LIVE + `/user-apikey/create-new-apikey`;
    var data = {
      userID: userID,
      email: userEmail,
      mobileNumber: userMobile,
      isActive: true,
      isAdmin: admin,
      isHashMetadata: hashMetaData,
      isPinByHash: pinByHash,
      isPinFileToIpfs: pinFileToIPFS,
      isPinJobs: pinJobs,
      isPinJsonToIpfs: pinJSONToIPFS,
      isUnpin: unpin,
      isPinList: pinList,
      isUserPinnedDataTotal: userPinnedDataTotal,
      isAddPinObject: addPinObject,
      isGetPinObject: getPinObject,
      isRemovePinObject: removePinObject,
      isReplacePinObject: replacePinObject,
      isLimitMaxUses: limitMaxUse,
      maxUses: maxUsesCount,
      keyName: KeyName,
    };
    axios
      .post(API_URL, data, headerConfig)
      .then((res) => {
        console.log("Create Api Key res***", res);
        if (res?.data?.success === true) {
          setPopup(false);
          getApiKeyData();
        }
      })
      .catch((err) => {
        console.log("Create Api Key err***", err);
        console.log(err?.response?.data?.message);
        setKeyError(err?.response?.data?.message);
        if (err?.response?.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          Cookie.remove("fName");
          Cookie.remove("lName");
          Cookie.remove("userID");
          Cookie.remove("userToken");
          Cookie.remove("token_start_time");
          navigate("/");
        }
      });
  };

  //**** Delete Api Key ****/
  const DeleteApiKey = (keyid) => {
    let headerConfig = {
      headers: {
        authorization: "Bearer " + sessionStorage.getItem("userToken"),
      },
    };

    var API_URL =
      GLOBAL_URL_DOMAIN_LIVE + `/user-apikey/delete-apikey?apikeyID=${keyid}`;

    axios
      .delete(API_URL, headerConfig)
      .then((res) => {
        console.log("Delete Api Key res***", res);
        if (res?.data?.success === true) {
          getApiKeyData();
        }
      })
      .catch((err) => {
        console.log("delete Api Key err***", err);
        console.log(err?.response?.data?.message);
        if (err?.response?.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          Cookie.remove("fName");
          Cookie.remove("lName");
          Cookie.remove("userID");
          Cookie.remove("userToken");
          Cookie.remove("token_start_time");
          navigate("/");
        }
      });
  };
  const [keyData, setKeyData] = useState([]);
  const columns = [
    {
      name: <>{text_srno}</>,
      sortable: true,
      selector: (row, index) => <div className="yf-srno">{index + 1}</div>,
      width: "100px",
    },
    {
      name: <>{text_keyname}</>,
      selector: (row) => row?.keyName,
    },
    {
      name: <>{text_key}</>,
      selector: (row, index) => row?.apiKey,
    },
    {
      name: <>{text_dateissued}</>,
      selector: (row, index) => moment(row?.createdAt).format("DD MMM ,yyyy"),
    },
    {
      name: <>{text_maxuses}</>,
      selector: (row) => row?.maxUses,
    },
    {
      name: <>{text_permissions}</>,
      selector: (row) => (
        <>{row?.isAdmin === true ? <>Admin</> : <>Custom</>}</>
      ),
    },
    {
      name: "",
      selector: (row, index) => (
        <button className="revoke-btn" onClick={() => DeleteApiKey(row?._id)}>
          <DeleteOutlineIcon
            className="yf-ed-icon"
            style={{ height: "22px" }}
          />
          <span className="yf-download ">{text_revoke}</span>
        </button>
      ),
    },
  ];
  useEffect(() => {
    try {
      SetLanguageText();
    } catch (err) {
      console.log("" + err);
    }
  }, []);
  const SetLanguageText = () => {
    try {
      var xml = localStorage.getItem(GlobalConstants.session_lang_xml);
      var doc = new dom().parseFromString(xml);
      setText_srno(
        xpath.select1("/resources/string[@name='text_srno']", doc).firstChild
          .data
      );
      setText_createkey(
        xpath.select1("/resources/string[@name='text_createkey']", doc)
          .firstChild.data
      );
      setText_keyname(
        xpath.select1("/resources/string[@name='text_keyname']", doc).firstChild
          .data
      );
      setText_newkey(
        xpath.select1("/resources/string[@name='text_newkey']", doc).firstChild
          .data
      );
      setText_apikey(
        xpath.select1("/resources/string[@name='text_apikey']", doc).firstChild
          .data
      );
      setText_createnewapikey(
        xpath.select1("/resources/string[@name='text_createnewapikey']", doc)
          .firstChild.data
      );
      setText_key(
        xpath.select1("/resources/string[@name='text_key']", doc).firstChild
          .data
      );
      setText_dateissued(
        xpath.select1("/resources/string[@name='text_dateissued']", doc)
          .firstChild.data
      );
      setText_permissions(
        xpath.select1("/resources/string[@name='text_permissions']", doc)
          .firstChild.data
      );
      setText_searchhere(
        xpath.select1("/resources/string[@name='text_searchhere']", doc)
          .firstChild.data
      );
      setText_admin(
        xpath.select1("/resources/string[@name='text_admin']", doc).firstChild
          .data
      );
      setText_pinning(
        xpath.select1("/resources/string[@name='text_pinning']", doc).firstChild
          .data
      );
      setText_hashmetadata(
        xpath.select1("/resources/string[@name='text_hashmetadata']", doc)
          .firstChild.data
      );
      setText_pinbyhash(
        xpath.select1("/resources/string[@name='text_pinbyhash']", doc)
          .firstChild.data
      );
      setText_pinfiletoipfs(
        xpath.select1("/resources/string[@name='text_pinfiletoipfs']", doc)
          .firstChild.data
      );
      setText_pinjobs(
        xpath.select1("/resources/string[@name='text_pinjobs']", doc).firstChild
          .data
      );
      setText_pinjsontoipfs(
        xpath.select1("/resources/string[@name='text_pinjsontoipfs']", doc)
          .firstChild.data
      );
      setText_unpin(
        xpath.select1("/resources/string[@name='text_unpin']", doc).firstChild
          .data
      );
      setText_data(
        xpath.select1("/resources/string[@name='text_data']", doc).firstChild
          .data
      );
      setText_pinlist(
        xpath.select1("/resources/string[@name='text_pinlist']", doc).firstChild
          .data
      );
      setText_userpinneddata(
        xpath.select1("/resources/string[@name='text_userpinneddata']", doc)
          .firstChild.data
      );
      setText_pinningserviceapi(
        xpath.select1("/resources/string[@name='text_pinningserviceapi']", doc)
          .firstChild.data
      );
      setText_maxuses(
        xpath.select1("/resources/string[@name='text_maxuses']", doc).firstChild
          .data
      );
      setText_notice(
        xpath.select1("/resources/string[@name='text_notice']", doc).firstChild
          .data
      );
      setText_limitmaxuses(
        xpath.select1("/resources/string[@name='text_limitmaxuses']", doc)
          .firstChild.data
      );
      setText_removepinobject(
        xpath.select1("/resources/string[@name='text_removepinobject']", doc)
          .firstChild.data
      );
      setText_addpinobject(
        xpath.select1("/resources/string[@name='text_addpinobject']", doc)
          .firstChild.data
      );
      setText_getpinobject(
        xpath.select1("/resources/string[@name='text_getpinobject']", doc)
          .firstChild.data
      );
      setText_pins(
        xpath.select1("/resources/string[@name='text_pins']", doc).firstChild
          .data
      );
      setText_noticesettings(
        xpath.select1("/resources/string[@name='text_noticesettings']", doc)
          .firstChild.data
      );
      setText_apiendpointaccess(
        xpath.select1("/resources/string[@name='text_apiendpointaccess']", doc)
          .firstChild.data
      );
      setText_replace_pin(
        xpath.select1("/resources/string[@name='text_replace_pin']", doc)
          .firstChild.data
      );
      setText_revoke(
        xpath.select1("/resources/string[@name='text_revoke']", doc).firstChild
          .data
      );
      setText_aes(
        xpath.select1("/resources/string[@name='text_aes']", doc).firstChild
          .data
      );
    } catch (ert) {}
  };
  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <>
        <div className="row">
          <div className="col-md-6"></div>
          <div className="text-end col-md-6 mb-2">
            <FilterComponent
              onFilter={(e) => setFilterText(e.target.value)}
              onClear={handleClear}
              PlaceHolder={text_searchhere}
              filterText={filterText}
              className="fc-input"
              clearClass="fc-x"
            />
          </div>
        </div>
      </>
    );
  }, [filterText, resetPaginationToggle, text_searchhere]);

  const closePopup = () => {
    setPopup(false);
  };

  const filteredItems = keyData.filter(
    (item) =>
      JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !==
      -1
  );
  return (
    <div className="set-main">
      <Header />
      <div className="set-mm"></div>
      <div className={popup === true ? "set bgblur" : "set"}>
        <div className="set-1">
          <div className="d-flex justify-content-between">
            <div className="set-1-title">{text_apikey}</div>
            <div className="d-flex gap-4">
              <button
                className="new-key-btn mt-5 newkeyBtn"
                onClick={() => setPopup(!popup)}
              >
                {text_newkey}
              </button>
            </div>
          </div>
          <div className="yf-ipfs-main">
            {subHeaderComponent}
            <DataTable
              columns={columns}
              data={filteredItems}
              pagination
              fixedHeader
              selectableRowsHighlight
              highlightOnHover
              customStyles={customTableStyles}
            />
          </div>
        </div>
      </div>
      {popup ? (
        <>
          <div className="popupSty">
            <div className="p-4">
              <div className="d-flex justify-content-between">
                <h5 className="mt-1">{text_createnewapikey}</h5>
                <h3>
                  <CgCloseO onClick={closePopup} />
                </h3>
              </div>
              <Divider />
              <div className="row mt-2 mb-2">
                <div className="col-md-6">
                  <p>{text_admin}</p>
                </div>
                <div className="col-md-6">
                  <FormControlLabel
                    control={<Switch onChange={() => setAdmin(!admin)} />}
                    label={text_noticesettings}
                  />
                </div>
              </div>
              <Divider />
              <div className="row mt-2 mb-2">
                <div className="col-md-6">
                  <p>{text_apiendpointaccess}</p>
                </div>
                <div className="col-md-6">
                  <Accordion
                    expanded={expanded === "panel1"}
                    sx={{ margin: "0 !important" }}
                    onChange={handleChange("panel1")}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                    >
                      <Typography sx={{ width: "33%", flexShrink: 0 }}>
                        {text_pinning}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails
                      sx={{ padding: "0px 16px 16px !important" }}
                    >
                      <Typography>
                        <FormControlLabel
                          control={
                            <Switch
                              onChange={() => setHashMetaData(!hashMetaData)}
                            />
                          }
                          label={text_hashmetadata}
                        />
                        <br />
                        <FormControlLabel
                          control={
                            <Switch onChange={() => setPinByHash(!pinByHash)} />
                          }
                          label={text_pinbyhash}
                        />
                        <br />
                        <FormControlLabel
                          control={
                            <Switch
                              onChange={() => setPinFileToIPFS(!pinFileToIPFS)}
                            />
                          }
                          label={text_pinfiletoipfs}
                        />
                        <br />
                        <FormControlLabel
                          control={
                            <Switch onChange={() => setPinJobs(!pinJobs)} />
                          }
                          label={text_pinjobs}
                        />
                        <br />
                        <FormControlLabel
                          control={
                            <Switch
                              onChange={() => setPinJSONToIPFS(!pinJSONToIPFS)}
                            />
                          }
                          label={text_pinjsontoipfs}
                        />
                        <br />
                        <FormControlLabel
                          control={<Switch onChange={() => setUnpin(!unpin)} />}
                          label={text_unpin}
                        />
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    expanded={expanded === "panel2"}
                    onChange={handleChange("panel2")}
                    sx={{ margin: "0 !important" }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2bh-content"
                      id="panel2bh-header"
                    >
                      <Typography sx={{ width: "33%", flexShrink: 0 }}>
                        {text_data}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <FormControlLabel
                          control={
                            <Switch onChange={() => setPinList(!pinList)} />
                          }
                          label={text_pinlist}
                        />

                        <FormControlLabel
                          control={
                            <Switch
                              onChange={() =>
                                setUserPinnedDataTotal(!userPinnedDataTotal)
                              }
                            />
                          }
                          label={text_userpinneddata}
                        />
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    expanded={expanded === "panel3"}
                    onChange={handleChange("panel3")}
                    sx={{ margin: "0 !important" }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel3bh-content"
                      id="panel3bh-header"
                    >
                      <Typography sx={{ width: "60%", flexShrink: 0 }}>
                        {text_pinningserviceapi}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails
                      sx={{ padding: "0px 16px 16px !important" }}
                    >
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel4a-content"
                          id="panel4a-header"
                        >
                          <Typography>{text_pins}</Typography>
                        </AccordionSummary>
                        <AccordionDetails
                          sx={{ padding: "0px 16px 16px !important" }}
                        >
                          <Typography>
                            <FormControlLabel
                              control={
                                <Switch
                                  onChange={() =>
                                    setAddPinObject(!addPinObject)
                                  }
                                />
                              }
                              label={text_addpinobject}
                            />
                            <FormControlLabel
                              control={
                                <Switch
                                  onChange={() =>
                                    setGetPinObject(!getPinObject)
                                  }
                                />
                              }
                              label={text_getpinobject}
                            />
                            <FormControlLabel
                              control={
                                <Switch
                                  onChange={() =>
                                    setRemovePinObject(!removePinObject)
                                  }
                                />
                              }
                              label={text_removepinobject}
                            />
                            <FormControlLabel
                              control={
                                <Switch
                                  onChange={() =>
                                    setReplacePinObject(!replacePinObject)
                                  }
                                />
                              }
                              label={text_replace_pin}
                            />
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                    </AccordionDetails>
                  </Accordion>
                </div>
              </div>
              <Divider />
              <div className="row mt-2">
                <div className="col-md-6">
                  <h6>{text_limitmaxuses}</h6>
                </div>
                <div className="col-md-6">
                  <FormControlLabel
                    control={
                      <Switch onChange={() => setLimitMaxUse(!limitMaxUse)} />
                    }
                    value=""
                    label={text_notice}
                  />
                  {limitMaxUse ? (
                    <>
                      <Box
                        component="form"
                        sx={{
                          "& .MuiTextField-root": { m: 1, width: "25ch" },
                        }}
                        noValidate
                        autoComplete="off"
                      >
                        <div>
                          <TextField
                            id="outlined-number"
                            label={text_maxuses}
                            type="number"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            onChange={(e) => setMaxUsesCount(e.target.value)}
                          />
                        </div>
                      </Box>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <p>{text_keyname}</p>
                </div>
                <div className="col-md-6">
                  <Box
                    component="form"
                    sx={{
                      "& > :not(style)": { m: 1, width: "25ch" },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <div className="w-100">
                      <TextField
                        id="outlined-basic"
                        label={text_keyname}
                        variant="outlined"
                        onChange={(e) => [
                          setKeyName(e.target.value),
                          setKeyError(""),
                        ]}
                      />
                    </div>
                  </Box>
                </div>
              </div>
              <p className="errMsg text-center">{keyError}</p>
              <div className="mt-3 justify-content-center d-flex">
                <button className="keyBtn" onClick={CreateApiKey}>
                  {text_createkey}
                </button>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
     <div className="container mt-5 p-0 set">
        <div className="d-flex">
          <div className="set-1-title mt-0 " style={{ color: "#009087" }}>
            {text_aes} {text_key} :-
          </div>
          <div className="d-flex align-items-center mb-3">
            <div className="input-group w-100 mt-2 mx-2">
              <input
                className="form-control"
                type="text"
                value="*************************************"
                readonly
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
