import React from "react";
import "./Loader2.css";

const Loader2 = () => {
  return (
    <div>
      <svg viewBox="25 25 50 50" id="loader2-main">
        <circle r="20" cy="50" cx="50" id="loader2-circle"></circle>
      </svg>
    </div>
  );
};

export default Loader2;
