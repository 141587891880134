import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import Cookie from "js-cookie";
import { GLOBAL_URL_DOMAIN_LIVE } from "../utils/Files/url";
import { errorToast } from "../utils/Files/toasterMsgs";

const CompanyProfileSlice = createSlice({
  name: "Company Data",
  initialState: [],
  reducers: {
    setCompData(state, action) {
      return (state = action.payload);
    },
  },
});

const { setCompData, setComNameEdit, setProfileImgUrl } =
  CompanyProfileSlice.actions;
export default CompanyProfileSlice.reducer;

///****** get company data */
export function getCompanyData() {
  return async function getCompanyDataThunk(dispatch, getstate) {
    try {
      var API_URL =
        GLOBAL_URL_DOMAIN_LIVE +
        `/company/company-data?c_id=${sessionStorage.getItem(
          "companyID"
        )}&u_id=${sessionStorage.getItem("userID")}`;

      let headerConfig = {
        headers: {
          authorization: "Bearer " + sessionStorage.getItem("userToken"),
        },
      };

      axios
        .get(API_URL, headerConfig)
        .then(function (response) {
          var data = response?.data?.data;
          console.log("profile data", data);
          dispatch(setCompData(data));
          //   dispatch(setComNameEdit(data?.companyName));
        })
        .catch(function (error) {
          console.log(error);
          // errorToast(err);
          if (error?.response?.status === 427) {
            sessionStorage.clear();
            localStorage.clear();
            Cookie.remove("fName");
            Cookie.remove("lName");
            Cookie.remove("userID");
            Cookie.remove("userToken");
            Cookie.remove("token_start_time");
            window.location.replace("/");
          }
          errorToast(error?.response?.data?.message);
        })
        .then(function () {});
    } catch (err) {
      console.log(err);
    }
  };
}
export function resetgetCompanyData() {
  return async function resetgetCompanyDataThunk(dispatch, getState) {
    try {
      dispatch(setCompData([]));
    } catch (err) {
      console.log(err);
    }
  };
}
