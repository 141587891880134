import React from "react";
import styled from "styled-components";

const Input = styled.input.attrs((props) => ({
  type: "text",
  size: props.small ? 5 : undefined,
}))``;

const ClearButton = styled.button``;

const FilterComponent = ({ filterText,PlaceHolder, onFilter, onClear ,className,clearClass}) => (
  <>
    <Input
      id="search"
      type="text"
      placeholder={PlaceHolder}
      value={filterText}
      onChange={onFilter}
      className={className}
    />
    <ClearButton onClick={onClear} className={clearClass}>
      X
    </ClearButton>
  </>
);

export default FilterComponent;
