import React, { useState } from "react";
import ProgressBoxContext from "./ProgressBoxContext";

const ProgressBoxContextProvider = ({ children }) => {
  //variable
  const [progressData_aws, setProgressData_aws] = useState(0);
  const [progressData_Server, setProgressData_Server] = useState(0);
  const [message, setMessage] = useState("");
  const [fileName, setFileName] = useState("");
  const [uploadLocation, setuploadLocation] = useState("");
  const [progressStatus, setProgressStatus] = useState("");
  const [progress, setProgress] = useState("");
  const [uploadStatus, setUploadStatus] = useState("");
  const [allFileData, setAllFileData] = useState();
  const [stopUpload_C, setStopUpload_C] = useState(false);
  const [uploadFrom, setUploadFrom] = useState("");
  const [hashDataMulti, setHashDataMulti] = useState();
  const [uploadStatusMulti, setUploadStatusMulti] = useState();
  const [allFileDataMulti, setAllFileDataMulti] = useState();
  const [fileFolderData, setFileFolderData] = useState();
  const [newFolderData, setNewFolderData] = useState();
  const [keyFolder, setKeyFolder] = useState();

  const updateProgressData_aws = (data) => {
    setProgressData_aws(data);
  };
  const handleHashMulti = (data) => {
    setHashDataMulti(data);
  };
  const updateProgressData_server = (data) => {
    setProgressData_Server(data);
  };
  const uploadFromdata = (data) => {
    setUploadFrom(data);
  };
  const updateMessage = (msg) => {
    setMessage(msg);
  };
  const handleuploadLocation = (data) => {
    setuploadLocation(data);
  };
  const updateFileName = (name) => {
    setFileName(name);
  };

  const handleProgressStatus = (data) => {
    setProgressStatus(data);
  };

  const handleUploadStatus = (data) => {
    setUploadStatus(data);
  };
  const handleData = (data) => {
    setAllFileData(data);
  };

  const handleIsUpload = (data) => {
    setStopUpload_C(data);
  };
  const handleUploadStatusMulti = (data) => {
    setUploadStatusMulti(data);
  };
  const handleDataMulti = (data) => {
    setAllFileDataMulti(data);
  };
  const handleFileFolderData = (data) => {
    setFileFolderData(data);
  };
  const newCreatedFolder = (data) => {
    setNewFolderData(data);
  };
  const handleKeyFolder = (data) => {
    console.log(data);
    setKeyFolder(data);
  };
  return (
    <ProgressBoxContext.Provider
      value={{
        progressData_aws,
        updateProgressData_aws,
        handleuploadLocation,
        handleProgressStatus,
        handleHashMulti,
        handleFileFolderData,
        progressData_Server,
        updateProgressData_server,
        handleUploadStatus,
        handleData,
        handleUploadStatusMulti,
        handleKeyFolder,
        message, // Include message in context value
        updateMessage, // Include updateMessage function
        uploadFromdata,
        handleDataMulti,
        fileName,
        newCreatedFolder,
        uploadStatus,
        keyFolder,
        uploadLocation,
        progress,
        progressStatus,
        allFileData,
        updateFileName,
        handleIsUpload,
        stopUpload_C,
        uploadFrom,
        hashDataMulti,
        uploadStatusMulti,
        allFileDataMulti,
        fileFolderData,
        newFolderData,
      }}
    >
      {children}
    </ProgressBoxContext.Provider>
  );
};

export default ProgressBoxContextProvider;
