import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import Cookie from "js-cookie";
import { GLOBAL_URL_DOMAIN_LIVE } from "../utils/Files/url";
const initialState = {
  dataAwsFolder: [],
  dataIpfsFolder: [],
  dataBothFolder: [],
  dataAwsFile: [],
  dataIpfsFile: [],
  dataBothFile: [],
  isLoading: false,
  error: null,
};
const SharedByMeSlice = createSlice({
  name: "sharedByMe",
  initialState,
  reducers: {
    fetchSharedByMeFoldersStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    fetchSharedByMeFoldersSuccessAws(state, action) {
      state.isLoading = false;
      state.dataAwsFolder = action.payload;
    },
    fetchSharedByMeFoldersSuccessIpfs(state, action) {
      state.isLoading = false;
      state.dataIpfsFolder = action.payload;
    },
    fetchSharedByMeFoldersSuccessBoth(state, action) {
      state.isLoading = false;
      state.dataBothFolder = action.payload;
    },
    fetchSharedByMeFilesSuccessAws(state, action) {
      state.isLoading = false;
      state.dataAwsFile = action.payload;
    },
    fetchSharedByMeFilesSuccessIpfs(state, action) {
      state.isLoading = false;
      state.dataIpfsFile = action.payload;
    },
    fetchSharedByMeFilesSuccessBoth(state, action) {
      state.isLoading = false;
      state.dataBothFile = action.payload;
    },
    fetchSharedByMeFoldersFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    resetSharedByMeData(state) {
      return initialState;
    },
  },
});
export const {
  fetchSharedByMeFoldersStart,
  fetchSharedByMeFoldersSuccessAws,
  fetchSharedByMeFoldersSuccessIpfs,
  fetchSharedByMeFoldersSuccessBoth,
  fetchSharedByMeFilesSuccessAws,
  fetchSharedByMeFilesSuccessIpfs,
  fetchSharedByMeFilesSuccessBoth,
  fetchSharedByMeFoldersFailure,
  resetSharedByMeData,
} = SharedByMeSlice.actions;

export const fetchSharedByMeFolders =
  (storageType, bucketId, awsBucketName) => async (dispatch) => {
    dispatch(fetchSharedByMeFoldersStart());
    let headerConfig = {
      headers: {
        authorization: "Bearer " + sessionStorage.getItem("userToken"),
      },
    };
    if (storageType === "aws") {
      var API_URL =
        GLOBAL_URL_DOMAIN_LIVE +
        `/file-access/shared-by-me-folders?sender_user_id=${sessionStorage.getItem(
          "userID"
        )}&bucket_id=${bucketId}&bucket_name=${awsBucketName}`;
      axios
        .get(API_URL, headerConfig)
        .then((response) => {
          console.log("shared_by_me_folders_func aws*", response);

          // console.log(alignment);
          const uniqueArr = Object.values(
            response.data?.data.reduce((acc, current) => {
              acc[current.folder_id] = current;
              return acc;
            }, {})
          );
          // const sortedData = uniqueArr.sort((a, b) => {
          //   //   // Assuming you want to sort by a property called "createdAt"
          //   return new Date(b.createdAt) - new Date(a.createdAt);
          // });
          // setData(uniqueArr);
          dispatch(fetchSharedByMeFoldersSuccessAws(uniqueArr));
          // setLoaderData(false);
        })
        .catch((err) => {
          dispatch(fetchSharedByMeFoldersFailure(err.message));
          console.log("shared_with_me_files_func err*", err);
          // setLoaderData(false);
          // setLoaderPopup(false);
          if (err?.response && err?.response?.status === 427) {
            sessionStorage.clear();
            localStorage.clear();
            Cookie.remove("fName");
            Cookie.remove("lName");
            Cookie.remove("userID");
            Cookie.remove("userToken");
            Cookie.remove("token_start_time");
            window.location.replace("/");
            //   navigate("/");
          }
        })
        .then(function () {});
    } else if (storageType === "ipfs") {
      var API_URL =
        GLOBAL_URL_DOMAIN_LIVE +
        `/ipfs-access/shared-by-me-folders?sender_user_id=${sessionStorage.getItem(
          "userID"
        )}&bucket_id=6e2f3b90-1dd9-4b7c-9be8-904bd4801e55&bucket_name=atom-storage`;
      axios
        .get(API_URL, headerConfig)
        .then((response) => {
          console.log("shared_by_me_folders_func aws*", response);

          // console.log(alignment);
          const uniqueArr = Object.values(
            response.data?.data.reduce((acc, current) => {
              acc[current.folder_id] = current;
              return acc;
            }, {})
          );
          // const sortedData = uniqueArr.sort((a, b) => {
          //   //   // Assuming you want to sort by a property called "createdAt"
          //   return new Date(b.createdAt) - new Date(a.createdAt);
          // });
          // setData(uniqueArr);
          dispatch(fetchSharedByMeFoldersSuccessIpfs(uniqueArr));
          // setLoaderData(false);
        })
        .catch((err) => {
          dispatch(fetchSharedByMeFoldersFailure(err.message));
          console.log("shared_with_me_files_func err*", err);
          if (err?.response && err?.response?.status === 427) {
            sessionStorage.clear();
            localStorage.clear();
            Cookie.remove("fName");
            Cookie.remove("lName");
            Cookie.remove("userID");
            Cookie.remove("userToken");
            Cookie.remove("token_start_time");
            window.location.replace("/");
            //   navigate("/");
          }
          // setLoaderData(false);
          // setLoaderPopup(false);
        })
        .then(function () {});
    } else if (storageType === "both") {
      var API_URL =
        GLOBAL_URL_DOMAIN_LIVE +
        `/file-access/shared-by-me-folders-aws-atom?sender_user_id=${sessionStorage.getItem(
          "userID"
        )}`;
      axios
        .get(API_URL, headerConfig)
        .then((response) => {
          console.log("shared_by_me_folders_func both*", response);
          // const uniqueArr = Object.values(
          //   response.data?.data.reduce((acc, current) => {
          //     acc[current.folder_id] = current;
          //     return acc;
          //   }, {})
          // );

          dispatch(fetchSharedByMeFoldersSuccessBoth(response.data?.data));
        })
        .catch((err) => {
          dispatch(fetchSharedByMeFoldersFailure(err.message));
          console.log("shared_with_me_files_func err*", err);
          if (err?.response && err?.response?.status === 427) {
            sessionStorage.clear();
            localStorage.clear();
            Cookie.remove("fName");
            Cookie.remove("lName");
            Cookie.remove("userID");
            Cookie.remove("userToken");
            Cookie.remove("token_start_time");
            window.location.replace("/");
            //   navigate("/");
          }
        });
    }
  };
// export const fetchSharedByMeFiles =
//   (storageType, bucketId) => async (dispatch) => {
//     dispatch(fetchSharedByMeFoldersStart());
//     // setLoaderData(true);
//     var API_URL =
//       GLOBAL_URL_DOMAIN_LIVE +
//       `/file-access/shared-by-me-files?sender_user_id=${sessionStorage.getItem(
//         "userID"
//       )}&bucket_id_aws=${bucketId}&bucket_id_ipfs=6e2f3b90-1dd9-4b7c-9be8-904bd4801e55&location=${storageType}`;
//     let headerConfig = {
//       headers: {
//         authorization: "Bearer " + sessionStorage.getItem("userToken"),
//       },
//     };
//     axios
//       .get(API_URL, headerConfig)
//       .then((response) => {
//         console.log("shared_by_me_files_func aws*", response);

//         // if (
//         //   response?.data !== null &&
//         //   (storageType === "aws" || storageType === null)
//         // ) {
//         console.log("it is aws files");

//         let fileFinalDataAws = [];
//         for (let i = 0; i < (response?.data?.fileData_aws).length; i++) {
//           if (
//             response?.data?.fileData_aws[i] !== null &&
//             !response?.data?.fileData_aws[i].file_unique_id.startsWith("1_1")
//           ) {
//             fileFinalDataAws.push(response?.data?.fileData_aws[i]);
//           }
//           console.log(fileFinalDataAws);
//         }
//         const uniqueDataaws = {};
//         const filteredDataaws = [];

//         fileFinalDataAws.forEach((item) => {
//           if (!uniqueDataaws[item.file_unique_id]) {
//             uniqueDataaws[item.file_unique_id] = true;
//             filteredDataaws.push(item);
//           }
//         });
//         const sortedDataaws = filteredDataaws.sort((a, b) => {
//           //   // Assuming you want to sort by a property called "createdAt"
//           return new Date(b.createdAt) - new Date(a.createdAt);
//         });
//         // setData(filteredData);
//         dispatch(fetchSharedByMeFilesSuccessAws(sortedDataaws));
//         // setFolderData(response?.data?.data_aws);

//         // console.log(response?.data?.data_aws);

//         // setLoaderData(false);
//         // } else if (response?.data !== null && storageType === "ipfs") {
//         console.log("it is ipfs files");
//         let fileFinalDataIpfs = [];
//         for (let i = 0; i < (response?.data?.fileData_ipfs).length; i++) {
//           if (
//             response?.data?.fileData_ipfs[i] !== null &&
//             !response?.data?.fileData_ipfs[i].file_unique_id.startsWith("1_1")
//           ) {
//             fileFinalDataIpfs.push(response?.data?.fileData_ipfs[i]);
//           }
//           // console.log(fileFinalDataIpfs);
//         }
//         const uniqueDataipfs = {};
//         const filteredDataipfs = [];

//         fileFinalDataIpfs.forEach((item) => {
//           if (!uniqueDataipfs[item.file_unique_id]) {
//             uniqueDataipfs[item.file_unique_id] = true;
//             filteredDataipfs.push(item);
//           }
//         });
//         const sortedDataipfs = filteredDataipfs.sort((a, b) => {
//           //   // Assuming you want to sort by a property called "createdAt"
//           return new Date(b.createdAt) - new Date(a.createdAt);
//         });
//         // setData(filteredData);
//         dispatch(fetchSharedByMeFilesSuccessIpfs(sortedDataipfs));

//         // setFolderData(response?.data?.data_ipfs);

//         // console.log(response?.data?.data_ipfs);

//         // setLoaderData(false);
//         // } else if (response?.data !== null && storageType === "both") {
//         console.log("it is both files");
//         let fileFinalDataboth = [];
//         for (let i = 0; i < (response?.data?.fileData_ipfs).length; i++) {
//           if (
//             response?.data?.fileData_ipfs[i] !== null &&
//             response?.data?.fileData_ipfs[i].file_unique_id.startsWith("1_1")
//           ) {
//             fileFinalDataboth.push(response?.data?.fileData_ipfs[i]);
//           }
//           console.log(fileFinalDataboth);
//         }

//         const uniqueDataboth = {};
//         const filteredDataboth = [];

//         fileFinalDataboth.forEach((item) => {
//           if (!uniqueDataboth[item.file_unique_id]) {
//             uniqueDataboth[item.file_unique_id] = true;
//             filteredDataboth.push(item);
//           }
//         });
//         const sortedDataboth = filteredDataboth.sort((a, b) => {
//           //   // Assuming you want to sort by a property called "createdAt"
//           return new Date(b.createdAt) - new Date(a.createdAt);
//         });
//         // setData(fileFinalDataboth);
//         dispatch(fetchSharedByMeFilesSuccessBoth(sortedDataboth));
//         // setFolderData(response?.data?.data_ipfs);

//         // console.log(response?.data?.data_ipfs);

//         // setLoaderData(false);
//         // }
//       })
//       .catch((err) => {
//         console.log("shared_with_me_files_func err*", err);
//         // setLoaderData(false);
//         // setLoaderPopup(false);
//         if (err?.response && err?.response?.status === 427) {
//           sessionStorage.clear();
//           localStorage.clear();
//           Cookie.remove("fName");
//           Cookie.remove("lName");
//           Cookie.remove("userID");
//           Cookie.remove("userToken");
//           Cookie.remove("token_start_time");
//           window.location.replace("/");
//           // navigate("/");
//         }
//       })
//       .then(function () {});
//   };

export const fetchSharedByMeFiles =
  (storageType, bucketId) => async (dispatch) => {
    dispatch(fetchSharedByMeFoldersStart());
    let headerConfig = {
      headers: {
        authorization: "Bearer " + sessionStorage.getItem("userToken"),
      },
    };
    if (storageType === "aws") {
      var API_URL =
        GLOBAL_URL_DOMAIN_LIVE +
        `/file-access/shared-by-me-files?sender_user_id=${sessionStorage.getItem(
          "userID"
        )}&bucket_id=${bucketId}`;
      axios
        .get(API_URL, headerConfig)
        .then((response) => {
          console.log("shared_by_me_files_func aws*", response);
          dispatch(fetchSharedByMeFilesSuccessAws(response?.data?.data));
        })
        .catch((err) => {
          console.log("shared_with_me_files_func err*", err);
          // setLoaderData(false);
          // setLoaderPopup(false);
          dispatch(fetchSharedByMeFoldersFailure(err.message));
          if (err?.response && err?.response?.status === 427) {
            sessionStorage.clear();
            localStorage.clear();
            Cookie.remove("fName");
            Cookie.remove("lName");
            Cookie.remove("userID");
            Cookie.remove("userToken");
            Cookie.remove("token_start_time");
            window.location.replace("/");
            //           // navigate("/");
          }
        })
        .then(function () {});
    }
    if (storageType === "ipfs") {
      var API_URL =
        GLOBAL_URL_DOMAIN_LIVE +
        `/ipfs-access/shared-by-me-files?sender_user_id=${sessionStorage.getItem(
          "userID"
        )}`;
      axios
        .get(API_URL, headerConfig)
        .then((response) => {
          console.log("shared_by_me_files_func ipfs*", response);
          dispatch(fetchSharedByMeFilesSuccessIpfs(response?.data?.data));
        })
        .catch((err) => {
          console.log("shared_with_me_files_func err*", err);
          // setLoaderData(false);
          // setLoaderPopup(false);
          dispatch(fetchSharedByMeFoldersFailure(err.message));
          if (err?.response && err?.response?.status === 427) {
            sessionStorage.clear();
            localStorage.clear();
            Cookie.remove("fName");
            Cookie.remove("lName");
            Cookie.remove("userID");
            Cookie.remove("userToken");
            Cookie.remove("token_start_time");
            window.location.replace("/");
            //           // navigate("/");
          }
        })
        .then(function () {});
    }
    if (storageType === "both") {
      var API_URL =
        GLOBAL_URL_DOMAIN_LIVE +
        `/file-access/shared-by-me-files-aws-atom?sender_user_id=${sessionStorage.getItem(
          "userID"
        )}&bucket_id=${bucketId}`;
      axios
        .get(API_URL, headerConfig)
        .then((response) => {
          console.log("shared_by_me_files_func aws*", response);
          dispatch(fetchSharedByMeFilesSuccessBoth(response?.data?.data));
        })
        .catch((err) => {
          console.log("shared_with_me_files_func err*", err);
          // setLoaderData(false);
          // setLoaderPopup(false);
          dispatch(fetchSharedByMeFoldersFailure(err.message));
          if (err?.response && err?.response?.status === 427) {
            sessionStorage.clear();
            localStorage.clear();
            Cookie.remove("fName");
            Cookie.remove("lName");
            Cookie.remove("userID");
            Cookie.remove("userToken");
            Cookie.remove("token_start_time");
            window.location.replace("/");
            //           // navigate("/");
          }
        })
        .then(function () {});
    }
  };

export default SharedByMeSlice.reducer;
