import { createSlice } from "@reduxjs/toolkit";
import { GLOBAL_URL_DOMAIN_LIVE } from "../utils/Files/url";
import axios from "axios";
import Cookie from "js-cookie";
const AwsRootFolderSlice = createSlice({
  name: "Aws Root Data",
  initialState: { folderData: [], filesData: [], isLoadingRoot: false },
  reducers: {
    setRootFolderData: (state, action) => {
      return { ...state, folderData: action.payload };
    },
    setFilesData: (state, action) => {
      return { ...state, filesData: action.payload };
    },
    AWSRootLoader(state, action) {
      return { ...state, isLoadingRoot: action.payload };
    },
  },
});
const { setRootFolderData, setFilesData, AWSRootLoader } =
  AwsRootFolderSlice.actions;
export default AwsRootFolderSlice.reducer;
var userID = sessionStorage.getItem("userID");
var CompanyID = sessionStorage.getItem("companyID");

export function getAwsRootData(bucketID, bucketname, reqUrlCreate) {
  console.log("getAwsRootData slice +++++++++++++++++++++++++++++++++");
  return async function getAwsRootDataThunk(dispatch, getstate) {
    try {
      dispatch(AWSRootLoader(true));
      // const ind_aws = reqUrlCreate.lastIndexOf("/");
      // const new_str_aws = reqUrlCreate.slice(0, ind_aws);
      // const data_new = new_str_aws.lastIndexOf("/");
      // const final_data = new_str_aws.slice(0, data_new + 1);
      // console.log(final_data);

      var folder_name = `${bucketname}/${sessionStorage.getItem("userID")}/`;
      if (bucketID !== "") {
        var data = {
          companyId: sessionStorage.getItem("companyID"),
          userId: sessionStorage.getItem("userID"),
          folderName: folder_name,
          bucketId: bucketID,
        };

        let headerConfig = {
          headers: {
            authorization: "Bearer " + sessionStorage.getItem("userToken"),
          },
        };

        var API_URL = GLOBAL_URL_DOMAIN_LIVE + "/folder/all-child-folders-aws";

        axios
          .post(API_URL, data, headerConfig)
          .then((res) => {
            console.log("add_folder_id_func res***", res);
            var Data = res?.data?.data;
            if (res?.status === 200) {
              dispatch(
                setRootFolderData(Data, bucketname, bucketID, folder_name)
              );
              dispatch(AWSRootLoader(false));
              // settingFolderData(Data, bucketname, bucketID, folder_name);
              // setLoader(false);
              // setAwsBucketName(bucketname);
              // setLeftFolders(Data);
              // foderwiseAwsFiles(bucketID);
            }
          })
          .catch((err) => {
            dispatch(AWSRootLoader(false));
            console.log("add_folder_id_func err***", err);
            if (err?.response?.status === 427) {
              sessionStorage.clear();
              localStorage.clear();
              Cookie.remove("fName");
              Cookie.remove("lName");
              Cookie.remove("userID");
              Cookie.remove("userToken");
              Cookie.remove("token_start_time");
              window.location.replace("/");
            }
          })
          .then(function () {});
      }
    } catch (err) {
      console.log(err);
    }
  };
}
export function foderwiseAwsFiless(Bucket_ID) {
  return async function foderwiseAwsFilessThunk(dispatch, getState) {
    try {
      dispatch(AWSRootLoader(true));
      let headerConfig = {
        headers: {
          authorization: "Bearer " + sessionStorage.getItem("userToken"),
        },
      };

      let API_URL =
        GLOBAL_URL_DOMAIN_LIVE +
        `/file/filedata-aws-folderwise?userId=${sessionStorage.getItem(
          "userID"
        )}&folderName=${sessionStorage.getItem(
          "userID"
        )}/&bucketId_aws=${Bucket_ID}`;

      let res = await axios.get(API_URL, headerConfig);

      // const updatedState = res.data.data;

      // Sort the data in descending order based on a specific property
      const sortedData = res?.data?.data?.sort((a, b) => {
        //   // Assuming you want to sort by a property called "createdAt"
        return new Date(b.createdAt) - new Date(a.createdAt);
      });

      dispatch(setFilesData(sortedData));
      dispatch(AWSRootLoader(false));
    } catch (err) {
      if (err?.response?.status === 427) {
        sessionStorage.clear();
        localStorage.clear();
        Cookie.remove("fName");
        Cookie.remove("lName");
        Cookie.remove("userID");
        Cookie.remove("userToken");
        Cookie.remove("token_start_time");
        // navigate("/");
        window.location.replace("/");
      }
    }
  };
}

export function resetAwsRootFolderData() {
  return async function resetAwsRootFolderDataThunk(dispatch, getState) {
    try {
      dispatch(setRootFolderData([]));
      dispatch(setFilesData([]));
      dispatch(AWSRootLoader(false));
    } catch (err) {
      console.log(err);
    }
  };
}
